import * as React from 'react';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { numberFormat } from 'utils/global/formatNumber';
import { services } from 'redux/services';
import { useToastMessage } from 'hooks';
import { TableContainer, Paper, Table, TableRow, TableCell, TableHead, TableBody, Button, Card, Grid, Typography, Box } from '@mui/material';
import { PrimaryDatePicker } from 'components/forms';

const calcTotalAmount = (array: any, item: any) => {
    let total = 0;
    array.forEach((element: any) => {
        total += element[item];
    });
    return numberFormat(String(total));
};

const startOfMonth = moment().startOf('month').toDate();
const endOfMonth = moment().endOf('month').toDate();

const Report: React.FunctionComponent<any> = (props): JSX.Element => {
    const { toastMessage } = useToastMessage();
    const { category } = useParams();
    const [reportData, setReportData] = useState<any>(null);

    const [startDate, setStartDate] = useState(startOfMonth);
    const [endDate, setEndDate] = useState(endOfMonth);

    const onApiCall = (fromDate = startDate, toDate = endDate) => {
        services.generateReport({ fromDate, toDate })
            .then((res: any) => {
                setReportData(res.result);
                // window.open(exportResponse.url);
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || "Something wrong !", 'error');
            })
    }


    useEffect(() => {
        onApiCall();
    }, [category]);

    const renderTable = (label: string, value: string) => {
        return (
            <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow >
                            <TableCell align="center" colSpan={5}><Typography variant='h6' color="primary">{label} Report</Typography></TableCell>
                        </TableRow>
                        <TableRow sx={{ background: "#ede9f9" }}>
                            <TableCell>Date</TableCell>
                            <TableCell align="justify">Sub Total</TableCell>
                            <TableCell align="justify">Discount Amount</TableCell>
                            <TableCell align="justify">Due Amount</TableCell>
                            <TableCell align="justify">Total Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData?.[value]?.map((item: any, index: number) => (
                            <TableRow key={index}>
                                <TableCell>{item.date}</TableCell>
                                <TableCell>{numberFormat(item.total_amount)}</TableCell>
                                <TableCell>{numberFormat(item.discount_amount)}</TableCell>
                                <TableCell>{numberFormat(item.due_amount)}</TableCell>
                                <TableCell>{numberFormat(item.paid_amount)}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow sx={{ background: "#f9f9f9" }}>
                            <TableCell />
                            <TableCell><b>{calcTotalAmount(reportData?.[value], 'total_amount')}</b></TableCell>
                            <TableCell><b>{calcTotalAmount(reportData?.[value], 'discount_amount')}</b></TableCell>
                            <TableCell><b>{calcTotalAmount(reportData?.[value], 'due_amount')}</b></TableCell>
                            <TableCell><b>{calcTotalAmount(reportData?.[value], 'paid_amount')}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <>
            <Card sx={{ p: 2, marginBottom: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <PrimaryDatePicker
                            value={startDate}
                            onChange={(date: any) => setStartDate(date)}
                            disableFuture
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <PrimaryDatePicker
                            value={endDate}
                            onChange={(date: any) => setEndDate(date)}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Button variant='contained' onClick={() => onApiCall()}>
                            Search
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button variant='contained' color="warning"
                            onClick={() => {
                                setStartDate(startOfMonth);
                                setEndDate(endOfMonth);
                                onApiCall(startOfMonth, endOfMonth);
                            }}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
            </Card>

            {!isEmpty(reportData?.income) || !isEmpty(reportData?.expense) ? (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {!isEmpty(reportData?.income) && renderTable('Income', 'income')}
                    </Grid>
                    <Grid item xs={6}>
                        {!isEmpty(reportData?.expense) && renderTable('Expense', 'expense')}
                    </Grid>
                </Grid>
            ) : (
                <Card sx={{ display: "flex", p:5, justifyContent: 'center', alignItems: 'center' }}>
                    <Typography>Data not found</Typography>
                </Card>
            )}
        </>
    );
};

export default Report;
