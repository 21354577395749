import React, { useState, MouseEvent } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface ActionMenuProps {
    children?: any;
}

const ActionMenu: React.FC<ActionMenuProps> = ({ children }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu

                id="long-menu"
                MenuListProps={{
                    "aria-labelledby": "long-button",
                    sx: {
                        paddingY: '0 !important',
                        minWidth: 124,
                    }
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{ zIndex: 1000 }}
            >
                {children?.length > 0 && children?.map((child: React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined, index: number) => child &&
                    <MenuItem key={index} sx={{ width: '100%', padding: 0, borderBottom: '1px solid #DDD' }}>
                        {React.cloneElement(child as React.ReactElement<any>, { style: { border: "none", width: '100%', justifyContent: 'start', padding: 12 } })}
                    </MenuItem>
                )}

            </Menu>
        </div>
    );
};

export { ActionMenu };
