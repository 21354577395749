
import { Button, Chip, Drawer, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';

interface Props {
    open: boolean;
    onClose: () => void;
    defaultData?: any;
}

function renderBooleanText(txt: any) {
    if (txt == 1) return <Chip label='Yes' color="success" variant='outlined' size='small' />;
    else return <Chip label='No' color="error" variant='outlined' size='small' />;
}

const View = ({ onClose, open, defaultData }: Props) => {
    return (
        <Drawer
            anchor={'right'}
            open={open}
            onClose={onClose}
            transitionDuration={600}
            PaperProps={{ elevation: 9 }}
            SlideProps={{ unmountOnExit: true }}
        >
            <TableContainer sx={{ marginY: 2, minWidth : 400, maxWidth : 800 }}>
                <Table size='small'>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Name</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{defaultData?.name}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Address</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{defaultData?.address_line1 || 'N/A'}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Email</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{defaultData?.email || 'N/A'}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Contact</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{defaultData?.contact || 'N/A'}</Typography></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Phone</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{defaultData?.phone || 'N/A'}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Country</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{defaultData?.country || 'N/A'}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Flagged</Typography></TableCell>
                            <TableCell>{renderBooleanText(defaultData?.is_flagged)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Description</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{defaultData?.description || 'N/A'}</Typography></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Drawer>
    )
}

export default View;