
import { Chip, Drawer, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { useToastMessage } from 'hooks';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { services } from 'redux/services';

interface Props {
    open: boolean;
    onClose: () => void;
    defaultData?: any;
}

function renderStatus(data: any) {
    if (data.is_cancelled == 1) return <Chip label='Cancelled' color="error" size='small' />;
    else if (data.is_cancelled == 0) return <Chip label='Active' color="success" size='small' />;
    else if (data.expires_in_days < 0) return <Chip label='Expired' color="error" size='small' />;
}

const View = ({ onClose, open, defaultData }: Props) => {
    return (
        <Drawer
            anchor={'right'}
            open={open}
            onClose={onClose}
            transitionDuration={600}
            PaperProps={{ elevation: 9 }}
            SlideProps={{ unmountOnExit: true }}
        >
            <TableContainer sx={{ marginY: 2, minWidth: 400, maxWidth: 800 }}>
                <Table size='small'>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Status</Typography></TableCell>
                            <TableCell>{renderStatus(defaultData)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Plan Name</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{defaultData?.subscription_plan}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Plan Period</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{defaultData?.plan_period} {defaultData?.period_type}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Plan Amount</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{defaultData?.plan_amount || 'N/A'}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Start Date</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{moment(defaultData?.start_date).format('YYYY-MM-DD hh:mm A')}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>End Date</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{moment(defaultData?.end_date).format('YYYY-MM-DD hh:mm A') || 'N/A'}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Remaining Days</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{defaultData?.expires_in_days || 'N/A'}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Billing Date</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{defaultData ? moment(defaultData?.billing_date).format('YYYY-MM-DD hh:mm A') : 'N/A'}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Billing Amount</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{defaultData?.total_amount || 'N/A'}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Payment Method</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{defaultData?.payment_method || 'N/A'}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Payment Status</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{defaultData?.payment_status || 'N/A'}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Payment Ref</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{defaultData?.payment_ref_number || 'N/A'}</Typography></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Drawer>
    )
}

export default View;