import DashboardLayout from "layouts/dashboard";
import {
  Agency,
  AgencyForm,
  Candidate,
  CandidateForm,
  Company,
  CompanyForm,
  Role,
  SpokenLanguage,
  Dashboard,
  Setting,
  User,
  UserForm,
  AdvanceSearch,
  EditCandidateForm,
  Accounting,
  Miscellaneous,
  Subscription,
  ChooseSubscriptionPlan,
  VisaList,
  Permission,
  Profession,
  SubscriptionPlan,
  Vacancy,
  Report,
  SubscriptionFeature,
  PaymentMethod,
  PaymentComplete,
} from "pages";
import Login from "pages/Login";
import NotFound from "pages/Page404";
import { Navigate, useRoutes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

export default function Router() {
  return useRoutes([
    {
      path: "/admin",
      element: <DashboardLayout />,
      children: [
        {
          path: "dashboard",
          element: (
            <PrivateRoute permissions={[]} hasSubscribed={false}>
              <Dashboard />
            </PrivateRoute>
          ),
        },
        {
          path: "agencies",
          element: (
            <PrivateRoute permissions={["accounts.listAgencyAccount"]}>
              <Agency />
            </PrivateRoute>
          ),
        },
        {
          path: "agencies/:formType",
          element: (
            <PrivateRoute permissions={["accounts.createAgencyAccount"]}>
              <AgencyForm />
            </PrivateRoute>
          ),
        },
        {
          path: "agencies/:formType/:uuid",
          element: (
            <PrivateRoute permissions={["accounts.updateAgencyAccount"]}>
              <AgencyForm />
            </PrivateRoute>
          ),
        },
        {
          path: "companies",
          element: (
            <PrivateRoute permissions={["companies.listCompany"]}>
              <Company />
            </PrivateRoute>
          ),
        },
        {
          path: "companies/:formType",
          element: (
            <PrivateRoute permissions={["companies.createCompany"]}>
              <CompanyForm />
            </PrivateRoute>
          ),
        },
        {
          path: "companies/vacancies/:companyUuid",
          element: (
            <PrivateRoute permissions={["vacancies.updateCompany"]}>
              <Vacancy />
            </PrivateRoute>
          ),
        },
        {
          path: "vacancies",
          element: (
            <PrivateRoute permissions={["vacancies.listCompanyVacancy"]}>
              <Vacancy />
            </PrivateRoute>
          ),
        },
        {
          path: "companies/:formType/:uuid",
          element: (
            <PrivateRoute permissions={["vacancies.createCompanyVacancy","vacancies.updateCompanyVacancy"]}>
              <CompanyForm />
            </PrivateRoute>
          ),
        },
        {
          path: "users",
          element: (
            <PrivateRoute permissions={["accountUsers.listUser","roles.listRole"]}>
              <User />
            </PrivateRoute>
          ),
        },
        {
          path: "users/:formType",
          element: (
            <PrivateRoute permissions={["accountUsers.createUser"]}>
              <UserForm />
            </PrivateRoute>
          ),
        },
        {
          path: "users/:formType/:uuid",
          element: (
            <PrivateRoute permissions={["accountUsers.updateUser"]}>
              <UserForm />
            </PrivateRoute>
          ),
        },
        {
          path: "candidates/list/:status",
          element: (
            <PrivateRoute permissions={["candidates.listCandidate"]}>
              <Candidate />
            </PrivateRoute>
          ),
        },
        {
          path: "candidates/:formType",
          element: (
            <PrivateRoute permissions={["candidates.createCandidate"]}>
              <CandidateForm />
            </PrivateRoute>
          ),
        },
        {
          path: "candidates/:formType/:tab/:uuid",
          element: (
            <PrivateRoute permissions={["candidates.updateCandidate"]}>
              <EditCandidateForm />
            </PrivateRoute>
          ),
        },
        // {
        //   path: "settings/roles",
        //   element: (
        //     <PrivateRoute permissions={["roles.list"]}>
        //       <Role />
        //     </PrivateRoute>
        //   ),
        // },
        {
          path: "settings/permissions",
          element: (
            <PrivateRoute permissions={["permissions.assignPermission"]}>
              <Permission />
            </PrivateRoute>
          ),
        },
        {
          path: "profile",
          element: (
            <PrivateRoute permissions={['accountUsers.listUser']}>
              <Setting />
            </PrivateRoute>
          ),
        },
        {
          path: "settings/spoken-language",
          element: (
            <PrivateRoute permissions={["spokenLanguage.listLanguage"]}>
              <SpokenLanguage />
            </PrivateRoute>
          ),
        },
        {
          path: "settings/miscellaneous",
          element: (
            <PrivateRoute permissions={["miscellaneous.listMiscellaneous"]}>
              <Miscellaneous />
            </PrivateRoute>
          ),
        },
        {
          path: "blacklist-checker",
          element: (
            <PrivateRoute permissions={["candidates.listCandidate", "candidates.createCandidate"]}>
              <AdvanceSearch />
            </PrivateRoute>
          ),
        },
        {
          path: "accounting/:type",
          element: (
            <PrivateRoute permissions={["expenseItems.listItem"]}>
              <Accounting />
            </PrivateRoute>
          ),
        },
        {
          path: "report/:type",
          element: (
            <PrivateRoute permissions={["expenseItems.reports"]}>
              <Report />
            </PrivateRoute>
          ),
        },
        {
          path: "subscriptions",
          element: (
            <PrivateRoute permissions={["subscription.listSubscription"]}>
              <Subscription />
            </PrivateRoute>
          ),
        },

        {
          path: "visas/:slug",
          element: (
            <PrivateRoute permissions={["candidateVisas.listVisa"]}>
              <VisaList />
            </PrivateRoute>
          ),
        },
        {
          path: "settings/professions",
          element: (
            <PrivateRoute permissions={["professions.listProfession"]}>
              <Profession />
            </PrivateRoute>
          ),
        },
        {
          path: "settings/subscription-features",
          element: (
            <PrivateRoute permissions={["subscriptionPlanFeatures.listFeature"]}>
              <SubscriptionFeature />
            </PrivateRoute>
          ),
        },
        {
          path: "settings/payment-methods",
          element: (
            <PrivateRoute permissions={["paymentMethods.listPaymentMethod"]}>
              <PaymentMethod />
            </PrivateRoute>
          ),
        },
        {
          path: "settings/subscription-plans",
          element: (
            <PrivateRoute permissions={["subscriptionPlans.listPlan"]}>
              <SubscriptionPlan />
            </PrivateRoute>
          ),
        },
        {
          path: "subscription",
          element: (
            <PrivateRoute
              permissions={["subscription.chooseSubscriptionPlan"]}
              hasSubscribed={false}
            >
              <ChooseSubscriptionPlan />
            </PrivateRoute>
          ),
        },
        {
          path: "payment-complete",
          element: (
            <PrivateRoute
              permissions={["subscription.completePayment"]}
              hasSubscribed={false}
            >
              <PaymentComplete />
            </PrivateRoute>
          ),
        },
        { path: "404", element: <NotFound /> },
      ],
    },
    { path: "/", element: <Login /> },
    { path: "*", element: <Navigate to="/admin/404" replace /> },
  ]);
}
