import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
// material
import { Box, Grid } from "@mui/material";

import {
    CustomDatePicker,
    CustomRadioGroup,
    CustomTextArea,
} from "components/forms";
import { CustomButton, CustomDrawer, CustomModal, HoverLoader } from "components";
import { validationRule } from "utils/global";
import { useToastMessage } from "hooks";
import { services } from "redux/services";
import { booleanTypes, booleanOptions } from "utils/constants";
import dayjs from "dayjs";
import { Close, Save, Update } from "@mui/icons-material";

interface Props {
    open: boolean;
    closeModal: () => void;
    refreshData: () => void;
    selectedData: any;
}

const OriginalPassportRecievedForm = (props: Props) => {
    const { closeModal, open, refreshData, selectedData } = props;
    const { toastMessage } = useToastMessage();
    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
    } = useForm<any>({
        mode: "all",
        defaultValues: {
            has_original_passport: false,
            passport_recieved_date: new Date(),
        },
    });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);

    function onSubmit(data: any) {
        setLoading(true);
        data.uuid = selectedData.uuid;
        data.passport_recieved_date = dayjs(data?.passport_recieved_date).format(
            "YYYY-MM-DD H:m:s"
        );
        const apiFunc = "updateCandidatePassportRecieved";
        const message = "updated-message";
        services[apiFunc](data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: message }, { title: "candidate" }));
                closeModal();
                refreshData();
            })
            .catch((err: any) => {
                setLoading(false);
                toastMessage(
                    err?.data?.message || "Something wrong !",
                    "error"
                );
            });
    }

    useEffect(() => {
        if (selectedData) {
            reset({
                has_original_passport: selectedData?.has_original_passport ? true : false,
                passport_recieved_date: selectedData?.passport_recieved_date || new Date()
            });
        }
    }, [selectedData]);

    return (
        <CustomModal
            open={open}
            onClose={closeModal}
            title={"Original Passport | Scanned Copy Form"}
        >
            <Box sx={{ width: 500, padding: 3 }}>
                <Grid container spacing={2}>
                    {loading && <HoverLoader />}
                    <Grid item xs={12}>
                        <CustomRadioGroup
                            label={formatMessage({ id: "has-original-passport" })}
                            name="has_original_passport"
                            groupList={booleanOptions}
                            control={control}
                            labelPlacement="top"
                            defaultValue={booleanTypes.Yes}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomDatePicker
                            control={control}
                            label={formatMessage({ id: "recieved-date" })}
                            name="passport_recieved_date"
                            error={errors.passport_recieved_date}
                            rules={{ required: true }}
                            maxDate={new Date()}
                        />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent='end' gap={2}>
                        <CustomButton
                            startIcon={< Close />}
                            label='Close'
                            color="error"
                            onClick={closeModal}
                        />
                        <CustomButton
                            startIcon={<Save />}
                            label='Update'
                            color="secondary"
                            onClick={handleSubmit(onSubmit)}
                        />
                    </Grid>
                </Grid>
            </Box>
        </CustomModal>
    );
};

export default OriginalPassportRecievedForm;
