//intl action type
export const SET_CURRENT_LOCALE = 'SET_CURRENT_LOCALE';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_DUBLICATE_RESET = 'AUTH_DUBLICATE_RESET';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_RESET = 'AUTH_RESET';
export const AUTH_SUBSCRIPTION_STATUS = 'AUTH_SUBSCRIPTION_STATUS';
export const PROFILE_UPDATE = 'PROFILE_UPDATE';

export const RESET_TOKEN = 'RESET_TOKEN';

export const UPDATE_UTILITY = 'UPDATE_UTILITY';

export const GET_MASTER_PAGINATION_DATA_START = "GET_MASTER_PAGINATION_DATA_START";
export const GET_MASTER_PAGINATION_DATA_SUCCESS = "GET_MASTER_PAGINATION_DATA_SUCCESS";
export const GET_MASTER_PAGINATION_DATA_FAIL = "GET_MASTER_PAGINATION_DATA_FAIL";
export const RESET_MASTER_PAGINATION_DATA = "RESET_MASTER_PAGINATION_DATA";

export const GET_MASTER_LOOKUP_DATA_START = "GET_MASTER_LOOKUP_DATA_START";
export const GET_MASTER_LOOKUP_DATA_SUCCESS = "GET_MASTER_LOOKUP_DATA_SUCCESS";
export const GET_MASTER_LOOKUP_DATA_FAIL = "GET_MASTER_LOOKUP_DATA_FAIL";

export const GET_USER_LOOKUP_DATA_START = "GET_USER_LOOKUP_DATA_START";
export const GET_USER_LOOKUP_DATA_SUCCESS = "GET_USER_LOOKUP_DATA_SUCCESS";
export const GET_USER_LOOKUP_DATA_FAIL = "GET_USER_LOOKUP_DATA_FAIL";



