
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from "react-intl";

import CustomTable from 'components/table';
import { TableEditButton, DeleteButton, ActionMenu, CustomButton, ConfirmationButton } from 'components';
import { masterPaginationServices, tableInitialState, } from 'utils/constants';
import { getTableParams } from 'utils/global';
import { RootReducerState } from 'utils/types';
import { getMasterPaginationData } from 'redux/actions';
import PageLayout from 'layouts/PageLayout';
import { accountColumns } from 'utils/constants/tableColumn/column';
import { services } from 'redux/services';
import { useToastMessage } from 'hooks';
import { AirplanemodeActive, AirplanemodeInactive, Delete, Edit, Verified } from '@mui/icons-material';
import StatusForm from './StatusForm';

const Account: React.FC = () => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { toastMessage } = useToastMessage();

    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [verifyModal, setVerifyModal] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [selectedData, setSelectedData] = useState<any>(null);

    const { userData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            userData: masterPaginationReducer[masterPaginationServices.account].data,
            loading: masterPaginationReducer[masterPaginationServices.account].loading,
        }),
        shallowEqual
    );

    const { rows, count } = userData;

    useEffect(() => {
        onApiCall();
    }, [tableState]);

    function onApiCall(withState: boolean = true) {
        const params = getTableParams(withState ? tableState : {});
        dispatch(getMasterPaginationData(masterPaginationServices.account, params));
    }

    let columnAction = {
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                const rowData = rows[tableMeta.rowIndex];
                return (
                    <ActionMenu>
                        {rowData?.is_verified == 0 &&
                            <CustomButton
                                title={formatMessage({ id: "verify-title" })}
                                color='warning'
                                size='small'
                                label="Verify"
                                variant='outlined'
                                startIcon={<Verified />}
                                onClick={() => handleVerifyAccount(rowData)}
                            />
                        }

                        <CustomButton
                            color='secondary'
                            size='small'
                            label="Edit"
                            variant='outlined'
                            onClick={() => handleRowClick(rowData)}
                            startIcon={<Edit />}
                        />
                        {rowData?.is_verified == 1 ?
                            <CustomButton
                                color={rowData?.is_active == 0 ? 'success' : 'error'}
                                size='small'
                                label={rowData?.is_active == 0 ? 'Enable' : 'Disable'}
                                variant='outlined'
                                onClick={() => handleStatusAccount(rowData)}
                                startIcon={rowData?.is_active == 0 ? <AirplanemodeActive /> : <AirplanemodeInactive />}
                            />
                            :
                            <ConfirmationButton
                                color='error'
                                size='small'
                                label="Delete"
                                variant='outlined'
                                onConfirm={() => onDeleteData(rowData)}
                                title={formatMessage({ id: "delete-title" })}
                                subTitle={formatMessage({ id: "delete-confirmation" })}
                                startIcon={<Delete />}
                            />
                        }

                    </ActionMenu>
                )
            }
        }
    }


    function handleStatusAccount(data: any) {
        setIsActive(true);
        setSelectedData(data)
    }

    function handleVerifyAccount(data: any) {
        setVerifyModal(true);
        setSelectedData(data)
    }

    function handleRowClick(rowData: any) {
        navigate(`${pathname}/update/${rowData?.uuid}`, { ...rowData });
    }

    function onDeleteData(data: any) {
        setDeleteLoading(true);
        services.deleteAccount({ uuid: data.uuid })
            .then((res: any) => {
                onApiCall();
                toastMessage(formatMessage({ id: "deleted-message" }, { title: formatMessage({ id: 'agency' }) }));
            })
            .catch((err) => {
                toastMessage(err?.data?.message || formatMessage({ id: "opps-error-message" }), 'error');
            })
            .finally(() => setDeleteLoading(false))
    }


    return (
        <PageLayout title={formatMessage({ id: "agency" })}>
            <CustomTable
                columns={[...accountColumns(formatMessage), columnAction]}
                tableData={rows}
                tableState={tableState}
                rowsCount={count}
                setTableState={setTableState}
                loading={loading || deleteLoading}
            />

            {verifyModal && selectedData?.uuid && (
                <StatusForm
                    open={verifyModal}
                    closeModal={() => setVerifyModal(false)}
                    uuid={selectedData.uuid}
                    refreshData={onApiCall}
                    status='verified'
                    headerText='Verify Account'
                />
            )}

            {isActive && selectedData?.uuid && (
                <StatusForm
                    open={isActive}
                    closeModal={() => setIsActive(false)}
                    uuid={selectedData.uuid}
                    refreshData={onApiCall}
                    status={Number(selectedData?.is_active) == 0 ? 'enabled' : 'disabled'}
                    headerText={Number(selectedData?.is_active) == 0 ? 'Enable Account' : 'Disable Account'}
                />
            )}
        </PageLayout>

    )
}

export default Account;
