import { useSelector, shallowEqual } from "react-redux";
import { roleData } from "utils/constants";
import { RootReducerState } from "utils/types";

export function useCheckRole() {
    const user = useSelector(({ auth }: RootReducerState) => auth.data);
    const isSuperAdmin = user?.role?.includes(roleData.superadmin);
    const isAdmin = user?.role?.includes(roleData.admin);
    const isAccountant = user?.role?.includes(roleData.accountant);
    const isReceptionist = user?.role?.includes(roleData.receptionist);
    const isDocumentation = user?.role?.includes(roleData.documentation);
    const isDeparture = user?.role?.includes(roleData.departure);
    const isAgent = user?.role?.includes(roleData.agent);
    
    return {
        isAccountant,
        isAdmin,
        isSuperAdmin,
        isReceptionist,
        isAgent,
        isDocumentation,
        isDeparture,
    }
}