import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";

import { Button, Card, CardActions, CardMedia, Grid } from "@mui/material";
import { Save } from "@mui/icons-material";

import { CustomFileUpload } from "components/forms";
import CardLayout from "layouts/CardLayout";
import { services } from "redux/services";
import { useToastMessage } from "hooks";
import {  useState } from "react";
import { FileAcceptData, fileValidate } from "utils/constants";
import { CustomDeleteButton } from "components";

interface Props {
    uuid: string;
    name: "experience" | "certificate" | "medical";
    fileArray: Array<string>;
    setFileArray: any;
}
const DocumentUploadForm = ({ uuid, name, fileArray, setFileArray }: Props) => {
    const { formatMessage } = useIntl();
    const {
        handleSubmit,
        formState: { errors },
        register,
        clearErrors,
        setValue,
    } = useForm({ mode: "all" });

    const { toastMessage } = useToastMessage();
    const [wasTriggered, setWasTriggered] = useState(false);

    function onSubmit(data: any) {
        setWasTriggered(false);
        if (data?.files?.length > 0) {
            const apiFunc = "saveCandidateFile";
            const message = "uploaded-message";
            services[apiFunc](data)
                .then((res: any) => {
                    setValue(`files`, null);
                    clearErrors(`files`);
                    setFileArray(res?.result);
                    setWasTriggered(true);
                    toastMessage(
                        formatMessage(
                            { id: message },
                            { title: formatMessage({ id: "candidate" }) }
                        )
                    );
                })
                .catch((err: any) => {
                    toastMessage(
                        err?.data?.message || "Something Wrong",
                        "error"
                    );
                });
        } else {
            toastMessage("Please upload file", "error");
        }
    }

    function onSubmitExperiences(data: any) {
        const params = {
            type: name,
            file_type: "image",
            candidate_uuid: uuid,
            files: data[name],
        };

        onSubmit(params);
    }

    function onDelete(ids: Array<string>) {
        const apiFunc = "deleteCandidateFile";
        const message = "deleted-message";
        services[apiFunc]({
            ids,
            candidate_uuid: uuid,
        })
            .then((res: any) => {
                setFileArray(fileArray?.filter((x: any) => !ids.includes(x.id)));
                toastMessage(
                    formatMessage(
                        { id: message },
                        { title: formatMessage({ id: "file" }) }
                    )
                );
            })
            .catch((err: any) => {
                toastMessage(
                    err?.data?.message || "Something Wrong",
                    "error"
                );
            });
    }

    return (
        <>
            <CardLayout title={formatMessage({ id: name }) + " " + formatMessage({ id: 'document' })}>
                <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomFileUpload
                            accept={FileAcceptData.IMAGES}
                            // formLabel="Experience"
                            error={errors?.experiences}
                            {...register(name, {
                                validate: (value: any) =>
                                    value?.length > 0 ? fileValidate(value?.[0]) : true,
                            })}
                            onClear={() => {
                                setValue(`experiences`, null);
                                clearErrors(`experiences`);
                            }}
                            wasTriggered={wasTriggered}
                            onChange={(file: any) => setValue(name, file)}
                        />
                    </Grid>
                    <Grid item lg={2} md={6} sm={12} xs={12}>
                        <Button
                            size="large"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            onClick={handleSubmit(onSubmitExperiences)}
                            startIcon={<Save />}
                            fullWidth
                            sx={{ padding: 1.5 }}
                        >
                            Upload Image
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={3} sx={{ marginTop: 2 }}>
                    {fileArray?.length > 0 &&
                        fileArray
                            ?.filter((x: any) => x.type === name)
                            ?.map((item: any, index: number) => (
                                <Grid item lg={4} md={6} sm={6} xs={6} key={index}>
                                    <Card sx={{ maxWidth: 500 }}>
                                        <CardMedia
                                            sx={{
                                                height: 300,
                                                backgroundSize: "contain",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => window.open(item.file_url, "_blank")}
                                            image={item.file_url}
                                            title={name}
                                        />
                                        <CardActions>
                                            <CustomDeleteButton
                                                variant="outlined"
                                                label="Remove"
                                                fullWidth
                                                onDelete={() => onDelete([item.id])}
                                            />
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                </Grid>
            </CardLayout>
        </>
    );
};

export default DocumentUploadForm;
