import Grid from '@mui/material/Grid';
import { CustomTab } from 'components';
import User from './UserList';
import Role from '../master/roles';
import { Container } from '@mui/material';
import { usersTab } from 'utils/constants';
import { useState } from 'react';
import { useCheckPermissions } from 'hooks';

const permissionSet = {
    users: ['accountUsers.listUser'],
    listRole: ['roles.listRole'],
}

interface Props {
}
const AdvanceSearch = (props: Props) => {
    const checkPermissions = useCheckPermissions(permissionSet);
    const [active, setActive] = useState('users')
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Container maxWidth="xl" style={{ maxWidth: '100%' }}>
                    <CustomTab
                        tabs={usersTab}
                        onChange={(tab) => {
                            setActive(tab)
                        }}
                        active={active}
                    />
                </Container>
            </Grid>
            <Grid item xs={12}>
                {active === 'users' && checkPermissions.users && <User />}
                {active === 'roles' && checkPermissions.listRole && <Role />}
            </Grid>
        </Grid>
    )
}

export default AdvanceSearch;