
import { useIntl } from 'react-intl';
import { CustomModal, SimpleTable } from 'components';
import {  expenseDetailsColumns } from 'utils/constants/tableColumn/column';

const ViewDetail = (props: any) => {
  const { onHide, show, detailData } = props;
  const { formatMessage } = useIntl();
  return (
    <CustomModal
      open={show}
      onClose={onHide}
      size="md"
      // title="Expense Details"
    >
      <SimpleTable
        columns={[...expenseDetailsColumns(formatMessage)]}
        tableData={detailData?.expense_item_detail}
      />
    </CustomModal>
  );
}

export default ViewDetail;
