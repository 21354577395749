import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { advanceSearchCandidateColumns } from 'utils/constants/tableColumn/column';
import { passportOnlyOptions, candidateStatusOptions, genderList, masterPaginationServices, blacklistOptions } from 'utils/constants';
import { RootReducerState } from 'utils/types';
import { ConfirmationButton, HoverLoader, SimpleTable } from 'components';
import { useIntl } from 'react-intl';
import { getMasterPaginationData } from 'redux/actions';
import { Box, Button, Card, Grid, TextField } from '@mui/material';
import { PrimaryDatePicker, Select } from 'components/forms';

import dayjs from 'dayjs';
import { services } from 'redux/services';
import { useCheckPermissions, useToastMessage } from 'hooks';

const List: React.FunctionComponent<any> = (): JSX.Element => {
  const dispatch = useDispatch();
  const { toastMessage } = useToastMessage();
  const { formatMessage } = useIntl();
  const checkPermissions = useCheckPermissions({ candidateExports: ['candidates.candidateListExport'] });
  const [searchText, setSearchText] = useState("");
  const [searchCandidateData, setSearchCandidateData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [exportLoading, setExportLoading] = useState<any>(false);
  const [gender, setGender] = useState<any>(null);
  const [blacklist, setBlackList] = useState<any>(null);
  const [originalPassport, setOriginalPassport] = useState<any>(null);
  const [status, setStatus] = useState<any>(null);
  const [dob, setDob] = useState<any>(null);
  const {
    candidateData,
    loading,
  } = useSelector(
    ({ masterPaginationReducer }: RootReducerState) => ({
      candidateData: masterPaginationReducer[masterPaginationServices.advanceSearchCandidate].data,
      loading: masterPaginationReducer[masterPaginationServices.advanceSearchCandidate].loading,
    }),
    shallowEqual,
  );


  useEffect(() => {
    if (candidateData?.rows?.length > 0) {
      setSearchCandidateData(candidateData?.rows)
    } else {
      setSearchCandidateData([])
    }
  }, [candidateData?.rows])

  function onApiCall() {
    const params = {
      filter: {
        searchText,
        gender: gender?.value,
        status: status?.value,
        blacklist: blacklist?.value,
        originalPassport: originalPassport?.value,
        dob: dob ? dayjs(dob).format('YYYY-MM-DD') : null,
        startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
        endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
      }
    }
    dispatch(getMasterPaginationData(masterPaginationServices.advanceSearchCandidate, params));
  }

  const handleExport = () => {
    if (searchCandidateData?.length === 0) { toastMessage('There are no records to export.', 'warning'); return; }
    setExportLoading(true);
    const params = {
      filter: {
        searchText,
        gender: gender?.value,
        status: status?.value,
        dob: dob ? dayjs(dob).format('YYYY-MM-DD') : null,
        startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
        endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
      }
    }
    services.exportAdvanceSearchCandidate(params)
      .then((res: any) => {
        if (res?.result?.url) window.open(res?.result?.url);
        toastMessage(res?.message);
      })
      .catch((err) => {
        toastMessage(err?.data?.message || formatMessage({ id: "opps-error-message" }), 'error');
      })
      .finally(() => setExportLoading(false))
  };


  return (
    <>
      {exportLoading && <HoverLoader />}
      <Card sx={{ p: 2, marginBottom: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              placeholder='Search...'
              size='small'
              fullWidth
              value={searchText}
              onChange={(e: any) => setSearchText(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <PrimaryDatePicker
              label="DOB"
              clearable
              value={dob}
              onChange={(value: any) => setDob(value)}
              disableFuture
            />
          </Grid>
          <Grid item xs={2}>
            <Select
              placeholder='Gender'
              size='small'
              options={genderList}
              value={gender}
              onChange={(_, value: any) => setGender(value)}
            />
          </Grid>
          <Grid item xs={2}>
            <Select
              placeholder='Flagged'
              size='small'
              options={blacklistOptions}
              value={blacklist}
              onChange={(_, value: any) => setBlackList(value)}
            />
          </Grid>
          <Grid item xs={2}>
            <Select
              placeholder='Status'
              size='small'
              options={candidateStatusOptions?.filter(x => x.value !== 'all').map(x => ({ label: x.label, value: x.value }))}
              value={status}
              onChange={(_, value: any) => setStatus(value)}
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              placeholder='Original Passport'
              size='small'
              options={passportOnlyOptions}
              value={originalPassport}
              onChange={(_, value: any) => setOriginalPassport(value)}
            />
          </Grid>
          <Grid item xs={2}>
            <PrimaryDatePicker
              clearable
              label="Start Date"
              value={startDate}
              onChange={(value: any) => setStartDate(value)}
              disableFuture
            />
          </Grid>
          <Grid item xs={2}>
            <PrimaryDatePicker
              clearable
              label="End Date"
              value={endDate}
              onChange={(value: any) => setEndDate(value)}
              disableFuture
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2 }}>
              <Button variant='contained' onClick={onApiCall}>
                Search
              </Button>
              <Button variant='contained'
                color="warning"
                onClick={() => {
                  setStartDate(null);
                  setEndDate(null);
                  setSearchCandidateData([]);
                }}
              >
                Reset
              </Button>
              {checkPermissions.candidateExports &&
                <ConfirmationButton
                  color='success'
                  size='small'
                  variant='outlined'
                  onConfirm={handleExport}
                  label="Export"
                  title="Export"
                  subTitle="The records are about to be exported to PDF."
                />
              }
            </Box>
          </Grid>
        </Grid>
      </Card>

      <SimpleTable
        loading={loading}
        columns={advanceSearchCandidateColumns(formatMessage)}
        tableData={searchCandidateData}
      />
    </>
  );
};

export default List;
