import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
// material
import { Box, Button, Grid, IconButton } from '@mui/material';

import { CustomCheckBox, CustomFileUpload, CustomSelect, CustomTextArea, CustomTextBox } from 'components/forms';
import { CustomDrawer, HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';
import { FileAcceptData, fileValidate, planPeriodTypeOption } from 'utils/constants';
import { Add, Delete } from '@mui/icons-material';

interface Props {
    open: boolean;
    closeModal: () => void;
    formMode: 'create' | 'update';
    selectedData: any;
    headerText: string;
    refreshData: () => void;
}

const featuresInitialState = {
    detail: '',
}

const Form = (props: Props) => {
    const { closeModal, open, formMode, selectedData, headerText, refreshData } = props;
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, control, setValue, register, clearErrors, reset } = useForm<any>({
        mode: 'all',
        defaultValues: { status: 1, features: [featuresInitialState] }
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "features",
    });

    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);

    function onSubmit(data: any) {
        setLoading(true);
        data.features = data.features?.map((x: any) => x.detail);
        data.status = data?.status ? 1 : 0;
        if (formMode === 'update') data.id = selectedData?.id;
        const apiFunc = formMode === 'create' ? 'createSubscriptionFeature' : 'updateSubscriptionFeature';
        const message = formMode === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: message }, { title: "subscription-feature" }));
                closeModal();
                refreshData();
            })
            .catch((err: any) => {
                setLoading(false);
                toastMessage(err.message, 'error');
            })
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (selectedData) {
            reset({
                name: selectedData.name,
                features: selectedData?.features?.length > 0 ? selectedData?.features?.map((x: any) => ({ detail: x })) : [featuresInitialState],
                status: selectedData.status,
            });
        }

    }, [formMode === 'update']);

    return (
        <CustomDrawer
            formMode={formMode}
            open={open}
            onCancel={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            title={headerText}
            size='md'
        >
            <Grid container spacing={2}>
                {loading && <HoverLoader />}
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "name" })}
                        name="name"
                        control={control}
                        error={errors?.name}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>
                <Grid item xs={12}>
                    {fields.map((field, index) => (
                        <Box key={field.id} sx={{ display: 'flex', gap: 1, marginY: 1 }}>
                            <CustomTextBox
                                label={formatMessage({ id: "detail" })}
                                name={`features.${index}.detail`}
                                control={control}
                            />
                            <IconButton onClick={() => remove(index)}>
                                <Delete color='error' />
                            </IconButton>
                        </Box>
                    ))}
                    <Button
                        startIcon={<Add />}
                        variant='contained'
                        onClick={() => append(featuresInitialState)}
                    >
                        Add Features
                    </Button>
                </Grid>

                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <CustomCheckBox
                        name="status"
                        label={formatMessage({ id: "is-active" })}
                        control={control}
                        isDefaultChecked
                    />
                </Grid>
            </Grid>
        </CustomDrawer>
    )
}

export default Form;