import { Chip, Drawer, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

interface Props {
    open: boolean;
    onClose: () => void;
    defaultData?: { [key: string]: any };
}

function renderBooleanText(value: any) {
    return value == 1 ? <Chip label='Yes' color="success" variant='outlined' size='small' /> : <Chip label='No' color="error" variant='outlined' size='small' />;
}

const hideColumn = ['id', 'file', 'details', 'created_at', 'updated_at', 'created_by'];
const ReusableView = ({ onClose, open, defaultData }: Props) => {
    return (
        <Drawer
            anchor={'right'}
            open={open}
            onClose={onClose}
            transitionDuration={600}
            PaperProps={{ elevation: 9 }}
            SlideProps={{ unmountOnExit: true }}
        >
            <TableContainer sx={{ marginY: 2, minWidth: 400, maxWidth: 800 }}>
                <Table size='small'>
                    <TableBody>
                        {Object.entries(defaultData || {}).map(([key, value]) => !hideColumn.includes(key) && (
                            <TableRow key={key}>
                                <TableCell component="th" scope="row">
                                    <Typography variant='subtitle2' textTransform='capitalize'>{key?.replace('_', ' ')}</Typography>
                                </TableCell>
                                <TableCell>
                                    {typeof value === 'boolean' ? renderBooleanText(value) : (
                                        <Typography variant='body2' color='gray'>{value || 'N/A'}</Typography>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                        {defaultData?.details?.length > 0 &&
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Table size='small'>
                                        <TableHead sx={{ background: 'lightgray' }}>
                                            <TableCell>Profession</TableCell>
                                            <TableCell>Male</TableCell>
                                            <TableCell>Female</TableCell>
                                            <TableCell>Salary</TableCell>
                                        </TableHead>
                                        <TableBody>
                                            {defaultData?.details?.map((item: any, index: number) => <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell >{item.profession}</TableCell>
                                                <TableCell >{item.male}</TableCell>
                                                <TableCell >{item.female}</TableCell>
                                                <TableCell >{item.currency} {item.salary}</TableCell>
                                            </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        }
                        <TableRow>
                            <TableCell component="th" colSpan={2}>
                                <a href={defaultData?.file} target='_blank'>
                                    <img src={defaultData?.file} style={{ width: '100%', height: 200, objectFit: 'contain' }} alt="visa-image" />
                                </a>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Drawer>
    );
};

export default ReusableView;
