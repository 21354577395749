import { useSelector } from "react-redux";
import { findCommonItems } from "utils/global";
import { RootReducerState } from "utils/types";

interface PermissionSets {
    [key: string]: string[];
}

interface PermissionResults {
    [key: string]: boolean;
}

export function useCheckPermissions(permissionSets: PermissionSets): PermissionResults {
    const userData = useSelector((state: RootReducerState) => state.auth.data);

    const result: PermissionResults = Object.entries(permissionSets).reduce((acc, [key, permissions]) => {
        acc[key] = findCommonItems(permissions, userData.permissions);
        return acc;
    }, {} as PermissionResults);

    return result;
}