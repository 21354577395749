import { Ajax } from "./Ajax";

export const BASE_URI = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/api/v1':'https://manpower.backend.icodifytech.com/api/v1';

class AxiosService extends Ajax {
	public constructor() {
		super({
			baseURL: BASE_URI,
		});
	}
}

export { AxiosService };
