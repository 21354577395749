import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { Button, Card, CardActions, CardMedia, Grid, Typography } from '@mui/material';
import { Save } from '@mui/icons-material';

import { CustomFileUpload } from 'components/forms';
import CardLayout from 'layouts/CardLayout';
import { services } from 'redux/services';
import { useCheckRole, useToastMessage } from 'hooks';
import { useEffect, useState } from 'react';
import { FileAcceptData, fileValidate } from 'utils/constants';
import { CustomDeleteButton } from 'components';

const DocumentForm = ({ defaultData, fileArray, setFileArray, uuid }: any) => {
    const { formatMessage } = useIntl();
    const { isAdmin, isSuperAdmin } = useCheckRole();
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue } = useForm({ mode: 'all' });
    const { toastMessage } = useToastMessage();
    const [wasTriggered, setWasTriggered] = useState(false)

    function onSubmit(data: any) {
        setWasTriggered(false);
        if (data?.files?.length > 0) {
            const apiFunc = 'saveAccountFile';
            const message = 'save-message';
            services[apiFunc](data)
                .then((res: any) => {
                    const files: any = res?.result;
                    setValue(`files`, null);
                    clearErrors(`files`);
                    setFileArray([...fileArray, ...files]);
                    setWasTriggered(true);
                    toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'document' }) }));
                })
                .catch((err: any) => {
                    toastMessage(err?.data?.message || 'Something Wrong', 'error');
                })
        } else {
            toastMessage('Please upload file', 'error');
        }
    }

    function onSubmitExperiences(data: any) {
        const params = {
            type: 'licences',
            file_type: 'image',
            account_uuid: uuid,
            files: data.licences
        };

        onSubmit(params)
    }

    function onDelete(ids: Array<string>) {
        const apiFunc = 'deleteAccountFile';
        const message = 'deleted-message';
        services[apiFunc]({
            ids,
            account_uuid: uuid,
        }).then((res: any) => {
            setFileArray(fileArray?.filter((x: any) => !ids.includes(x.id)));
            toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'file' }) }));
        })
            .catch((err: any) => {
                toastMessage(err?.data?.message || 'Something Wrong', 'error');
            })
    }

    return (
        <CardLayout title={formatMessage({ id: "licences" })}>
            {defaultData?.is_verified == 0 && (isAdmin || isSuperAdmin) &&
                <Grid container spacing={2} marginBottom={3}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomFileUpload
                            accept={FileAcceptData.IMAGES}
                            error={errors?.licences}
                            {...register('licences', {
                                validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true,
                            })}
                            onClear={() => {
                                setValue(`licences`, null);
                                clearErrors(`licences`);
                            }}
                            wasTriggered={wasTriggered}
                            onChange={(file: any) => setValue('licences', file)}
                        />
                    </Grid>
                    <Grid item lg={2} md={6} sm={12} xs={12}>
                        <Button sx={{ padding: 1.5 }} size="large" variant="contained" type='submit' color="secondary" onClick={handleSubmit(onSubmitExperiences)} startIcon={<Save />} fullWidth>
                            Upload Image
                        </Button>
                    </Grid>
                </Grid>
            }
            <Grid container spacing={3}>
                {fileArray?.length > 0 ? fileArray?.filter((x: any) => x.type === 'licences')?.map((item: any, index: number) =>
                    <Grid item lg={4} md={6} sm={6} xs={6} key={index}>
                        <Card sx={{ maxWidth: 500 }}>
                            <CardMedia
                                sx={{ height: 300, backgroundSize: 'contain', cursor: 'pointer' }}
                                onClick={() => window.open(item.file_url, "_blank")}
                                image={item.file_url}
                                title={formatMessage({ id: "licences" })}
                            />
                            {defaultData?.is_verified == 0 && (isAdmin || isSuperAdmin) &&
                                <CardActions>
                                    <CustomDeleteButton
                                        variant="outlined"
                                        label="Remove"
                                        fullWidth
                                        onDelete={() => onDelete([item.id])}
                                    />
                                </CardActions>
                            }
                        </Card>
                    </Grid>
                ) : <Grid item xs={12} display="flex" justifyContent="center">
                    <Typography variant="subtitle1" color="gray">
                        {formatMessage({ id: "no-document-found" })}
                    </Typography>
                </Grid>}
            </Grid>
        </CardLayout>
    )
}

export default DocumentForm;