import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Box, Button, Grid } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { Save, Visibility, VisibilityOff } from '@mui/icons-material';

import { CustomCheckBox, CustomFileUpload, CustomSelect, CustomTextArea, CustomTextBox } from 'components/forms';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import SectionLayout from 'layouts/SectionLayout';
import { services } from 'redux/services';
import { FileAcceptData, fileValidate, masterLookupServices } from 'utils/constants';
import { RootReducerState } from 'utils/types';
import { getMasterLookupData } from 'redux/actions';

const Form: React.FC<any> = ({ formType, uuid, defaultData }) => {
    const { toastMessage } = useToastMessage();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm<any>({ mode: 'all',defaultValues:{
        general_information: {is_active:1}
    } });
    const { formatMessage } = useIntl();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [provinceOption, setProvinceOption] = useState<Array<any>>([]);
    const [districtOption, setDistrictOption] = useState<Array<any>>([]);
    const [municipalitiesOption, setMunicipaltitesOption] = useState<Array<any>>([]);
    const [fileImage, setFileImage] = useState({
        logo: '',
    })

    const { candidateLookup } = useSelector(
        ({ masterLookupReducer }: RootReducerState) => {
            return ({
                candidateLookup: masterLookupReducer[masterLookupServices.getCandidateLookup].data
            })
        },
        shallowEqual
    );

    useEffect(() => {
        setLoading(false);
        dispatch(getMasterLookupData(masterLookupServices.getCandidateLookup));
    }, [])

    useEffect(() => {
        if (candidateLookup) {
            const provinceSelectState = candidateLookup?.province?.map((item: any) => ({
                value: item?.id,
                label: item?.name,
                districts: item?.districts?.map((dis: any) => ({
                    value: dis?.id,
                    label: dis?.name,
                    municipalities: dis?.municipalities?.map((mun: any) => ({
                        value: mun?.id,
                        label: mun?.name,
                    }))
                }))
            }));

            setProvinceOption(provinceSelectState)
        }
    }, [candidateLookup])

    function onSubmit(data: any) {
        setLoading(true);
        data.logo = data?.logo?.[0];
        data.passport_issue_date_bs = data.passport_issue_date_ad;
        data.passport_expiry_date_bs = data.passport_expiry_date_ad;
        data.address.province_id = data?.address.province_id?.value;
        data.address.district_id = data?.address.district_id?.value;
        data.address.municipality_id = data?.address.municipality_id?.value;
        data.is_active = data?.is_active ? 1 : 0;
        if (formType === 'update') {
            data.id = +defaultData.id;
            data.uuid = uuid;
        }
        const apiFunc = formType === 'create' ? 'createAccount' : 'updateAccount';
        const message = formType === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                if (formType === 'create') {
                    navigate(`/admin/agencies/update/${res.result.uuid}`);
                }
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'account' }) }));
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message, 'error');
            }).finally(() => setLoading(false))
    }

    useEffect(() => {
        if (defaultData) {
            const province = provinceOption?.find((item: any) => +item.value === +defaultData.province_id);
            const district = province?.districts?.find((item: any) => +item.value === +defaultData.district_id);
            const municipalities = district?.municipalities?.find((item: any) => +item.value === +defaultData.municipality_id);
            reset({
                general_information: {
                    name: defaultData.name,
                    short_description: defaultData.short_description,
                    phone: defaultData.phone,
                    contact: defaultData.contact,
                    email: defaultData.email,
                    is_active: defaultData.is_active,
                    description: defaultData.description,
                },
                address: {
                    address_line1: defaultData.address_line1,
                    address_line2: defaultData.address_line2,
                    district_id: district,
                    municipality_id: municipalities,
                    province_id: province
                },
            });
            setFileImage({ logo: defaultData.logo_path })
        }
    }, [defaultData, provinceOption])
    
    return (
        <Fragment>
            <SectionLayout title={formatMessage({ id: "general-information" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "company-name" })}
                            name="general_information.name"
                            control={control}
                            error={errors?.general_information?.name}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>

                    <Grid item lg={8} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "slogan" })}
                            name="general_information.short_description"
                            control={control}
                            error={errors?.general_information?.slogan}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "company-email" })}
                            name="general_information.email"
                            control={control}
                            error={errors?.general_information?.email}
                            rules={validationRule.textbox({ type: "email", required: true })}
                            onChangeValue={(value: string) => setValue('credential.email', value)}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "phone-number" })}
                            name="general_information.phone"
                            control={control}
                            error={errors?.general_information?.phone}
                            rules={validationRule.textbox({ type: "contact", maxLength: 20 })}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "contact" })}
                            name="general_information.contact"
                            control={control}
                            error={errors?.general_information?.contact}
                            rules={validationRule.textbox({ type: "contact", maxLength: 20 })}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomTextArea
                            formLabel={formatMessage({ id: "company-detail" })}
                            name="general_information.description"
                            control={control}
                            minRows={6}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomCheckBox
                            name="general_information.is_active"
                            label={formatMessage({ id: "is-active" })}
                            control={control}
                        />
                    </Grid>
                </Grid>
            </SectionLayout>
            <SectionLayout title={formatMessage({ id: "address" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={provinceOption ?? []}
                            label={formatMessage({ id: "province" })}
                            name="address.province_id"
                            control={control}
                            onChangeValue={(data) => setDistrictOption(data.districts)}
                            error={errors?.address?.province_id}
                            rules={{ required: true }}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={districtOption ?? []}
                            label={formatMessage({ id: "district" })}
                            name="address.district_id"
                            control={control}
                            onChangeValue={(data) => setMunicipaltitesOption(data.municipalities)}
                            error={errors?.address?.district_id}
                            rules={{ required: true }}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={municipalitiesOption ?? []}
                            label={formatMessage({ id: "municipality" })}
                            name="address.municipality_id"
                            control={control}
                            error={errors?.address?.municipality_id}
                            rules={{ required: true }}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "address-line1" })}
                            name="address.address_line1"
                            control={control}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "address-line2" })}
                            name="address.address_line2"
                            control={control}
                        />
                    </Grid>
                    {/* <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "zip" })}
                            name="address.zip"
                            control={control}
                            error={errors?.address?.zip}
                            rules={validationRule.textbox({ type: "number" })}
                        />
                    </Grid> */}
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "country" })}
                            name="address.country"
                            control={control}
                            error={errors?.address?.country}
                            defaultValue="Nepal"
                            rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                            disabled
                        />
                    </Grid>
                </Grid>
            </SectionLayout>
            {formType === 'create' &&
                <SectionLayout title={formatMessage({ id: "credential" })}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "login-email" })}
                                name="credential.email"
                                control={control}
                                error={errors?.credential?.email}
                                rules={validationRule.textbox({ required: true, type: "email" })}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "password" })}
                                name="credential.password"
                                control={control}
                                error={errors?.credential?.password}
                                type={showPassword ? 'text' : 'password'}
                                rules={{
                                    ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                                    validate: (value: any) => (watch('credential.confirmation_password') ? (watch('credential.confirmation_password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                                }}
                                helperText={formatMessage({ id: "password-policy-message" })}
                                autoComplete="off"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                                                {!showPassword ? <VisibilityOff color="primary" onClick={() => {
                                                    setShowPassword((prev) => !prev)
                                                }} /> : <Visibility color="primary" onClick={() => {
                                                    setShowPassword((prev) => !prev)
                                                }} />}
                                            {/* <Tooltip title={formatMessage({ id: "password-policy-message" })}>
                                                <InfoIcon color="primary" />
                                            </Tooltip> */}
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "confirm-password" })}
                                name="credential.confirmation_password"
                                type={showPassword ? 'text' : 'password'}
                                control={control}
                                error={errors?.credential?.confirmation_password}
                                // helperText={formatMessage({ id: "password-policy-message" })}
                                rules={{
                                    ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                                    validate: (value: any) => (watch('credential.password') ? (watch('credential.password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                                }}
                                autoComplete="off"
                            />
                        </Grid>

                    </Grid>
                </SectionLayout>
            }

            <SectionLayout title={formatMessage({ id: "logo" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomFileUpload
                            accept={FileAcceptData.IMAGES}
                            {...register('logo', {
                                validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true
                            })}
                            label={formatMessage({ id: "Logo" })}
                            error={errors?.logo}
                            defaultFileUrl={fileImage.logo}
                            defaultFileName={''}
                            onClear={() => {
                                setValue(`logo`, null);
                                clearErrors(`logo`);
                            }}
                            onChange={(file: any) => setValue('logo', file)}
                        />
                    </Grid>
                </Grid>
            </SectionLayout>

            <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2 }}>
                <Button
                    color='info'
                    variant='contained'
                    onClick={handleSubmit(onSubmit)}
                    startIcon={<Save />}
                >
                    Submit
                </Button>
            </Box>
        </Fragment>
    )
}

export default Form;