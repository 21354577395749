import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import { Link } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useQuery, useToastMessage } from 'hooks';
import { services } from 'redux/services';
import { AUTH_SUBSCRIPTION_STATUS } from 'redux/actionTypes';
import { useDispatch } from 'react-redux';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function SubscriptionDenied() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const { toastMessage } = useToastMessage();
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (query) {
      const queryParams: any = {};
      query.forEach((value, key) => {
        queryParams[key] = value;
      });

      services.completePayment({ payment_remarks: queryParams })
        .then((res: any) => {
          // dispatch({ type: AUTH_SUBSCRIPTION_STATUS, payload: { has_subscribed: 1 } });
          setMessage(res.message);
          toastMessage("Congratulations! You have successfully subscribed.");
          window.location.reload();
        })
        .catch((err) => {
          setMessage(err?.data?.message);
        });
    }
  }, [])

  return (
    <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
      <Typography variant="h3" paragraph>
        Payment Completed
      </Typography>

      <Typography sx={{ color: 'text.info' }}>
        {message}
      </Typography>

      <Button startIcon={<Link />} sx={{ marginTop: 2 }} size="large" variant="contained" onClick={() => navigate('/admin/subscription')}>
        Go Back Home
      </Button>
    </ContentStyle>
  );
}
