import { BASE_URI } from "utils/axios";

export const MASTER_API_ENDPOINTS = {
	// Master Requests
	LIST_ROLE: `${BASE_URI}/roles/list`,
	CREATE_ROLE: `${BASE_URI}/roles/save`,
	UPDATE_ROLE: `${BASE_URI}/roles/update`,
	DELETE_ROLE: `${BASE_URI}/roles/delete`,

	LIST_SPOKEN_LANGUAGE: `${BASE_URI}/spoken-language/list`,
	CREATE_SPOKEN_LANGUAGE: `${BASE_URI}/spoken-language/save`,
	UPDATE_SPOKEN_LANGUAGE: `${BASE_URI}/spoken-language/update`,
	DELETE_SPOKEN_LANGUAGE: `${BASE_URI}/spoken-language/delete`,

	LIST_MISCELLANEOUS: `${BASE_URI}/miscellaneous/list`,
	CREATE_MISCELLANEOUS: `${BASE_URI}/miscellaneous/save`,
	UPDATE_MISCELLANEOUS: `${BASE_URI}/miscellaneous/update`,
	DELETE_MISCELLANEOUS: `${BASE_URI}/miscellaneous/delete`,

	LIST_PERMISSION: `${BASE_URI}/permissions/list`,
	CREATE_PERMISSION: `${BASE_URI}/permissions/save`,
	UPDATE_PERMISSION: `${BASE_URI}/permissions/update`,
	DELETE_PERMISSION: `${BASE_URI}/permissions/delete`,
	UPDATE_PERMISSION_AUTO: `${BASE_URI}/permissions/auto-update`,

	LIST_PROFESSION: `${BASE_URI}/professions/list`,
	CREATE_PROFESSION: `${BASE_URI}/professions/save`,
	UPDATE_PROFESSION: `${BASE_URI}/professions/update`,
	DELETE_PROFESSION: `${BASE_URI}/professions/delete`,

	LIST_SUBSCRIPTION_FEATURE: `${BASE_URI}/subscription-plan-features/list`,
	CREATE_SUBSCRIPTION_FEATURE: `${BASE_URI}/subscription-plan-features/save`,
	UPDATE_SUBSCRIPTION_FEATURE: `${BASE_URI}/subscription-plan-features/update`,
	DELETE_SUBSCRIPTION_FEATURE: `${BASE_URI}/subscription-plan-features/delete`,

	LIST_SUBSCRIPTION_PLAN: `${BASE_URI}/subscription-plans/list`,
	CREATE_SUBSCRIPTION_PLAN: `${BASE_URI}/subscription-plans/save`,
	UPDATE_SUBSCRIPTION_PLAN: `${BASE_URI}/subscription-plans/update`,
	DELETE_SUBSCRIPTION_PLAN: `${BASE_URI}/subscription-plans/delete`,

	LIST_PAYMENT_METHOD: `${BASE_URI}/payment-methods/list`,
	CREATE_PAYMENT_METHOD: `${BASE_URI}/payment-methods/save`,
	UPDATE_PAYMENT_METHOD: `${BASE_URI}/payment-methods/update`,
	DELETE_PAYMENT_METHOD: `${BASE_URI}/payment-methods/delete`,
};
