import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  CustomSelect,
  MaskTextBox,
  TextBox,
} from 'components/forms';
import { CustomModal } from 'components';
import { Add, Save } from '@mui/icons-material';
import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { services } from 'redux/services';
import { useToastMessage } from 'hooks';
import { useIntl } from 'react-intl';
import { RootReducerState } from 'utils/types';
import { masterPaginationServices } from 'utils/constants';
import { shallowEqual, useSelector } from 'react-redux';

const item = {
  expense_item: 'candidate-fees',
  code: 'candidate-fees',
  title: 'Candidate Fees',
  qty: 1,
  rate: 10000,
  total_amount: 10000,
  discount_amount: 0,
  paid_amount: 10000,
};

const defaultValues = {
  miscellaneousItem: [item],
};

const Form: React.FunctionComponent<any> = (props): JSX.Element => {
  const { onHide, show, refreshData, miscellaneousList, headerText } = props;
  const { formatMessage } = useIntl();
  const { toastMessage } = useToastMessage();
  const [candidateId, setCandidateId] = useState(null);
  const [miscellaneousData, setMiscellaneousData] = useState<any>([]);
  const [candidateListData, setCandidateListData] = useState<any>([]);
  const [viewModal, setViewModal] = useState(false);

  const {
    candidateData,
  } = useSelector(
    ({ masterPaginationReducer }: RootReducerState) => ({
      candidateData: masterPaginationReducer[masterPaginationServices.candidate].data,
    }),
    shallowEqual,
  );

  const methods = useForm<any>({
    mode: 'onSubmit',
    defaultValues: defaultValues,
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = methods;

  const miscellaneousItem: any = useFieldArray({
    control,
    name: 'miscellaneousItem',
  });

  // useEffect(() => {
  //   if (miscellaneousList?.length > 0) {
  //     setMiscellaneousData(miscellaneousList)
  //   } else {
  //     setMiscellaneousData([])
  //   }
  // }, [miscellaneousList]);

  useEffect(() => {
    if (candidateData?.rows?.length > 0) {
      setCandidateListData(candidateData?.rows?.map((item: any) => ({
        label: item.full_name_search,
        value: item.id,
      })))
    } else {
      setCandidateListData([]);
    }
  }, [candidateData]);

  const onSubmit = (data: any) => {
    data.id = null;
    data.remarks = '';
    data.type = 'income';
    data.candidate_id = data.candidate_id.value;
    data.miscellaneousItem = data?.miscellaneousItem?.map((item: any) => ({ ...item, id: item.pid })) ?? [];
    const apiCall = 'createExpense';
    services[apiCall](data)
      .then((res: any) => {
        toastMessage(formatMessage({ id: 'created-message' }, { title: "miscellaneous" }));
        refreshData();
        onHide();
      })
      .catch((err: any) => {
        toastMessage(err?.data?.message || "Something wrong !", 'error');
      })
  }

  const handleSelect = (selected: any, index: number) => {
    const rate = selected?.actual_price ? selected?.actual_price : 0;
    setValue(`miscellaneousItem.${index}.expense_item`, selected?.value);
    setValue(`miscellaneousItem.${index}.qty`, 1);
    setValue(`miscellaneousItem.${index}.rate`, rate);
    setValue(`miscellaneousItem.${index}.code`, selected?.code);
    setValue(`miscellaneousItem.${index}.title`, selected?.label);
    let total_amount = +watch(`miscellaneousItem.${index}.qty`) * rate;
    setValue(`miscellaneousItem.${index}.total_amount`, total_amount);
    setValue(`miscellaneousItem.${index}.discount_amount`, 0);
    setValue(`miscellaneousItem.${index}.paid_amount`, total_amount);
  };

  const expenseOption = miscellaneousData?.map((item: any) => ({
    label: item.name,
    value: item.code,
    code: item.code,
    actual_price: item.actual_price,
  }));

  return (
    <>
      <CustomModal
        open={show}
        onClose={onHide}
        size={'xs'}
        title={headerText}
      >
        <Box sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item lg={12} sm={12} xs={12}>
              <Typography>{formatMessage({ id: 'candidate' })}</Typography>
              <CustomSelect
                placeholder={formatMessage({ id: 'select-candidate' })}
                control={control}
                options={candidateListData}
                name={`candidate_id`}
                value={candidateId}
                onChangeValue={setCandidateId}
                rules={{ required: true }}
                error={errors?.candidate_id}
              />
            </Grid>
            {miscellaneousItem.fields.map(
              (
                {
                  id,
                  pid,
                  code,
                  title,
                  qty,
                  rate,
                  total_amount,
                  expense_item,
                  discount_amount,
                  paid_amount,
                }: any,
                index: number,
              ) => {
                return (<>
                  <Grid item display="none">
                    <TextBox
                      type="text"
                      defaultValue={pid}
                      {...register(`miscellaneousItem.${index}.pid`)}
                    />
                    <TextBox
                      type="text"
                      {...register(`miscellaneousItem.${index}..code`)}
                      defaultValue={code}
                    />
                    <TextBox
                      type="text"
                      {...register(`miscellaneousItem.${index}.title`)}
                      defaultValue={title}
                    />
                    <MaskTextBox
                      required
                      defaultValue={discount_amount}
                      name={`miscellaneousItem.${index}.discount_amount`}
                      control={control}
                      inputMode="numeric"
                      maskOptions={{
                        integerLimit: 4,
                        allowNegative: false,
                        allowDecimal: true,
                        decimalSymbol: '.',
                        includeThousandsSeparator: false,
                        allowLeadingZeroes: true,
                        decimalLimit: 2,
                      }}
                      rules={{
                        required: true,
                        validate: (value: number) => {
                          if (
                            +value >
                            +watch(`miscellaneousItem.${index}.total_amount`)
                          )
                            return 'Discount cannot be greater than total amount';
                        },
                      }}
                      error={
                        errors?.miscellaneousItem?.[index]?.discount_amount
                      }
                      onValueChange={(e: any) => {
                        const value = e.target.value;
                        const total_amount =
                          +watch(`miscellaneousItem.${index}.total_amount`) -
                          +value;
                        setValue(
                          `miscellaneousItem.${index}.paid_amount`,
                          total_amount,
                        );
                      }}
                    />
                    <MaskTextBox
                      defaultValue={qty > 0 ? qty : 1}
                      name={`miscellaneousItem.${index}.qty`}
                      control={control}
                      inputMode="numeric"
                      maskOptions={{
                        integerLimit: 3,
                        allowNegative: false,
                        allowDecimal: false,
                        decimalSymbol: '.',
                        includeThousandsSeparator: false,
                        allowLeadingZeroes: true,
                      }}
                      rules={{
                        required: true,
                      }}
                      error={errors?.['miscellaneousItem']?.[index]?.['qty']}
                      onValueChange={(e: any) => {
                        const value = e.target.value;
                        if (value > 0) {
                          const total_amount = Number(watch(`miscellaneousItem.${index}.rate`)) * Number(value);
                          setValue(`miscellaneousItem.${index}.total_amount`, total_amount);
                        }
                        setValue(`miscellaneousItem.${index}.qty`, value);
                      }}
                      disabled
                    />

                  </Grid>
                  <Grid item display="none" lg={12} sm={12} xs={12}>
                    <CustomSelect
                      disableClearable
                      control={control}
                      placeholder='Select Item...'
                      options={expenseOption}
                      defaultValue={expenseOption?.find((x: any) => x.value == expense_item)}
                      name={`miscellaneousItem.${index}.expense_item`}
                      value={expenseOption?.find((x: any) => x.value == expense_item)}
                      onChangeValue={selected =>
                        handleSelect(selected, index)
                      }
                      rules={{
                        required: true,
                      }}
                      disabled
                      error={errors?.['miscellaneousItem']?.[index]?.['expense_item']}
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <MaskTextBox
                      label={formatMessage({ id: 'rate' })}
                      defaultValue={rate}
                      name={`miscellaneousItem.${index}.rate`}
                      control={control}
                      inputMode="numeric"
                      maskOptions={{
                        integerLimit: 8,
                        allowNegative: false,
                        allowDecimal: true,
                        decimalSymbol: '.',
                        includeThousandsSeparator: false,
                        allowLeadingZeroes: true,
                        decimalLimit: 2,
                      }}
                      rules={{
                        required: true,
                      }}
                      onValueChange={(e: any) => {
                        const value = e.target.value;
                        if (value > 0) {
                          const total_amount = Number(watch(`miscellaneousItem.${index}.qty`)) * Number(value);
                          setValue(`miscellaneousItem.${index}.total_amount`, total_amount);
                        }
                        setValue(`miscellaneousItem.${index}.rate`, value);
                      }}
                      error={errors?.['miscellaneousItem']?.[index]?.['rate']}
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <MaskTextBox
                      required
                      label={formatMessage({ id: 'paid-amount' })}
                      defaultValue={paid_amount}
                      name={`miscellaneousItem.${index}.paid_amount`}
                      control={control}
                      inputMode="numeric"
                      maskOptions={{
                        integerLimit: 8,
                        allowNegative: false,
                        allowDecimal: true,
                        decimalSymbol: '.',
                        includeThousandsSeparator: false,
                        allowLeadingZeroes: true,
                        decimalLimit: 2,
                      }}
                      rules={{
                        required: true,
                      }}
                      onValueChange={(e: any) => {
                        const value = Number(e.target.value);
                        setValue(
                          `miscellaneousItem.${index}.total_amount`,
                          value,
                        );
                        setValue(
                          `miscellaneousItem.${index}.paid_amount`,
                          value,
                        );
                      }}
                      error={errors?.miscellaneousItem?.[index]?.paid_amount}
                    />
                  </Grid>
                </>
                );
              },
            )
            }
          </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2, margin: 2 }}>
          <Button variant='contained' color='info' startIcon={<Save />} onClick={handleSubmit(onSubmit)}>Submit</Button>
        </Box>
      </CustomModal >
    </>
  );
};

export default Form;
