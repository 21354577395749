import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import Tesseract from 'tesseract.js';
import { Grid, Card, CardMedia, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Button, Stack, Box, Alert, AlertTitle } from '@mui/material';
import { Download, Refresh, Save, Verified } from '@mui/icons-material';

import { CustomCheckBox, CustomDatePicker, CustomFileUpload, CustomTextBox } from 'components/forms';
import { convertToDate, fetchCompletionsApi, validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import { FileAcceptData, fileValidate } from 'utils/constants';
import { services } from 'redux/services';

import { CustomModal } from 'components';
import VisaSamplePhoto from 'assets/images/visa-sample.png';
import useResponsive from 'hooks/useResponsive';
import OpenAIStepper from 'components/OpenAIStepper';
import moment from 'moment';

interface VisaInformation {
    permitOrVisaNumber: string;
    dateOfIssue: string;
    passportNumber: string;
    profession: string;
    fullName: string;
    dateOfBirth: string;
    uidOrApplicationNumber: string;
    nationality: string;
    purposeOfVisa: string;
    visaValidity: string;
    visaType: string;
}
const defaultInfo = {
    permitOrVisaNumber: '-',
    dateOfIssue: '-',
    passportNumber: '-',
    profession: '-',
    fullName: '-',
    dateOfBirth: '-',
    uidOrApplicationNumber: '-',
    nationality: '-',
    purposeOfVisa: '-',
    visaValidity: '-',
    visaType: '-'
}

interface Props {
    open: boolean;
    onClose: () => void;
    defaultData?: any;
    refreshData?: any;
    candidateData: {
        uuid: string,
        passport_number: string,
        dob_ad: string,
        full_name_search: string,
        passport_nationality: string
    };
    formType: 'create' | 'update'
}

const VisaForm = ({ onClose, open, defaultData, candidateData, refreshData, formType }: Props) => {
    const isDesktop = useResponsive("up", "lg");
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm({ mode: 'all' });
    const { formatMessage } = useIntl();

    const [parseData, setParsedData] = useState<VisaInformation>(defaultInfo);
    const [defaultSampleUrl, setDefaultSampleUrl] = useState<any>(VisaSamplePhoto);
    const [progressStep, setProgressStep] = useState<number>(0);
    const [refetchInformation, setRefetchInformation] = useState<boolean>(false);
    const [validateMsg, setValidateMsg] = useState<any>(['Please ensure that the visa information is validated before submitting.']);
    const [validateSuccessMsg, setValidateSuccessMsg] = useState<any>([]);
    const [fileImage, setFileImage] = useState({
        file: '',
    })
    function onSubmit(data: any) {
        if (formType === 'update') {
            data.id = defaultData?.id;
            data.has_departure_confirm = data?.has_departure_confirm ? 1 : 0;
            data.has_labour_clearance = data?.has_labour_clearance ? 1 : 0;
            data.has_ticket_confirm = data?.has_ticket_confirm ? 1 : 0;
        } else {
            data.visa_extracted_info = parseData ? JSON.stringify(parseData) : "";
            data.candidate_uuid = candidateData.uuid;
        }
        data.file = data?.file?.[0];
        data.dob = dayjs(data?.dob).format('YYYY-MM-DD');
        data.date_of_issue = dayjs(data?.date_of_issue).format('YYYY-MM-DD');
        data.visa_validity = data?.visa_validity ? dayjs(data?.date_of_issue).format('YYYY-MM-DD') : null;
        const apiFunc = formType === 'create' ? 'createCandidateVisa' : 'updateCandidateVisa';
        const message = formType === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                refreshData();
                onClose();
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'visa' }) }));
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message, 'error');
            })
    }

    const performOCR = (imageFile: string) => {
        if (imageFile) {
            setTimeout(() => {
                setProgressStep(2);
            }, 1000);
            Tesseract.recognize(
                imageFile,
                'eng', // language code, you can change it as needed
                // { logger: (info) => console.log(info) }
            ).then(({ data: { text } }) => {
                setProgressStep(3);
                submitPassport(text);
            }).catch(err => {
                setProgressStep(0);
                console.error(err);
            });
        }
    };

    const submitPassport = async (text: string) => {
        const systemPrompt = 'Do not engage in anything other than extracting this given text.';
        const jsonOutputFormat = `{
                "permitOrVisaNumber": "",
                "dateOfIssue": "",
                "passportNumber": "",
                "profession": "",
                "fullName": "",
                "dateOfBirth": "",
                "uidOrApplicationNumber": "",
                "nationality": "",
                "purposeOfVisa": "",
                "visaValidity": "",
                "visaType": "",
        }`;
        const buildUserPrompt = `${text}\n.Output JSON format extracted from the provided text must be: ${jsonOutputFormat}`;

        try {
            await fetchCompletionsApi(
                {
                    messages: [
                        { role: 'system', content: systemPrompt },
                        { role: 'user', content: buildUserPrompt },
                    ],
                },
                (value: any) => {
                    const parsed = JSON.parse(value) as VisaInformation;
                    const dob = convertToDate(parsed.dateOfBirth);
                    const passportIssueDate = convertToDate(parsed.dateOfIssue) as Date;
                    const visaValidityDate = convertToDate(parsed.visaValidity);
                    setValue('visa_validity', visaValidityDate);
                    setValue('date_of_issue', passportIssueDate);
                    setValue('dob', dob);
                    setValue('passport_number', parsed.passportNumber);
                    setValue('full_name', parsed.fullName);
                    setValue('nationality', parsed.nationality);
                    setValue('visa_number', parsed.permitOrVisaNumber);
                    setValue('profession', parsed.profession);
                    setValue('purposeOfVisa', parsed.purposeOfVisa);
                    setValue('application_number', parsed.uidOrApplicationNumber);
                    setValue('visa_type', parsed.visaType);
                    setParsedData(parsed);
                    setRefetchInformation(true);
                    setProgressStep(4);
                }
            );
        } catch (e) {
            setProgressStep(0);
            toastMessage(e || 'Something Wrong', 'error');
        }
    }

    useEffect(() => {
        if (defaultData) {
            reset({
                id: defaultData.id,
                full_name: defaultData.full_name,
                visa_number: defaultData.visa_number,
                application_number: defaultData.application_number,
                date_of_issue: defaultData.date_of_issue,
                passport_number: defaultData.passport_number,
                nationality: defaultData.nationality,
                dob: defaultData.dob,
                visa_validity: defaultData.visa_validity,
                visa_type: defaultData.visa_type,
                profession: defaultData.profession,
                company: defaultData.company,
                has_departure_confirm: defaultData.has_departure_confirm,
                has_labour_clearance: defaultData.has_labour_clearance,
                has_ticket_confirm: defaultData.has_ticket_confirm,
                file: null
            });
            setFileImage({ file: defaultData.ticket_image })
        }
    }, [
        defaultData,
    ])

    const handleFetch = () => {
        setProgressStep(1);
        const files = watch('file');
        if (files?.length > 0) {
            performOCR(files?.[0]);
        }
    }

    const handleValidate = () => {
        let msg = [];
        if (watch('passport_number')?.toLowerCase() != candidateData.passport_number?.toLowerCase()) {
            msg.push('Passport number does not match.');
        }
        if (watch('full_name')?.toLowerCase() != candidateData.full_name_search?.toLowerCase()) {
            msg.push('Full name does not match.')
        }
        if (!moment(moment(watch('dob')).format('YYYY-MM-DD')).isSame(moment(candidateData.dob_ad).format('YYYY-MM-DD'))) {
            msg.push('Date of birth does not match.')
        }
        if (msg.length > 0) {
            setValidateMsg(msg);
            setValidateSuccessMsg([]);
        } else {
            setValidateMsg([]);
            setValidateSuccessMsg(['Passport number, full name, and date of birth have been verified successfully.'])
        }
    }


    function validationRender() {
        return <TableContainer component={Paper}>
            <Table size='small'>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">Passport Number</TableCell>
                        <TableCell>{candidateData?.passport_number}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Full Name</TableCell>
                        <TableCell>{candidateData?.full_name_search}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Date of Birth</TableCell>
                        <TableCell>{candidateData?.dob_ad}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            {validateMsg?.map((v: any, i: number) =>
                <Alert severity="error" key={i}>
                    {v}
                </Alert>
            )}
            {validateSuccessMsg?.map((v: any, i: number) =>
                <Alert severity="success" key={i}>
                    {v}
                </Alert>
            )}
        </TableContainer>
    }

    return (
        <CustomModal
            open={open}
            onClose={onClose}
            size={isDesktop ? "xl" : "xs"}
            hideBackdrop={false}
        >
            <Box sx={{ padding: 3 }}>
                {formType === 'create' &&
                    <Alert severity="info" variant='standard' sx={{ marginBottom: 2 }}>
                        <AlertTitle>Note</AlertTitle>
                        {formatMessage({ id: "ai-warning-message" })}
                    </Alert>
                }
                {progressStep > 0 &&
                    <OpenAIStepper activeStep={progressStep} handleClose={() => setProgressStep(0)} />
                }
                <Grid container spacing={2} marginBottom={2}>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Grid container spacing={2}>
                            {formType === 'create' && <Fragment>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <CustomFileUpload
                                        accept={FileAcceptData.IMAGES}
                                        error={errors?.file}
                                        {...register('file', {
                                            validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true,
                                        })}
                                        onClear={() => {
                                            setValue(`file`, null);
                                            clearErrors(`file`);
                                            setParsedData(defaultInfo);
                                            reset();
                                            setDefaultSampleUrl(VisaSamplePhoto);
                                        }}
                                        onChange={(file: any) => {
                                            setValue('file', file);
                                            setDefaultSampleUrl(null);
                                        }}
                                        hasDefaultSampleImage
                                        defaultFileUrl={defaultSampleUrl}
                                        warningMessage={defaultSampleUrl ? 'Image must be in the portrait format shown above.' : ''}
                                    />
                                    <Button
                                        color="warning"
                                        disabled={watch('file')?.length > 0 ? false : true}
                                        startIcon={refetchInformation ? <Refresh /> : <Download />}
                                        variant='outlined'
                                        fullWidth sx={{ marginTop: 1, marginBottom: 2 }}
                                        onClick={handleFetch}
                                    >
                                        Fetch visa information using AI Engine
                                    </Button>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    {validationRender()}
                                </Grid>
                            </Fragment>
                            }
                            {formType === 'update' &&
                                <Grid item xs={12}>
                                    {validationRender()}
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <Grid container spacing={2}>
                            {formType === 'create' ? <Fragment>
                                {parseData &&
                                    <Grid item xs={12}>
                                        <TableContainer component={Paper}>
                                            <Table size='small'>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">Nationality Code</TableCell>
                                                        <TableCell>{parseData?.nationality}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">Visa Number</TableCell>
                                                        <TableCell>{parseData?.permitOrVisaNumber}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">Passport Number</TableCell>
                                                        <TableCell>{parseData?.passportNumber}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">Full Name</TableCell>
                                                        <TableCell>{parseData?.fullName}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">Profession</TableCell>
                                                        <TableCell>{parseData?.profession}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">Visa Type</TableCell>
                                                        <TableCell>{parseData?.visaType}</TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell component="th" scope="row">Date of Birth</TableCell>
                                                        <TableCell>{parseData?.dateOfBirth}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">Application Number</TableCell>
                                                        <TableCell>{parseData?.uidOrApplicationNumber}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">Date of Issue</TableCell>
                                                        <TableCell>{parseData?.dateOfIssue}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">Visa Validity</TableCell>
                                                        <TableCell>{parseData?.visaValidity}</TableCell>
                                                    </TableRow>
                                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell component="th" scope="row">Purpose of the visa</TableCell>
                                                        <TableCell>{parseData?.purposeOfVisa}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                }
                            </Fragment> : <>
                                <Grid item xs={12}>
                                    <CustomCheckBox
                                        name="has_departure_confirm"
                                        label={formatMessage({ id: "has-departure-confirm" })}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomCheckBox
                                        name="has_labour_clearance"
                                        label={formatMessage({ id: "has-labour-clearance" })}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomCheckBox
                                        name="has_ticket_confirm"
                                        label={formatMessage({ id: "has-ticket-confirm" })}
                                        control={control}
                                    />
                                </Grid>
                            </>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item lg={formType === 'create' ? 12 : 8} md={formType === 'create' ? 12 : 8} sm={12} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item lg={3} md={6} sm={12} xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "full-name" })}
                                    name="full_name"
                                    control={control}
                                    error={errors?.full_name}
                                    rules={validationRule.textbox({ required: true })}
                                />
                            </Grid>
                            <Grid item lg={3} md={6} sm={12} xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "visa-number" })}
                                    name="visa_number"
                                    control={control}
                                    error={errors?.visa_number}
                                    rules={validationRule.textbox({ required: true })}
                                />
                            </Grid>
                            <Grid item lg={3} md={6} sm={12} xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "application-number" })}
                                    name="application_number"
                                    control={control}
                                    error={errors?.application_number}
                                />
                            </Grid>

                            <Grid item lg={3} md={6} sm={12} xs={12}>
                                <CustomDatePicker
                                    control={control}
                                    label={formatMessage({ id: "date-of-issue" })}
                                    name="date_of_issue"
                                    error={errors.date_of_issue}
                                    rules={{ required: true }}
                                    maxDate={new Date()}
                                />
                            </Grid>
                            <Grid item lg={3} md={6} sm={12} xs={12}>
                                <CustomDatePicker
                                    control={control}
                                    label={formatMessage({ id: "dob" })}
                                    name="dob"
                                    error={errors.dob}
                                    rules={{
                                        required: true,
                                        validate: (value: any) => (dayjs().diff(value, 'year') >= 18) || formatMessage({ id: "age-validation-message-18" })
                                    }}
                                    maxDate={new Date()}
                                />
                            </Grid>
                            <Grid item lg={3} md={6} sm={12} xs={12}>
                                <CustomDatePicker
                                    control={control}
                                    label={formatMessage({ id: "visa-validity" })}
                                    name="visa_validity"
                                />
                            </Grid>
                            <Grid item lg={3} md={6} sm={12} xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "passport-number" })}
                                    name="passport_number"
                                    control={control}
                                    error={errors?.passport_number}
                                    rules={validationRule.textbox({ required: true })}
                                />
                            </Grid>
                            <Grid item lg={3} md={6} sm={12} xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "visa-type" })}
                                    name="visa_type"
                                    control={control}
                                />
                            </Grid>
                            <Grid item lg={3} md={6} sm={12} xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "profession" })}
                                    name="profession"
                                    control={control}
                                />
                            </Grid>
                            <Grid item lg={3} md={6} sm={12} xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "nationality" })}
                                    name="nationality"
                                    control={control}
                                />
                            </Grid>
                            <Grid item lg={3} md={6} sm={12} xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "company" })}
                                    name="company"
                                    control={control}
                                    error={errors?.company}
                                    rules={validationRule.textbox({ required: true })}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {formType === 'update' &&
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Grid container spacing={2}>
                                {watch('has_ticket_confirm') == 1 &&
                                    <Grid item xs={12}>
                                        <CustomFileUpload
                                            accept={FileAcceptData.IMAGES}
                                            formLabel="Ticket Image"
                                            defaultFileUrl={fileImage.file}
                                            error={errors?.file}
                                            {...register('file', {
                                                validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true,
                                            })}
                                            onClear={() => {
                                                setValue(`file`, null);
                                                clearErrors(`file`);
                                                reset();
                                            }}
                                            onChange={(file: any) => {
                                                setValue('file', file);
                                            }}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    }
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" justifyContent="flex-end" my={5} gap={3}>
                            <Button
                                variant="contained"
                                onClick={handleValidate}
                                startIcon={<Verified />}
                            >
                                Validate
                            </Button>
                            <Button
                                disabled={validateMsg?.length > 0 ? true : false}
                                variant="contained"
                                type='submit'
                                color='info'
                                onClick={handleSubmit(onSubmit)}
                                startIcon={<Save />}
                            >
                                {formType === 'create' ? 'Submit' : 'Update'}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </CustomModal>
    )
}

export default VisaForm;