import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
// material
import { Grid, InputAdornment, Tooltip } from '@mui/material';

import { CustomCheckBox, CustomFileUpload, CustomSelect, CustomTextBox } from 'components/forms';
import { HoverLoader } from 'components';
import { getUpdateImageFormat, getUploadImageFormat, validationRule } from 'utils/global';
import { useCheckRole, useCreateLookupOptions, useToastMessage } from 'hooks';
import FormLayout from 'layouts/FormLayout';
import SectionLayout from 'layouts/SectionLayout';
import { FileAcceptData, fileValidate, images, masterLookupServices } from 'utils/constants';
import { services } from 'redux/services';
import { RootReducerState } from 'utils/types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getMasterLookupData } from 'redux/actions';
import Info from '@mui/icons-material/Info';

const Form: React.FC = () => {
    const { formType, uuid }: any = useParams<any>();
    const dispatch = useDispatch();
    const { toastMessage } = useToastMessage();
    const navigate = useNavigate();
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm<any>({
        mode: 'all',
        defaultValues: {
            is_active: true
        }
    });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [fileImage, setFileImage] = useState({
        file: '',
    })
    const { isAdmin, isSuperAdmin } = useCheckRole();

    const [defaultData, setDefaultData] = useState<any>(null);
    const [provinceOption, setProvinceOption] = useState<any>([]);

    const { userLookup } = useSelector(
        ({ masterLookupReducer }: RootReducerState) => {
            return ({
                userLookup: masterLookupReducer[masterLookupServices.getUserLookup].data
            })
        },
        shallowEqual
    );

    const selectOptions = useCreateLookupOptions(userLookup);

    useEffect(() => {
        if (userLookup) {
            const provinceSelectState = userLookup?.province?.map((item: any) => ({
                value: item?.id,
                label: item?.name,
                districts: item?.districts?.map((dis: any) => ({
                    value: dis?.id,
                    label: dis?.name,
                    municipalities: dis?.municipalities?.map((mun: any) => ({
                        value: mun?.id,
                        label: mun?.name,
                    }))
                }))
            }));

            setProvinceOption(provinceSelectState)
        }
    }, [userLookup])

    useEffect(() => {
        setLoading(false);
        dispatch(getMasterLookupData(masterLookupServices.getUserLookup));
    }, [])

    function onSubmit(data: any) {
        data.file = data?.file?.[0];
        data.role = defaultData?.role.includes('admin') ? defaultData.role : data?.role?.map(({ label }: any) => label);
        data.is_active = data?.is_active ? 1 : 0;
        if (formType === 'update') data.uuid = uuid;
        const apiFunc = formType === 'create' ? 'createUser' : 'updateUser';
        const message = formType === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                reset();
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'user' }) }));
                navigate(-1)
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || "Something wrong !", 'error');
            }).finally(() => setLoading(false))
    }


    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (uuid) {
            setLoading(true);
            services.getUserByUuid({ uuid })
                .then((res: any) => {
                    setLoading(false);
                    const response = res.result;
                    setDefaultData({
                        address: response.address,
                        email: response.email,
                        full_name: response.full_name,
                        is_active: response.is_active,
                        phone: response.phone,
                        role: response.role,
                    });
                    setFileImage({ file: response.profile_image })
                })
                .catch((err: any) => {
                    setLoading(false);
                    toastMessage(err?.data?.message || "Something wrong !", 'error');
                })
        }
    }, [formType === 'update', uuid]);


    useEffect(() => {
        if (defaultData) {
            reset({
                ...defaultData,
                role: selectOptions?.roles?.filter((item: any) => defaultData.role?.includes(item.label)),
            });
        }
    }, [defaultData, selectOptions]);

    return (
        <FormLayout formType={formType} title={formatMessage({ id: "user" })} onSubmit={handleSubmit(onSubmit)}>
            {loading && <HoverLoader />}
            <SectionLayout title={formatMessage({ id: "general-information" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "full-name" })}
                            name="full_name"
                            control={control}
                            error={errors?.full_name}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "email" })}
                            name="email"
                            control={control}
                            error={errors?.email}
                            rules={validationRule.textbox({ required: true, type: "email" })}
                            disabled={uuid ? true : false}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "phone" })}
                            name="phone"
                            control={control}
                            error={errors?.phone}
                        />
                    </Grid>
                    {!(defaultData?.role.includes('admin') && isAdmin) &&
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomSelect
                                options={selectOptions?.roles ?? []}
                                label={formatMessage({ id: "role" })}
                                name="role"
                                control={control}
                                error={errors.role}
                                rules={{ required: true }}
                                multiple
                            />
                        </Grid>
                    }

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "password" })}
                            name="password"
                            control={control}
                            error={errors?.password}
                            type="password"
                            rules={uuid ? {} : {
                                ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                                validate: (value: any) => (watch('confirmation_password') ? (watch('confirmation_password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                            }}
                            autoComplete="off"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                                        <Tooltip title={formatMessage({ id: "password-policy-message" })}>
                                            <Info color="primary" />
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "confirm-password" })}
                            name="confirmation_password"
                            control={control}
                            error={errors?.confirmation_password}
                            type="password"
                            rules={uuid ? {} : {
                                ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                                validate: (value: any) => (watch('password') ? (watch('password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                            }}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "address" })}
                            name="address"
                            control={control}
                            error={errors?.address}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomCheckBox
                            name="is_active"
                            label={formatMessage({ id: "is-active" })}
                            control={control}
                        />
                    </Grid>
                </Grid>
            </SectionLayout>
            <SectionLayout title={formatMessage({ id: "Photo" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomFileUpload
                            accept={FileAcceptData.IMAGES}
                            label={formatMessage({ id: "photo" })}
                            error={errors?.logo}
                            {...register('file', {
                                validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true,
                            })}
                            defaultFileUrl={fileImage.file}
                            onClear={() => {
                                setValue(`file`, null);
                                clearErrors(`file`);
                            }}
                            onChange={(file: any) => setValue('file', file)}
                        />
                    </Grid>
                </Grid>
            </SectionLayout>

        </FormLayout>
    )
}

export default Form;