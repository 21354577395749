
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from "react-intl";
import { Approval, Delete, DocumentScanner, Edit, FilePresent, Refresh, RemoveRedEye, Send, Reviews, PasswordSharp, Check } from '@mui/icons-material';

import CustomTable from 'components/table';
import { ActionMenu, ConfirmationButton, CustomButton } from 'components';
import { candidateStatusEnum, masterPaginationServices, tableInitialState, } from 'utils/constants';
import { getCandiateStatus, getTableParams } from 'utils/global';
import { RootReducerState } from 'utils/types';
import { getMasterPaginationData } from 'redux/actions';
import PageLayout from 'layouts/PageLayout';
import { candidateColumns } from 'utils/constants/tableColumn/column';
import { services } from 'redux/services';
import { useCheckPermissions, useCheckRole, useToastMessage } from 'hooks';
import FlagForm from './FlagForm';
import VerifyDocument from './VerifyDocument';
import { VisaForm } from 'pages';
import VisaView from 'pages/visa/View';
import CustomCandidateTab from './CustomCandidateTab';
import OriginalPassportRecievedForm from './OriginalPassportRecievedForm';

const permissionSet = {
    candidateSave: ['candidates.createCandidate'],
    candidateUpdate: ['candidates.updateCandidate'],
    flagCandidateStatus: ['candidates.flagCandidateStatus'],
    updateOriginalPassportRecievedDate: ['candidates.updateOriginalPassportRecievedDate'],
    candidateDelete: ['candidates.deleteCandidate'],
    candidateVisas: ['candidateVisas.listVisa'],
    sendDocumentForReview: ['candidates.sendDocumentForReview'],
    sendDocumentForProcess: ['candidates.sendDocumentForProcess'],
    verifyDocumentReviewRequest: ['candidates.verifyDocumentReviewRequest'],
    verifyDocumentProcessRequest: ['candidates.verifyDocumentProcessRequest'],
    candidateExports: ['candidates.candidateListExport'],
}

const Candidate: React.FC = () => {
    const { status } = useParams();
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const { toastMessage } = useToastMessage();
    const dispatch = useDispatch();
    const { isAdmin, isReceptionist, isDeparture, isAgent, isDocumentation } = useCheckRole();
    const checkPermissions = useCheckPermissions(permissionSet);
    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [visaModal, setVisaModal] = useState(false);
    const [visaViewModal, setVisaViewModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [viewFlagForm, setViewFlagForm] = useState(false);
    const [passportOGModal, setPassportOGModal] = useState(false);
    const [selectedData, setSelectedData] = useState<any>();
    const { userData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            userData: masterPaginationReducer[masterPaginationServices.candidate].data,
            loading: masterPaginationReducer[masterPaginationServices.candidate].loading,
        }),
        shallowEqual
    );

    const { rows, count } = userData;

    useEffect(() => {
        onApiCall();
    }, [tableState, status]);

    function onApiCall() {
        const params = getTableParams(tableState);
        params.status = status;
        dispatch(getMasterPaginationData(masterPaginationServices.candidate, params));
    }

    let columnAction = {
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                const rowData = rows[tableMeta.rowIndex];
                const { isReviewing, isRequested, isPending, isCancelled, isApproved, isReviewed } = getCandiateStatus(rowData?.status)
                return (
                    <ActionMenu>
                        {isReviewing && (checkPermissions.verifyDocumentReviewRequest || checkPermissions.verifyDocumentProcessRequest) ?
                            <CustomButton
                                size='small'
                                label={"Review Information"}
                                variant='outlined'
                                onClick={() => handleViewOption(rowData)}
                                startIcon={<Check />}
                            /> : <CustomButton
                                size='small'
                                label={isRequested && (checkPermissions.verifyDocumentReviewRequest || checkPermissions.verifyDocumentProcessRequest) ? "Approve" : "View"}
                                variant='outlined'
                                onClick={() => handleViewOption(rowData)}
                                startIcon={isRequested && (checkPermissions.verifyDocumentReviewRequest || checkPermissions.verifyDocumentProcessRequest) ? <Approval /> : <RemoveRedEye />}
                            />
                        }

                        {isApproved &&
                            <CustomButton
                                size='small'
                                label="Resume"
                                variant='outlined'
                                sx={{ color: 'purple', borderColor: 'purple' }}
                                onClick={() => handleExportResume(rowData)}
                                startIcon={<DocumentScanner />}
                            />
                        }

                        {checkPermissions.candidateUpdate && !isApproved &&
                            <CustomButton
                                color='secondary'
                                size='small'
                                label="Edit"
                                variant='outlined'
                                onClick={() => handleRowClick(rowData)}
                                startIcon={<Edit />}
                            />
                        }

                        {checkPermissions.flagCandidateStatus && isApproved &&
                            <CustomButton
                                color='success'
                                size='small'
                                label="Flag"
                                variant='outlined'
                                onClick={() => handleFlagOption(rowData)}
                                startIcon={<Edit />}
                                disabled={rowData?.is_flagged === 1 ? true : false}
                            />
                        }

                        {checkPermissions.candidateDelete && !isApproved &&
                            <ConfirmationButton
                                color='error'
                                size='small'
                                label="Delete"
                                variant='outlined'
                                onConfirm={() => onDeleteData(rowData)}
                                title={formatMessage({ id: "delete-title" })}
                                subTitle={formatMessage({ id: "delete-confirmation" })}
                                startIcon={<Delete />}
                            />
                        }

                        {(isPending || isReviewed) && (checkPermissions.sendDocumentForReview || checkPermissions.sendForProccess) &&
                            <ConfirmationButton
                                startIcon={<Send />}
                                color='warning'
                                size='small'
                                variant='outlined'
                                onConfirm={() => handleStatus({ uuid: rowData.uuid, status: isAgent ? candidateStatusEnum.reviewing : candidateStatusEnum.requested })}
                                label={`Send for ${isAgent ? "Review" : "Process"}`}
                                title={`Send for ${isAgent ? "Review" : "Process"}`}
                                subTitle='Please verify that all informations have been appropriately updated before submission.'
                            />
                        }

                        {(checkPermissions.sendDocumentForReview || checkPermissions.sendForProccess) && isCancelled &&
                            <ConfirmationButton
                                startIcon={<Refresh />}
                                color='warning'
                                size='small'
                                variant='outlined'
                                onConfirm={() => handleStatus({ uuid: rowData.uuid, status: isAgent ? candidateStatusEnum.reviewing : candidateStatusEnum.requested })}
                                label={`Send for ${isAgent ? "Review" : "Process"}`}
                                title={`Send for ${isAgent ? "Review" : "Process"}`}
                                subTitle='Please verify that all informations have been appropriately updated before re submission.'
                            />
                        }

                        {checkPermissions.candidateVisas && isApproved &&
                            <CustomButton
                                color='warning'
                                size='small'
                                label="Visa"
                                variant='outlined'
                                onClick={() => handleVisaClick(rowData)}
                                startIcon={<FilePresent />}
                            />
                        }
                        {checkPermissions.updateOriginalPassportRecievedDate &&
                            <CustomButton
                                color='info'
                                size='small'
                                label="OG Passport"
                                variant='outlined'
                                onClick={() => handleOriginalPassport(rowData)}
                                startIcon={<PasswordSharp />}
                            />
                        }
                    </ActionMenu>
                )
            }
        }
    }

    function handleVisaClick(rowData: any) {
        if (isDeparture && rowData?.visas?.length === 0) {
            toastMessage("The candidate's visa information is not available. Please notify the user with the documenter role to upload the required information", 'warning');
            return false;
        };
        if (rowData?.visas?.length > 0) {
            setVisaViewModal(true);
        } else {
            setVisaModal(true);
        }
        setSelectedData(rowData)
    }

    function handleRowClick(rowData: any) {
        navigate(`/admin/candidates/update/general/${rowData?.uuid}`, { ...rowData });
    }

    function handleViewOption(rowData: any) {
        setViewModal(true);
        setSelectedData(rowData)
    }

    function handleFlagOption(rowData: any) {
        setViewFlagForm(true);
        setSelectedData(rowData)
    }

    function handleOriginalPassport(rowData: any) {
        setPassportOGModal(true);
        setSelectedData(rowData)
    }

    function handleStatus(params: any) {
        services.updateCandidateStatus(params)
            .then((res: any) => {
                onApiCall();
                toastMessage(formatMessage({ id: 'updated-message' }, { title: "candidate" }));
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || "Something wrong !", 'error');
            })
    }

    function handleExportResume(data: any) {
        setDeleteLoading(true);
        services.exportCandidateResume({ uuid: data.uuid })
            .then((res: any) => {
                window.open(res.result.url)
                // toastMessage(formatMessage({ id: "deleted-message" }, { title: formatMessage({ id: 'candidate' }) }));
            })
            .catch((err) => {
                toastMessage(err?.data?.message || formatMessage({ id: "opps-error-message" }), 'error');
            })
            .finally(() => setDeleteLoading(false))
    }

    function onDeleteData(data: any) {
        setDeleteLoading(true);
        services.deleteCandidate({ uuid: data.uuid })
            .then((res: any) => {
                onApiCall();
                toastMessage(formatMessage({ id: "deleted-message" }, { title: formatMessage({ id: 'candidate' }) }));
            })
            .catch((err) => {
                toastMessage(err?.data?.message || formatMessage({ id: "opps-error-message" }), 'error');
            })
            .finally(() => setDeleteLoading(false))
    }

    return (
        <PageLayout
            title={`${status} List`}
            isList={false}
            hideToolbar={checkPermissions.candidateSave ? false : true}
            createUrl="/admin/candidates/create"
        >
            {(!isDeparture || !isAgent) &&
                <CustomCandidateTab
                    status={status}
                    totalItems={count}
                    onChange={(tab: string) => {
                        navigate(`/admin/candidates/list/${tab}`);
                        setTableState(tableInitialState);
                    }}
                />
            }
            <CustomTable
                columns={[...candidateColumns(formatMessage), columnAction]}
                tableData={rows}
                tableState={tableState}
                rowsCount={count}
                setTableState={setTableState}
                loading={loading || deleteLoading}
                options={{
                    download:checkPermissions.candidateExports,
                    print:checkPermissions.candidateExports,
                }}
            />

            {viewModal &&
                <VerifyDocument
                    open={viewModal}
                    onClose={() => setViewModal(false)}
                    uuid={selectedData?.uuid}
                    refreshData={onApiCall}
                />
            }

            {visaModal &&
                <VisaForm
                    open={visaModal}
                    onClose={() => setVisaModal(false)}
                    candidateData={{
                        uuid: selectedData?.uuid,
                        passport_number: selectedData?.passport_number,
                        dob_ad: selectedData?.dob_ad,
                        full_name_search: selectedData?.full_name_search,
                        passport_nationality: selectedData?.passport_nationality,
                    }}
                    refreshData={onApiCall}
                    formType='create'
                />
            }

            {viewFlagForm &&
                <FlagForm
                    refreshData={onApiCall}
                    selectedData={selectedData}
                    open={viewFlagForm}
                    closeModal={() => setViewFlagForm(false)}
                    headerText={'Flagged Reason'}
                />
            }

            {visaViewModal &&
                <VisaView
                    open={visaViewModal}
                    onClose={() => setVisaViewModal(false)}
                    defaultData={selectedData?.visas}
                />
            }

            {passportOGModal &&
                <OriginalPassportRecievedForm
                    refreshData={onApiCall}
                    selectedData={selectedData}
                    open={passportOGModal}
                    closeModal={() => setPassportOGModal(false)}
                />
            }


        </PageLayout>
    )
}

export default Candidate;
