import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
// material
import { Grid } from '@mui/material';

import { CustomCheckBox, CustomFileUpload, CustomSelect, CustomTextArea, CustomTextBox } from 'components/forms';
import { HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import FormLayout from 'layouts/FormLayout';
import SectionLayout from 'layouts/SectionLayout';
import { FileAcceptData, fileValidate, images } from 'utils/constants';
import { services } from 'redux/services';
import countryList from 'utils/json/countries.json'

const Form: React.FC = () => {
    const { formType, uuid }: any = useParams<any>();
    const { toastMessage } = useToastMessage();
    const { formatMessage } = useIntl();
    const navigate = useNavigate();

    const [updateId, setUpdateId] = useState<number>(0);
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm<any>({ mode: 'all', defaultValues: { general_information: { is_active: 1 } } });
    const [loading, setLoading] = useState(false);
    const [fileImage, setFileImage] = useState({
        logo: '',
    })

    function onSubmit(data: any) {
        setLoading(true);
        data.logo = data?.logo?.[0];
        data.address.country = data?.address?.country?.value;
        if (formType === 'update') {
            data.id = updateId;
            data.uuid = uuid;
        }
        const apiFunc = formType === 'create' ? 'createCompany' : 'updateCompany';
        const message = formType === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                reset();
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'company' }) }));
                navigate(-1)
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || 'Something Wrong', 'error');
            }).finally(() => setLoading(false))
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (uuid) {
            setLoading(true);
            services.getCompanyByUuid({ uuid })
                .then((res: any) => {
                    const response = res.result;
                    setUpdateId(+response.id);
                    reset({
                        general_information: {
                            name: response.name,
                            short_description: response.short_description,
                            phone: response.phone,
                            contact: response.contact,
                            email: response.email,
                            is_active: response.is_active,
                            description: response.description,
                        },
                        address: {
                            address_line1: response.address_line1,
                            country: countryList.find((item: any) => item.value === response.country),
                        },
                    })
                    setFileImage({ logo: response.logo_path })
                })
                .catch((err: any) => {
                    toastMessage(err?.data?.message || 'Something Wrong', 'error');
                }).finally(() => setLoading(false))
        }

    }, [formType === 'update', uuid]);

    return (
        <FormLayout formType={formType} title={formatMessage({ id: "company" })} onSubmit={handleSubmit(onSubmit)}>
            {loading && <HoverLoader />}
            <SectionLayout title={formatMessage({ id: "company-information" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "company-name" })}
                            name="general_information.name"
                            control={control}
                            error={errors?.general_information?.name}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "email" })}
                            name="general_information.email"
                            control={control}
                            error={errors?.general_information?.email}
                            rules={validationRule.textbox({ type: "email" })}
                        />
                    </Grid>
                    {/* <Grid item lg={8} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "slogan" })}
                            name="general_information.short_description"
                            control={control}
                            error={errors?.general_information?.slogan}
                        />
                    </Grid> */}

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "phone-number" })}
                            name="general_information.phone"
                            control={control}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "contact" })}
                            name="general_information.contact"
                            control={control}
                            // error={errors?.general_information?.contact}
                            // rules={validationRule.textbox({ type: "contact", maxLength: 20 })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "address" })}
                            name="address.address_line1"
                            control={control}
                            error={errors?.address?.address_line1}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={countryList}
                            label={formatMessage({ id: "country" })}
                            name="address.country"
                            control={control}
                            error={errors?.address?.country}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextArea
                            formLabel={formatMessage({ id: "company-detail" })}
                            name="general_information.description"
                            control={control}
                            minRows={6}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomCheckBox
                            name="general_information.is_active"
                            label={formatMessage({ id: "is-active" })}
                            control={control}
                        />
                    </Grid>
                    
                </Grid>
            </SectionLayout>
            {/* <SectionLayout title={formatMessage({ id: "company-address" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "address-line2" })}
                            name="address.address_line2"
                            control={control}
                            error={errors?.address?.address_line2}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "city" })}
                            name="address.city"
                            control={control}
                            error={errors?.address?.city}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "state" })}
                            name="address.state"
                            control={control}
                            error={errors?.address?.state}
                            rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "zip" })}
                            name="address.zip"
                            control={control}
                            error={errors?.address?.zip}
                            rules={validationRule.textbox({ required: true, type: "number" })}
                        />
                    </Grid>
                    
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "country" })}
                            name="address.country"
                            control={control}
                            error={errors?.address?.country}
                            rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                        />
                    </Grid>
                </Grid>
            </SectionLayout> */}

            <SectionLayout title={formatMessage({ id: "company-files" })}>
                <Grid container spacing={2}>
                    <Grid item xs={6} >
                        <CustomFileUpload
                            accept={FileAcceptData.IMAGES}
                            label={formatMessage({ id: "choose-logo" })}
                            error={errors?.logo}
                            {...register('logo', {
                                validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true,
                            })}
                            defaultFileUrl={fileImage.logo}
                            onClear={() => {
                                setValue(`logo`, null);
                                clearErrors(`logo`);
                            }}
                            onChange={(file: any) => setValue('logo', file)}
                        />
                    </Grid>
                </Grid>
            </SectionLayout>

        </FormLayout>
    )
}

export default Form;