import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';

import TableContainer from '@mui/material/TableContainer';
import Table, { TableProps } from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import Paper, { PaperProps } from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

interface ColumnProps {
    label: string; // label referes to (column-name) and name refers to key name from tableData
    name: string;
    [key: string]: any;
}


interface Props {
    columns: ColumnProps[];
    colSpans?: number[];
    tableData: { [key: string]: any }[];
    paperProps?: PaperProps;
    tableProps?: TableProps;
    tableCellProps?: TableCellProps;
    loading?: boolean;
}


const SimpleTable = ({ columns, colSpans, tableData, paperProps, loading, tableProps, tableCellProps }: Props) => {
    const theme = useTheme();

    return (
        <Paper
            elevation={0}
            {...paperProps}
        >
            {loading && <LinearProgress sx={{ width: "100%" }} />}
            <TableContainer>
                <Table stickyHeader aria-label="sticky-table" size="small" padding="normal" {...tableProps}>
                    <TableHead>
                        <TableRow>
                            {columns.map((colName, index: number) => (
                                <TableCell
                                    key={index}
                                    width={`${colSpans?.[index] ? `${colSpans[index]}%` : 'auto'}`}
                                    // align={index === 0 ? "left" : "center"}
                                    sx={{
                                        backgroundColor: theme.palette.grey[300],
                                        color: theme.palette.grey[700],
                                    }}
                                    {...tableCellProps}
                                >
                                    <Typography variant='h6' style={{ fontSize: 14 }}>
                                        {colName.label ? <FormattedMessage id={colName.label} /> : null}
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {tableData.map((item: any, index: number) => {
                            return (
                                <TableRow hover key={index}>
                                    {columns.map((col, subIndex: number) => (
                                        <TableCell
                                            key={subIndex}
                                            padding="normal"
                                            sx={{ border: "1px solid rgba(0,0,0,0.05)", textTransform: 'capitalize' }}
                                            // align={subIndex === 0 ? "left" : "center"}
                                            {...tableCellProps}
                                        >
                                            {col?.options ? col.options.customBodyRender((item?.[col.name] || "-"), index) : item?.[col.name] || '-'}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {tableData.length === 0 && (
                <Box sx={{ textAlign: 'center', padding: 8, border: (theme) => `1px solid ${theme.palette.grey[200]}`, marginBottom: 10 }}>
                    <Typography variant='h6' color="warning">
                        <FormattedMessage id="no-records-found" />
                    </Typography>
                </Box>
            )}
        </Paper>
    )
}

export { SimpleTable };