import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import ExpenseForm from './Form';
import ViewDetail from './ViewDetail';
import DeleteForm from './DeleteForm';
import CustomTable from 'components/table';
import { expenseColumns } from 'utils/constants/tableColumn/column';
import { masterPaginationServices, tableInitialState } from 'utils/constants';
import { RootReducerState } from 'utils/types';
import { services } from 'redux/services';
import { ActionMenu, TableViewButton, TableEditButton, DeleteButton } from 'components';
import { useToastMessage, useCheckRole } from 'hooks';
import { useIntl } from 'react-intl';
import { getTableParams } from 'utils/global';
import { getMasterPaginationData } from 'redux/actions';
import { Button, Card, Grid, TextField } from '@mui/material';
import { PrimaryDatePicker } from 'components/forms';

import dayjs from 'dayjs';
import { Add } from '@mui/icons-material';

const List: React.FunctionComponent<any> = (): JSX.Element => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { toastMessage } = useToastMessage();
  const { isAdmin, isReceptionist, isAgent } = useCheckRole();

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [paymentFormModal, setPaymentFormModal] = useState(false);
  const [deleteFormModal, setDeleteFormModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [tableState, setTableState] = useState({
    ...tableInitialState,

  });
  const {
    expenseData,
    loading,
    miscellaneousList,
  } = useSelector(
    ({ auth, masterPaginationReducer }: RootReducerState) => ({
      currentUser: auth.data,
      miscellaneousList: masterPaginationReducer[masterPaginationServices.miscellaneous].data,
      expenseData: masterPaginationReducer[masterPaginationServices.expense].data,
      loading: masterPaginationReducer[masterPaginationServices.expense].loading,
    }),
    shallowEqual,
  );

  const { rows, count } = expenseData;

  function onApiCall(fromDate = startDate, toDate = endDate) {
    const params = getTableParams(tableState);
    params.extraFilter = {
      startDate: fromDate ? dayjs(fromDate).format('YYYY-MM-DD') : null,
      endDate: toDate ? dayjs(toDate).format('YYYY-MM-DD') : null,
      type: 'income',
      candidate: 1
    }
    dispatch(getMasterPaginationData(masterPaginationServices.expense, params));
  }

  useEffect(() => {
    dispatch(getMasterPaginationData(masterPaginationServices.candidate, { page: 0, limit: 100000 }));
    // dispatch(getMasterPaginationData(masterPaginationServices.miscellaneous, {}));
  }, []);

  useEffect(() => {
    onApiCall();
  }, [tableState]);

  // useEffect(() => {
  //   if (!isEmpty(exportResponse)) {
  //     window.open(exportResponse.url);
  //     dispatch(expenseItemActions.reset('export'));
  //   }
  // }, [exportResponse]);


  // const handleExport = mode => {
  //   if (listResponse?.length > 0) {
  //     confirmAlert({
  //       message: 'Are you sure want to download pdf ?',
  //       // buttons: [
  //       //   {
  //       //     label: 'Yes',
  //       //     onClick: () =>
  //       //       dispatch(expenseItemActions.exportBooking({ ...listParam, mode })),
  //       //   },
  //       //   {
  //       //     label: 'No',
  //       //     onClick: null,
  //       //   },
  //       // ],
  //     });
  //   } else {
  //     dispatch(globalActions.notifyWarning('No data to print.'));
  //   }
  // };

  const columnAction = {
    label: formatMessage({ id: "action" }),
    name: "",
    options: {
      customBodyRender: (_: any, tableMeta: any) => {
        const rowData = rows[tableMeta.rowIndex];
        return (
          <ActionMenu>
            <TableViewButton
              tooltipLabel="View"
              onClick={() => handleViewOption(rowData)}
            />

            {isAdmin &&
              <DeleteButton
                tooltipLabel="Delete"
                onDelete={() => onDeleteData(rowData)}
              />
            }
          </ActionMenu>
        )
      }
    }
  }

  function handleViewOption(rowData: any) {
    setViewModal(true);
    setSelectedData(rowData)
  }

  function onDeleteData(data: any) {
    setDeleteLoading(true);
    services.deleteExpense({ id: data.id, remarks: 'delete' })
      .then((res: any) => {
        onApiCall();
        toastMessage(formatMessage({ id: "deleted-message" }, { title: formatMessage({ id: 'candidate-fee' }) }));
      })
      .catch((err) => {
        toastMessage(err?.data?.message ? err?.data?.message : formatMessage({ id: "opps-error-message" }), 'error');
      })
      .finally(() => setDeleteLoading(false))
  }

  return (
    <>
      <Card sx={{ p: 2, marginBottom: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <PrimaryDatePicker
              value={startDate}
              onChange={(date: any) => setStartDate(date)}
              disableFuture
            />
          </Grid>
          <Grid item xs={2}>
            <PrimaryDatePicker
              value={endDate}
              onChange={(date: any) => setEndDate(date)}
              disableFuture
            />
          </Grid>
          <Grid item xs={1}>
            <Button variant='contained' onClick={() => onApiCall()}>
              Search
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button variant='contained'
              color="warning"
              onClick={() => {
                setStartDate(new Date());
                setEndDate(new Date());
                onApiCall(new Date(), new Date());
              }}
            >
              Reset
            </Button>
          </Grid>
          <Grid item xs={6} textAlign={'end'}>
            <Button
              startIcon={<Add />}
              variant='contained'
              color="success" onClick={() => {
                setPaymentFormModal(true);
                setSelectedData(null);
              }}>
              Create Fee
            </Button>
          </Grid>
        </Grid>
      </Card>

      <CustomTable
        columns={[
          {
            name: "candidate_name",
            label: formatMessage({ id: "name" }),
          },
          ...expenseColumns(formatMessage), 
          columnAction
        ]}
        tableData={rows}
        tableState={tableState}
        rowsCount={count}
        setTableState={setTableState}
        loading={loading || deleteLoading}
      />

      {viewModal && (
        <ViewDetail
          show={viewModal}
          headerText="Detail"
          detailData={selectedData}
          onHide={() => setViewModal(false)}
        />
      )}
      {deleteFormModal && (
        <DeleteForm
          show={deleteFormModal}
          headerText="Delete"
          detailData={selectedData}
          onHide={() => setDeleteFormModal(false)}
        />
      )}
      {paymentFormModal && (
        <ExpenseForm
          refreshData={onApiCall}
          show={paymentFormModal}
          headerText={formatMessage({ id : 'create-candidate-fee'})}
          miscellaneousItemData={selectedData}
          onHide={() => setPaymentFormModal(false)}
          miscellaneousList={miscellaneousList?.rows}
        />
      )}
    </>
  );
};

export default List;
