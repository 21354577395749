import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Button, Container, Grid } from '@mui/material';

import { CustomTextArea } from 'components/forms';
import { CustomDrawer, CustomModal, HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';
import { Update } from '@mui/icons-material';

interface Props {
    open: boolean;
    status: 'requested' | 'rejected' | 'verified' | 'cancelled' | 'enabled' | 'disabled',
    closeModal: () => void;
    headerText: string;
    refreshData: () => void;
    uuid: string
}

const StatusForm = (props: Props) => {
    const { closeModal, open, headerText, status, refreshData, uuid } = props;
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, control, reset } = useForm({ mode: 'all' });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);

    function onSubmit(data: any) {
        setLoading(true);
        data.uuid = uuid;
        data.status = status;
        const message = 'updated-message';
        services.updateAccountStatus(data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: message }, { title: "account" }));
                closeModal();
                refreshData();
            })
            .catch((err: any) => {
                setLoading(false);
                toastMessage(err?.data?.message || "Something wrong !", 'error');
            })
    }

    return (
        <CustomModal
            open={open}
            onClose={closeModal}
            title={headerText}
        >
            <Container maxWidth="xl">
                <Grid container spacing={2} paddingY={3}>
                    {loading && <HoverLoader />}
                    <Grid item xs={12}>
                        <CustomTextArea
                            formLabel={formatMessage({ id: "remarks" }) + "*"}
                            name="remarks"
                            control={control}
                            minRows={6}
                            error={errors?.remarks}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button startIcon={<Update />} variant='contained' color="info" fullWidth onClick={handleSubmit(onSubmit)}>
                            Update
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </CustomModal>
    )
}

export default StatusForm;