import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';

interface Props {
  meta?: any;
  title?: string;
}

function capitalizeSentence(sentence:string) {
  // Split the sentence into an array of words
  let words = sentence?.split(' ');

  // Capitalize the first letter of each word
  for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  // Join the words back into a single sentence
  return words.join(' ');
}

const SeoLayout = forwardRef(({ title = 'Home', meta }: Props) => {
  return <>
    <Helmet>
      <title>{`${capitalizeSentence(title)} | Manpower Management System`}</title>
      {meta}
    </Helmet>
  </>
});

SeoLayout.defaultProps = {
  title: '',
}

export default SeoLayout;
