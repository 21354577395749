
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from "react-intl";
import { RemoveRedEye, Edit, Delete, LinkOff, Ballot } from '@mui/icons-material';

import CustomTable from 'components/table';
import { ActionMenu, ConfirmationButton, CustomButton } from 'components';
import { masterPaginationServices, tableInitialState, } from 'utils/constants';
import { getTableParams } from 'utils/global';
import { RootReducerState } from 'utils/types';
import { getMasterPaginationData } from 'redux/actions';
import PageLayout from 'layouts/PageLayout';
import { companyColumns } from 'utils/constants/tableColumn/column';
import { services } from 'redux/services';
import { useCheckPermissions, useCheckRole, useToastMessage } from 'hooks';
import View from './View';

const permissionSet = {
    companySave: ['companies.createCompany'],
    companyUpdate: ['companies.updateCompany'],
    companyGet: ['companies.getCompany'],
    companyDelete: ['companies.deleteCompany'],
    viewCompanyAssociateVacancies: ['companies.viewCompanyAssociateVacancies'],
}

const Company: React.FC = () => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { toastMessage } = useToastMessage();
    const checkPermissions = useCheckPermissions(permissionSet);

    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const [viewModal, setViewModal] = useState(false);
    const [selectedData, setSelectedData] = useState<any>();

    const { userData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            userData: masterPaginationReducer[masterPaginationServices.company].data,
            loading: masterPaginationReducer[masterPaginationServices.company].loading,
        }),
        shallowEqual
    );

    const { rows, count } = userData;

    useEffect(() => {
        onApiCall();
    }, [tableState]);

    function onApiCall(withState: boolean = true) {
        const params = getTableParams(withState ? tableState : {});
        dispatch(getMasterPaginationData(masterPaginationServices.company, params));
    }

    let columnAction = {
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                const rowData = rows[tableMeta.rowIndex];
                return (
                    <ActionMenu>
                        {checkPermissions.companyGet && (
                            <CustomButton
                                color="warning"
                                size="small"
                                label="View"
                                variant="outlined"
                                onClick={() => handleViewClick(rowData)}
                                startIcon={<RemoveRedEye />}
                            />
                        )}

                        {checkPermissions.companyUpdate && (
                            <CustomButton
                                color="secondary"
                                size="small"
                                label="Edit"
                                variant="outlined"
                                onClick={() => handleRowClick(rowData)}
                                startIcon={<Edit />}
                            />
                        )}
                        {checkPermissions.viewCompanyAssociateVacancies && (
                            <CustomButton
                                color="info"
                                size="small"
                                label="Vacancy"
                                variant="outlined"
                                onClick={() => navigate(`/admin/companies/vacancies/${rowData.uuid}`)}
                                startIcon={<Ballot />}
                            />
                        )}

                        {checkPermissions.companyDelete && (
                            <ConfirmationButton
                                color='error'
                                size='small'
                                label="Delete"
                                variant='outlined'
                                onConfirm={() => onDeleteData(rowData)}
                                title={formatMessage({ id: "delete-title" })}
                                subTitle={formatMessage({ id: "delete-confirmation" })}
                                startIcon={<Delete />}
                            />
                        )}
                    </ActionMenu>
                )
            }
        }
    }

    function handleViewClick(rowData: any) {
        setViewModal(true);
        setSelectedData(rowData);
    }

    function handleRowClick(rowData: any) {
        navigate(`${pathname}/update/${rowData?.uuid}`, { ...rowData });
    }

    function onDeleteData(data: any) {
        setDeleteLoading(true);
        services.deleteCompany({ uuid: data.uuid })
            .then((res: any) => {
                onApiCall();
                toastMessage(formatMessage({ id: "deleted-message" }, { title: formatMessage({ id: 'company' }) }));
            })
            .catch((err) => {
                toastMessage(err?.data?.message || formatMessage({ id: "opps-error-message" }), 'error');
            })
            .finally(() => setDeleteLoading(false))
    }

    return (
        <PageLayout title={formatMessage({ id: "company" })} hideToolbar={!checkPermissions.companySave}>
            <CustomTable
                columns={[...companyColumns(formatMessage), columnAction]}
                tableData={rows}
                tableState={tableState}
                rowsCount={count}
                setTableState={setTableState}
                loading={loading || deleteLoading}
            />


            {viewModal && (
                <View
                    open={viewModal}
                    onClose={() => setViewModal(false)}
                    defaultData={selectedData}
                />
            )}
        </PageLayout>
    )
}

export default Company;
