import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import Tesseract from 'tesseract.js';
import * as faceapi from 'face-api.js';

import { Add, ArrowBack, Crop, Download, Refresh, Restore, Save } from '@mui/icons-material';
import { Grid, Stack, Button, Card, CardActions, CardMedia, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Alert, AlertTitle, Typography } from '@mui/material';

import { useCheckRole, useToastMessage } from 'hooks';
import { services } from 'redux/services';
import { getMasterLookupData } from 'redux/actions';
import { FileAcceptData, candidateStatusEnum, fileValidate, genderList, masterLookupServices } from 'utils/constants';
import { convertToDate, dataUrlToFile, extractFullName, fetchCompletionsApi, validationRule } from 'utils/global';

import { CustomDatePicker, CustomFileUpload, CustomSelect, CustomTextBox } from 'components/forms';
import { CustomDeleteButton } from 'components';
import { HoverLoader } from 'components';
import OpenAIStepper from 'components/OpenAIStepper';
import CardLayout from 'layouts/CardLayout';
import SamplePhoto from 'assets/images/sample.jpeg';
import UserSamplePhoto from 'assets/images/user-icon.jpeg';

interface OpenApiResponse {
    nationalityCode: string;
    passportNo: string;
    countryCode: string;
    givenName: string;
    surname: string;
    sex: string;
    dateOfBirth: string;
    personalNumber: string;
    dateOfIssue: string;
    dateOfExpiry: string;
    birthPlace: string;
    mrzLine: string;
}
const initialParsedData = {
    nationalityCode: '-',
    passportNo: '-',
    countryCode: '-',
    givenName: '-',
    surname: '-',
    sex: '-',
    dateOfBirth: '-',
    personalNumber: '-',
    dateOfIssue: '-',
    dateOfExpiry: '-',
    birthPlace: '-',
    mrzLine: '-',
}
const GeneralForm = ({ uuid, formType, selectOptions, defaultData, onRefresh }: any) => {
    const dispatch = useDispatch();
    const { toastMessage } = useToastMessage();
    const { isReceptionist } = useCheckRole();
    const navigate = useNavigate();
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm({ mode: 'all' });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState<boolean>(false);
    const [fileArray, setFileArray] = useState([]);
    const [parseData, setParsedData] = useState<OpenApiResponse>(initialParsedData);
    const [defaultSampleUrl, setDefaultSampleUrl] = useState<any>(SamplePhoto);
    const [defaultUserSampleUrl, setDefaultUserSampleUrl] = useState<any>(UserSamplePhoto);
    const [progressStep, setProgressStep] = useState<number>(0);
    const [refetchInformation, setRefetchInformation] = useState<boolean>(false);
    const [showSubmit, setShowSubmit] = useState<boolean>(false);
    const [validateMessage, setValidateMessage] = useState<any>(null);

    function onSubmit(data: any) {
        if (formType === "create") {
            if (isReceptionist) data.status = candidateStatusEnum.reviewed;
            if (!data?.passport_image || data?.passport_image?.length === 0) {
                toastMessage('Please upload passport front image', 'error');
                return;
            }
        }
        const passport_image = data?.passport_image?.[0];
        delete data?.passport_image;
        if (parseData) {
            data.passport_extracted_info = JSON.stringify(parseData);
        }
        data.photo = data?.photo?.[0];
        data.gender = data?.gender?.value;
        data.dob_ad = dayjs(data?.dob_ad).format('YYYY-MM-DD');
        data.passport_issue_date_ad = dayjs(data?.passport_issue_date_ad).format('YYYY-MM-DD');
        data.passport_expiry_date_ad = dayjs(data?.passport_expiry_date_ad).format('YYYY-MM-DD');
        data.passport_expiry_date_bs = data.passport_expiry_date_ad;
        data.dob_bs = data.dob_ad;
        data.passport_issue_date_bs = data.passport_issue_date_ad;
        data.company_id = data?.company_id?.value;
        data.is_active = data?.is_active ? 1 : 0;
        data.professions = data?.professions?.map(({ label }: any) => label);

        if (formType === 'update') data.uuid = uuid;
        const apiFunc = formType === 'create' ? 'createCandidate' : 'updateCandidate';
        const message = formType === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                if (formType === "create") {
                    onSubmitPassportDocument(res.result.uuid, passport_image)
                } else {
                    if (passport_image) {
                        onSubmitPassportDocument(res.result.uuid, passport_image)
                    }
                    onRefresh();
                    toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'candidate' }) }));
                }
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message, 'error');
            })
    }

    function onValidate(data: any) {
        data.gender = data?.gender?.value;
        data.dob_ad = dayjs(data?.dob_ad).format('YYYY-MM-DD');
        data.passport_issue_date_ad = dayjs(data?.passport_issue_date_ad).format('YYYY-MM-DD');
        data.passport_expiry_date_ad = dayjs(data?.passport_expiry_date_ad).format('YYYY-MM-DD');
        data.passport_expiry_date_bs = data.passport_expiry_date_ad;
        data.dob_bs = data.dob_ad;
        data.passport_issue_date_bs = data.passport_issue_date_ad;
        data.company_id = data?.company_id?.value;
        data.is_active = data?.is_active ? 1 : 0;
        data.professions = data?.professions?.map(({ label }: any) => label);

        services.validateCandidate(data)
            .then((res: any) => {
                setShowSubmit(true);
                setValidateMessage(null);
                toastMessage(res?.message || 'Candidate has been validated successfully.');
            })
            .catch((err: any) => {
                if (err?.status === 406) {
                    setValidateMessage(err?.data?.message);
                    window?.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    });
                    toastMessage('Candidate has been already applied from other manpower.', 'error');
                } else {
                    setValidateMessage(null);
                    toastMessage(err?.data?.message, 'error');
                }

            })
    }


    function onSubmitPassportDocument(uuid: string, passport_image: File) {
        const params = {
            type: 'passport',
            file_type: 'image',
            candidate_uuid: uuid,
            files: [passport_image]
        }
        const message = 'save-message';
        services.saveCandidateFile(params)
            .then((res: any) => {
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'candidate' }) }));
                navigate(`/admin/candidates/update/information/${uuid}`);
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || 'Something Wrong', 'error');
            })
    }

    useEffect(() => {
        if (defaultData && selectOptions && Object.keys(selectOptions)?.length > 0) {
            reset({
                id: defaultData.id,
                first_name: defaultData.first_name,
                middle_name: defaultData.middle_name,
                last_name: defaultData.last_name,
                dob_ad: defaultData.dob_ad,
                passport_number: defaultData.passport_number,
                passport_nationality: defaultData.passport_nationality,
                phone: defaultData.phone,
                email: defaultData.email,
                citizenship_number: defaultData.citizenship_number,
                passport_issue_date_ad: defaultData.passport_issue_date_ad,
                passport_expiry_date_ad: defaultData.passport_expiry_date_ad,
                gender: genderList.find((item: any) => item.value == defaultData.gender),
                // agent_id: selectOptions?.agent?.find((item: any) => +item.value === +defaultData.agent_id),
                company_id: selectOptions?.company?.find((item: any) => item.value == defaultData.company_id),
                professions: selectOptions?.profession?.filter((item: any) => defaultData.professions?.includes(item.label)),
                photo: null
            });
            setDefaultUserSampleUrl(defaultData.photo_url)
            setFileArray(defaultData.documents?.filter((x: any) => x.type === 'passport'))
        }
    }, [
        defaultData,
        selectOptions
    ])

    function onDelete(ids: Array<string>) {
        const apiFunc = 'deleteCandidateFile';
        const message = 'deleted-message';
        services[apiFunc]({
            ids,
            candidate_uuid: uuid
        }).then((res: any) => {
            setFileArray([]);
            toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'passport' }) }));
        }).catch((err: any) => {
            toastMessage(err?.data?.message || 'Something Wrong', 'error');
        })
    }

    const performOCR = (imageFile: string) => {
        if (imageFile) {
            setTimeout(() => {
                setProgressStep(2);
            }, 1000);
            Tesseract.recognize(
                imageFile,
                'eng', // language code, you can change it as needed
                // { logger: (info) => console.log(info) }
            ).then(({ data: { text } }) => {
                setProgressStep(3);
                submitPassport(text);
            }).catch(err => {
                setProgressStep(0);
                console.error(err);
            });
        }
    };

    const submitPassport = async (text: string) => {
        const systemPrompt = 'Do not engage in anything other than extracting this given text.';
        const jsonOutputFormat = `{
                "nationalityCode": "",
                "passportNo": "",
                "countryCode": "",
                "givenName": "",
                "surname": "",
                "sex": "",
                "dateOfBirth": "",
                "personalNumber": "",
                "dateOfIssue": "",
                "dateOfExpiry": "",
                "birthPlace": "",
                "mrzLine": ""
        }`;
        const buildUserPrompt = `${text}\n.Output JSON format extracted from the provided text must be: ${jsonOutputFormat}`;

        try {
            await fetchCompletionsApi(
                {
                    messages: [
                        { role: 'system', content: systemPrompt },
                        { role: 'user', content: buildUserPrompt },
                    ],
                },
                (value: any) => {
                    const parsed = JSON.parse(value) as OpenApiResponse;
                    const dob = convertToDate(parsed.dateOfBirth);
                    const name = extractFullName(parsed.givenName);
                    const passportIssueDate = convertToDate(parsed.dateOfIssue) as Date;
                    const passportExpiryDate = convertToDate(parsed.dateOfExpiry) as Date;
                    // const passportIssueDate = subtractYears(passportExpiryDate);
                    setValue('passport_expiry_date_ad', passportExpiryDate);
                    setValue('passport_number', parsed.passportNo);
                    setValue('first_name', name.firstName);
                    setValue('middle_name', name.middleName);
                    setValue('last_name', parsed.surname);
                    setValue('passport_nationality', parsed.countryCode)
                    setValue('passport_issue_date_ad', passportIssueDate);
                    setValue('dob_ad', dob);
                    setValue('gender', genderList.find((item: any) => item.code.toLowerCase() === parsed.sex.toLowerCase()))
                    setValue('citizenship_number', parsed.personalNumber);
                    setParsedData(parsed);
                    setRefetchInformation(true);
                    setProgressStep(4);
                }
            );
        } catch (e) {
            setProgressStep(0);
            toastMessage(e || 'Something Wrong', 'error');
        }
    }

    const detectAndExtractProfilePhoto = async (imageDataUrl: string) => {
        const image = new Image();
        image.src = imageDataUrl;

        const detections = await faceapi.detectSingleFace(
            image,
            new faceapi.SsdMobilenetv1Options({ minConfidence: 0.3, maxResults: 1 })
        ).withFaceLandmarks();

        if (detections) {
            const faceBox = detections.detection.box;
            const additionalHeadHeight = 0.4; // Adjust this value based on your needs
            const additionalHeadWidth = 0.4; // Adjust this value based on your needs
            const topOffset = 0.4;
            // Expand the height of the bounding box to include more of the head
            const expandedFaceBox = {
                x: Math.max(0, faceBox.x - additionalHeadWidth * faceBox.width),
                y: Math.max(0, faceBox.y - topOffset * faceBox.height),
                width: faceBox.width * (1 + 2 * additionalHeadWidth),
                height: faceBox.height * (1 + additionalHeadHeight + topOffset)
            };

            const profilePhotoCanvas = document.createElement('canvas');
            profilePhotoCanvas.width = expandedFaceBox.width;
            profilePhotoCanvas.height = expandedFaceBox.height;
            const profileContext = profilePhotoCanvas.getContext('2d') as CanvasRenderingContext2D;
            profileContext.drawImage(
                image,
                expandedFaceBox.x,
                expandedFaceBox.y,
                expandedFaceBox.width,
                expandedFaceBox.height,
                0,
                0,
                expandedFaceBox.width,
                expandedFaceBox.height
            );

            const base64Image = profilePhotoCanvas.toDataURL('image/png');
            const file = dataUrlToFile(base64Image, `${moment().valueOf()}.png`);

            setDefaultUserSampleUrl(base64Image)
            setValue('photo', [file]);
        } else {
            setProgressStep(0);
            toastMessage('No face detected', 'warning');
        }
    };


    const handleFileChange = async (file: Blob) => {
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
            detectAndExtractProfilePhoto(event.target?.result as string);
        };
        reader.readAsDataURL(file);
    };

    const handleFetch = async () => {
        const files = watch('passport_image');
        if (files?.length > 0) {
            setProgressStep(1);
            await handleFileChange(files?.[0]);
            await performOCR(files?.[0]);
        }
    }

    const handleCropImage = async () => {
        setLoading(true);
        const files = watch('passport_image');
        if (files?.length > 0) {
            await handleFileChange(files?.[0]);
        }
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    const handleReset = () => {
        reset();
        setParsedData(initialParsedData);
        setValue(`passport_image`, null);
        setValue(`photo`, null);
        clearErrors(`photo`);
        clearErrors(`passport_image`);
        setDefaultSampleUrl(SamplePhoto);
        setDefaultUserSampleUrl(UserSamplePhoto)
    }

    return (
        <>
            {validateMessage &&
                <Alert severity="error" variant='standard' sx={{ marginTop: 2 }}>
                    <AlertTitle>Warning</AlertTitle>
                    <Typography variant='body1'>The candidate has already created from {validateMessage?.company} on {moment(validateMessage?.created_at).format('YYYY-MM-DD h:m A')}. Please contact at {validateMessage?.email} or {validateMessage?.phone || '-'}.</Typography>
                </Alert>
            }
            {loading && <HoverLoader text="Croping profile image...." />}
            {progressStep > 0 &&
                <OpenAIStepper activeStep={progressStep} handleClose={() => setProgressStep(0)} />
            }
            <CardLayout title={formatMessage({ id: "passport-information" })}>
                <Grid container spacing={2}>
                    {fileArray.length === 0 ?
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <CustomFileUpload
                                accept={FileAcceptData.IMAGES}
                                formLabel="Passport Image"
                                error={errors?.passport_image}
                                {...register('passport_image', {
                                    validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true,
                                })}
                                onClear={() => {
                                    setValue(`passport_image`, null);
                                    clearErrors(`passport_image`);
                                    setParsedData(initialParsedData);
                                    setDefaultSampleUrl(SamplePhoto);
                                }}
                                onChange={(file: any) => {
                                    setValue('passport_image', file);
                                    setDefaultSampleUrl(null);
                                }}
                                disabled={fileArray.length > 1}
                                hasDefaultSampleImage
                                defaultFileUrl={defaultSampleUrl}
                                warningMessage={defaultSampleUrl ? 'Image must be in the portrait format shown above.' : ''}
                            />
                            <Button
                                color="success"
                                disabled={watch('passport_image')?.length > 0 ? false : true}
                                // size='small'
                                startIcon={refetchInformation ? <Refresh /> : <Download />}
                                variant='outlined'
                                fullWidth sx={{ marginTop: 1 }}
                                onClick={handleFetch}
                            >
                                Fetch passport information using AI Engine
                            </Button>
                        </Grid> : fileArray?.map((item: any, index: number) =>
                            <Grid item lg={4} md={6} sm={12} xs={12} key={index}>
                                <Card sx={{ maxWidth: 500 }}>
                                    <CardMedia
                                        sx={{ height: 300, backgroundSize: 'contain', cursor: 'pointer' }}
                                        onClick={() => window.open(item.file_url, "_blank")}
                                        image={item.file_url}
                                        title="Passport"
                                    />
                                    <CardActions>
                                        <CustomDeleteButton
                                            variant="outlined"
                                            label="Remove passport images and upload new one"
                                            onDelete={() => onDelete(fileArray.map((item: any) => item.id))}
                                        />
                                    </CardActions>
                                </Card>
                            </Grid>
                        )}
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomFileUpload
                            accept={FileAcceptData.IMAGES}
                            formLabel={formatMessage({ id: "Photo" })}
                            error={errors?.photo}
                            {...register('photo', {
                                validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true
                            })}
                            onClear={() => {
                                setValue(`photo`, null);
                                clearErrors(`photo`);
                                setDefaultUserSampleUrl(UserSamplePhoto)
                            }}
                            onChange={(file: any) => {
                                setValue('photo', file);
                                setDefaultUserSampleUrl(null);
                            }}
                            defaultFileUrl={defaultUserSampleUrl}
                            hasDefaultSampleImage
                        // warningMessage={defaultUserSampleUrl ? 'Profile must be in the portrait format shown above.' : ''}
                        />
                        {refetchInformation &&
                            <Button
                                color="success"
                                startIcon={<Crop />}
                                variant='outlined'
                                fullWidth sx={{ marginTop: 1 }}
                                onClick={handleCropImage}
                            >
                                Crop pofile image using AI Photo Tools
                            </Button>
                        }
                    </Grid>
                    {parseData &&
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <TableContainer component={Paper}>
                                <Table size='small'>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Nationality Code</TableCell>
                                            <TableCell>{parseData?.nationalityCode}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Country Code</TableCell>
                                            <TableCell>{parseData?.countryCode}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Passport Number</TableCell>
                                            <TableCell>{parseData?.passportNo}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Given Name</TableCell>
                                            <TableCell>{parseData?.givenName}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Surname</TableCell>
                                            <TableCell>{parseData?.surname}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Sex</TableCell>
                                            <TableCell>{parseData?.sex}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell component="th" scope="row">Date of Birth</TableCell>
                                            <TableCell>{parseData?.dateOfBirth}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Personal Number</TableCell>
                                            <TableCell>{parseData?.personalNumber}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Date of Issue</TableCell>
                                            <TableCell>{parseData?.dateOfIssue}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Date Of Expiry</TableCell>
                                            <TableCell>{parseData?.dateOfExpiry}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Birth Place</TableCell>
                                            <TableCell>{parseData?.birthPlace}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    }
                    {/* <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {fileArray?.length > 0 && fileArray?.map((item: any, index: number) =>
                                <Grid item lg={4} md={6} sm={6} xs={6} key={index}>
                                    <Card sx={{ maxWidth: 500 }}>
                                        <CardMedia
                                            sx={{ height: 300, backgroundSize: 'contain', cursor: 'pointer' }}
                                            onClick={() => window.open(item.file_url, "_blank")}
                                            image={item.file_url}
                                            title="Passport"
                                        />
                                        <CardActions>
                                            <CustomDeleteButton
                                                variant="outlined"
                                                label="Remove passport images and upload new one"
                                                onDelete={() => onDelete(fileArray.map((item: any) => item.id))}
                                            />
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                    </Grid> */}

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "passport-number" })}
                            name="passport_number"
                            control={control}
                            error={errors?.passport_number}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomDatePicker
                            control={control}
                            label={formatMessage({ id: "passport-issue-date" })}
                            name="passport_issue_date_ad"
                            error={errors.passport_issue_date_ad}
                            rules={{ required: true }}
                            maxDate={new Date()}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomDatePicker
                            control={control}
                            label={formatMessage({ id: "passport-expiry-date" })}
                            name="passport_expiry_date_ad"
                            error={errors.passport_expiry_date_ad}
                            rules={{ required: true }}
                            maxDate={new Date()}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "first-name" })}
                            name="first_name"
                            control={control}
                            error={errors?.first_name}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "middle-name" })}
                            name="middle_name"
                            control={control}
                            error={errors?.middle_name}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "last-name" })}
                            name="last_name"
                            control={control}
                            error={errors?.last_name}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "citizenship-number" })}
                            name="citizenship_number"
                            control={control}
                            error={errors?.citizenship_number}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "nationality" })}
                            name="passport_nationality"
                            control={control}
                            error={errors?.passport_nationality}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={genderList}
                            label={formatMessage({ id: "gender" })}
                            name="gender"
                            control={control}
                            error={errors.gender}
                            rules={{ required: true }}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomDatePicker
                            control={control}
                            label={formatMessage({ id: "dob" })}
                            name="dob_ad"
                            error={errors.dob_ad}
                            rules={{
                                required: true,
                                validate: (value: any) => (dayjs().diff(value, 'year') >= 18) || formatMessage({ id: "age-validation-message-18" })
                            }}
                            maxDate={new Date()}
                        />
                    </Grid>
                </Grid>
            </CardLayout>
            <CardLayout title={formatMessage({ id: "other-information" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "phone" })}
                            name="phone"
                            control={control}
                            error={errors?.phone}
                            rules={validationRule.textbox({ type: "contact", maxLength: 20 })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "email" })}
                            name="email"
                            control={control}
                            error={errors?.email}
                            rules={validationRule.textbox({ type: "email" })}
                        />
                    </Grid>
                </Grid>
            </CardLayout>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={2} my={5}>
                        <Button variant="outlined" type='button' color='error' onClick={() => navigate('/admin/candidates/list/all')} startIcon={<ArrowBack />}>
                            Cancel
                        </Button>
                        {formType === "create" &&
                            <Button variant="outlined" type='button' color='warning' onClick={handleReset} startIcon={<Restore />}>
                                Reset
                            </Button>
                        }
                        <Button variant="contained" type='submit' disabled={showSubmit} color="success" onClick={handleSubmit(onValidate)} startIcon={<Save />}>
                            Validate
                        </Button>

                        <Button variant="contained" type='submit' disabled={!showSubmit} color="secondary" onClick={handleSubmit(onSubmit)} startIcon={<Save />}>
                            {formType === 'create' ? 'Submit' : 'Update'}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default GeneralForm;