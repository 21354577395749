import { Grid, Typography } from "@mui/material";
import { CustomModal } from "components";

interface Props {
  open: boolean;
  onClose: () => void;
  selectedData: any;
  headerText: string;
}

const View = (props: Props) => {
  const { onClose, open, selectedData, headerText } = props;
  const renderValue = (name: string, value: string) => {
    return (
      <span >
        <Typography variant="subtitle2" style={{ marginRight: 10 }}>
          {name}
        </Typography>
        <Typography variant="body2">{value || '-'}</Typography>
      </span>
    );
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={headerText}
      size={"xs"}
    >
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12}>
          {renderValue("Flagged", selectedData?.is_flagged === 1 ? 'Yes' : 'No')}
        </Grid>
        <Grid item xs={12}>
          {renderValue("Reason", selectedData?.flagged_reason)}
        </Grid>
        <Grid item xs={12}>
          {renderValue("Flagged By", selectedData?.flagged_by)}
        </Grid>
        {selectedData?.vacancies?.length > 0 &&
          <Grid item xs={12} >
            <Typography variant="subtitle2" style={{ marginRight: 10 }}>
              Company :
            </Typography >
            {selectedData?.vacancies?.map((vac: any, ind: any) =>
              <Typography variant="body2" key={ind} >{vac?.manpower_name}</Typography>
            )}
          </Grid>
        }
      </Grid>
    </CustomModal>
  );
};

export default View;
