import { Fragment, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { CustomTab, HoverLoader } from 'components';
import { Box, Container, IconButton, Stack, Typography } from '@mui/material';
import { masterLookupServices, profileTab } from 'utils/constants';
import SeoLayout from 'layouts/SeoLayout';
import Form from './CreateUpdateForm';
import DocumentForm from './DocumentForm';
import { useToastMessage } from 'hooks';
import { useDispatch } from 'react-redux';
import { services } from 'redux/services';
import { getMasterLookupData } from 'redux/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBack, RequestPageOutlined, Verified } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { ConfirmationIconButton, CustomButton } from 'components';
import StatusForm from './StatusForm';

const AdvanceSearch = () => {
    const { formType, uuid }: any = useParams<any>();
    const { toastMessage } = useToastMessage();
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>(profileTab[0].value);
    const [showVerifyModal, setShowVerifyModal] = useState<any>(false);
    const [showRejectModal, setShowRejectModal] = useState<any>(false);
    const [defaultData, setDefaultData] = useState<any>(null);
    const [fileArray, setFileArray] = useState([]);

    useEffect(() => {
        dispatch(getMasterLookupData(masterLookupServices.getCandidateLookup));
    }, [])

    useEffect(() => {
        if (uuid) {
            setLoading(true);
            services.getAccountByUuid({ uuid: uuid })
                .then((res: any) => {
                    setDefaultData(res.result);
                    setFileArray(res.result?.documents);
                })
                .catch((err: any) => {
                    toastMessage(err?.data?.message || "Something wrong !", 'error');
                })
                .finally(() => setLoading(false))
        }

    }, [formType === 'update', uuid]);

    return (
        <Fragment>
            <SeoLayout title={formType + " Account"} />
            {loading && <HoverLoader />}
            <Container maxWidth="xl" style={{ maxWidth: '100%' }}>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            gap={1}
                            mb={2}
                        >
                            <IconButton color="primary" sx={{ border: 1 }} onClick={() => navigate('/admin/agencies')}>
                                <ArrowBack />
                            </IconButton>
                            <Typography variant="h6" color="primary">
                                {" "}
                                {formatMessage({ id: "update" }) +
                                    " " +
                                    formatMessage({ id: "agency" })}
                            </Typography>
                        </Stack>
                    </Grid>

                    {formType === 'update' &&
                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <CustomTab
                                tabs={profileTab}
                                active={activeTab}
                                onChange={(tab) => { setActiveTab(tab) }}
                            />
                        </Grid>
                    }

                    {defaultData?.is_verified == 0 &&
                        <Grid item lg={6} md={6} sm={12} xs={12} >
                            <Box sx={{ display: "flex", justifyContent: "end" }} gap={2}>
                                <CustomButton
                                    startIcon={<Verified />}
                                    variant='contained'
                                    label='Verify'
                                    color="warning"
                                    onClick={() => setShowVerifyModal(true)}
                                />
                                <CustomButton
                                    startIcon={<Verified />}
                                    variant='contained'
                                    label='Reject'
                                    color="error"
                                    onClick={() => setShowRejectModal(true)}
                                />
                            </Box>

                        </Grid>
                    }

                    <Grid item xs={12}>
                        {activeTab === 'general' && <Form formType={formType} uuid={uuid} defaultData={defaultData} />}
                        {activeTab === 'documents' && <DocumentForm uuid={uuid} fileArray={fileArray}  setFileArray={setFileArray} defaultData={defaultData} />}
                    </Grid>

                    {showVerifyModal && (
                        <StatusForm
                            open={showVerifyModal}
                            closeModal={() => setShowVerifyModal(false)}
                            uuid={defaultData.uuid}
                            refreshData={() => navigate('/admin/agencies')}
                            status='verified'
                            headerText="Verify Account"
                        />
                    )}

                    {showRejectModal && (
                        <StatusForm
                            open={showRejectModal}
                            closeModal={() => setShowRejectModal(false)}
                            uuid={defaultData.uuid}
                            refreshData={() => navigate('/admin/agencies')}
                            status='rejected'
                            headerText="Reject Account"
                        />
                    )}
                </Grid>
            </Container>
        </Fragment>
    )
}

export default AdvanceSearch;