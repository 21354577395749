
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useIntl } from "react-intl";

import CustomTable from 'components/table';
import { ActionMenu, TableViewButton, TableFlagButton, CustomButton, ConfirmationButton } from 'components';
import { masterPaginationServices, tableInitialState, } from 'utils/constants';
import { getTableParams } from 'utils/global';
import { RootReducerState } from 'utils/types';
import { getMasterPaginationData } from 'redux/actions';
import PageLayout from 'layouts/PageLayout';
import { userColumns } from 'utils/constants/tableColumn/column';
import { services } from 'redux/services';
import { useCheckPermissions, useToastMessage } from 'hooks';
import View from './View';
import FlagForm from './FlagForm';
import { Delete, Edit } from '@mui/icons-material';

const permissionSet = {
    createUser: ['accountUsers.createUser'],
    updateUser: ['accountUsers.updateUser'],
    deleteUser: ['accountUsers.deleteUser'],
}

const Agent: React.FC = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { formatMessage } = useIntl();
    const { toastMessage } = useToastMessage();
    const dispatch = useDispatch();
    const checkPermissions = useCheckPermissions(permissionSet);

    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [viewFlagForm, setViewFlagForm] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const { userData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            userData: masterPaginationReducer[masterPaginationServices.user].data,
            loading: masterPaginationReducer[masterPaginationServices.user].loading,
        }),
        shallowEqual
    );

    const { rows, count } = userData;

    useEffect(() => {
        onApiCall();
    }, [tableState]);

    function onApiCall() {
        const params = getTableParams(tableState);
        dispatch(getMasterPaginationData(masterPaginationServices.user, params));
    }

    let columnAction = {
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                const rowData = rows[tableMeta.rowIndex];
                return (
                    <ActionMenu>
                        {checkPermissions.updateUser &&
                            <CustomButton
                                color='secondary'
                                size='small'
                                label="Edit"
                                variant='outlined'
                                onClick={() => handleRowClick(rowData)}
                                startIcon={<Edit />}
                            />
                        }
                        {checkPermissions.deleteUser &&
                            <ConfirmationButton
                                color='error'
                                size='small'
                                label="Delete"
                                variant='outlined'
                                onConfirm={() => onDeleteData(rowData)}
                                title={formatMessage({ id: "delete-title" })}
                                subTitle={formatMessage({ id: "delete-confirmation" })}
                                startIcon={<Delete />}
                            />
                        }
                    </ActionMenu>
                )
            }
        }
    }

    function handleRowClick(rowData: any) {
        navigate(`${pathname}/update/${rowData?.uuid}`, { ...rowData });
    }

    function onDeleteData(data: any) {
        setDeleteLoading(true);
        services.deleteUser({ uuid: data.uuid })
            .then((res: any) => {
                onApiCall();
                toastMessage(formatMessage({ id: "deleted-message" }, { title: formatMessage({ id: 'user' }) }));
            })
            .catch((err) => {
                toastMessage(err?.data?.message || formatMessage({ id: "opps-error-message" }), 'error');
            })
            .finally(() => setDeleteLoading(false))
    }

    return (
        <PageLayout title={formatMessage({ id: "user" })} hideToolbar={!checkPermissions.createUser}>
            <CustomTable
                columns={[...userColumns(formatMessage), columnAction]}
                tableData={rows}
                tableState={tableState}
                rowsCount={count}
                setTableState={setTableState}
                loading={loading || deleteLoading}
            />
            {viewModal &&
                <View
                    open={viewModal}
                    onClose={() => setViewModal(false)}
                    selectedData={selectedData}
                    headerText=''
                />
            }
            {viewFlagForm &&
                <FlagForm
                    refreshData={onApiCall}
                    selectedData={selectedData}
                    open={viewFlagForm}
                    closeModal={() => setViewFlagForm(false)}
                    headerText={'Flagged Reason'}
                />
            }

        </PageLayout>
    )
}

export default Agent;
