import { MASTER_API_ENDPOINTS } from "redux/masterApiEndpoints";
import { AxiosService } from "utils/axios";

interface ParamsState {
  [key: string]: any;
}

export const masterServices = {

  createRole: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_ROLE, body),
  updateRole: (body: ParamsState) => new AxiosService().put(MASTER_API_ENDPOINTS.UPDATE_ROLE, body),
  getAllRole: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_ROLE, body),
  deleteRole: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_ROLE, body),

  createSpokenLanguage: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_SPOKEN_LANGUAGE, body),
  updateSpokenLanguage: (body: ParamsState) => new AxiosService().put(MASTER_API_ENDPOINTS.UPDATE_SPOKEN_LANGUAGE, body),
  getAllSpokenLanguage: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_SPOKEN_LANGUAGE, body),
  deleteSpokenLanguage: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_SPOKEN_LANGUAGE, body),

  createMiscellaneous: (body: ParamsState) => new AxiosService().postForm(MASTER_API_ENDPOINTS.CREATE_MISCELLANEOUS, body),
  updateMiscellaneous: (body: ParamsState) => new AxiosService().put(MASTER_API_ENDPOINTS.UPDATE_MISCELLANEOUS, body),
  getAllMiscellaneous: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_MISCELLANEOUS, body),
  deleteMiscellaneous: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_MISCELLANEOUS, body),

  createPermission: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_PERMISSION, body),
  updatePermission: (body: ParamsState) => new AxiosService().put(MASTER_API_ENDPOINTS.UPDATE_PERMISSION, body),
  getAllPermission: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_PERMISSION, body),
  deletePermission: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_PERMISSION, body),
  autoUpdatePermission: () => new AxiosService().post(MASTER_API_ENDPOINTS.UPDATE_PERMISSION_AUTO, {}),

  createProfession: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_PROFESSION, body),
  updateProfession: (body: ParamsState) => new AxiosService().put(MASTER_API_ENDPOINTS.UPDATE_PROFESSION, body),
  getAllProfession: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_PROFESSION, body),
  deleteProfession: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_PROFESSION, body),

  createSubscriptionFeature: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_SUBSCRIPTION_FEATURE, body),
  updateSubscriptionFeature: (body: ParamsState) => new AxiosService().put(MASTER_API_ENDPOINTS.UPDATE_SUBSCRIPTION_FEATURE, body),
  getAllSubscriptionFeature: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_SUBSCRIPTION_FEATURE, body),
  deleteSubscriptionFeature: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_SUBSCRIPTION_FEATURE, body),

  createSubscriptionPlan: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_SUBSCRIPTION_PLAN, body),
  updateSubscriptionPlan: (body: ParamsState) => new AxiosService().put(MASTER_API_ENDPOINTS.UPDATE_SUBSCRIPTION_PLAN, body),
  getAllSubscriptionPlan: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_SUBSCRIPTION_PLAN, body),
  deleteSubscriptionPlan: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_SUBSCRIPTION_PLAN, body),

  createPaymentMethod: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_PAYMENT_METHOD, body),
  updatePaymentMethod: (body: ParamsState) => new AxiosService().put(MASTER_API_ENDPOINTS.UPDATE_PAYMENT_METHOD, body),
  getAllPaymentMethod: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_PAYMENT_METHOD, body),
  deletePaymentMethod: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_PAYMENT_METHOD, body),

};
