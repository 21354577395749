import React, { CSSProperties } from 'react';
import dayjs from 'dayjs';
import { Controller, ControllerProps, FieldError } from "react-hook-form";

import KeyboardDatePicker, { DatePickerProps } from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import { FormErrorMessage, } from "components/forms";
import { getApiDate } from 'utils/global';

interface Props {
    rules?: ControllerProps["rules"];
    control: ControllerProps["control"];
    error?: FieldError;
    onChangeValue?: (date: any) => void;
    formLabel?: string;
    name: string;
    defaultValue?: Date | null;
    size?: 'small' | 'medium';
    style?: CSSProperties;
}

const CustomDatePicker = React.memo((props: Props & Partial<DatePickerProps>) => {
    const { name, rules, defaultValue, control, error, onChangeValue, formLabel, size = 'medium', style, ...rest } = props;
    const df = "DD/MM/YYYY";

    return (
        <FormControl fullWidth>
            {formLabel && <span style={{ marginBottom: "8px" }}>{formLabel}</span>}

            <Controller
                name={name}
                control={control}
                rules={rules}
                defaultValue={defaultValue ?? null}
                render={({ field }) => {
                    const { ref, value, onChange: _onChange, ...fieldRest } = field;
                    return (
                        <KeyboardDatePicker
                            inputFormat="DD/MM/YYYY"
                            label={rules?.required ? `${props.label} *` : props.label}
                            value={value}
                            onChange={(date, value) => {
                                if (date ? dayjs(date).isValid() : value ? dayjs(value, df, true).isValid() : false) {
                                    _onChange(getApiDate(date?.toDate()));
                                    if (onChangeValue) {
                                        onChangeValue(date);
                                    }
                                }
                            }}
                            renderInput={params => {
                                return (
                                    <TextField
                                        size={size}
                                        variant="outlined"
                                        fullWidth
                                        error={error ? true : false}
                                        {...params}
                                        style={{ ...style }}
                                        onBlur={e => {
                                            let value = e.target.value;
                                            console.log({ value})
                                            if (!dayjs(value, df).isValid()) {
                                                _onChange(null);
                                            } else if (dayjs(value).isBefore(dayjs("01/01/1900")) || dayjs(value).isAfter(dayjs("01/01/2100"))) {
                                                _onChange(null);
                                            }
                                            if (fieldRest?.onBlur) {
                                                fieldRest.onBlur();
                                            }
                                        }}
                                    />
                                )
                            }}
                        />
                    )
                }}
            />

            <FormErrorMessage error={error} />
        </FormControl>
    )
});

const DatePicker = React.memo((props: { formLabel?: string } & Omit<DatePickerProps, 'renderInput'>) => {
    // const DatePicker = React.memo((props: { formLabel?: string } & DatePickerProps) => {
    const { formLabel, ...rest } = props;
    const df = "mm/dd/yyyy";

    return (
        <FormControl fullWidth>
            {formLabel && <span className="text-13 font-medium" style={{ marginBottom: "8px" }}>{formLabel}</span>}

            <KeyboardDatePicker
                {...rest}
                renderInput={params => (
                    <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        label={props.label}
                        {...params}
                        onBlur={e => {
                            let value = e.target.value;
                            if (dayjs(value, df)?.format(df) !== value) {
                                props.onChange(null);
                            }
                        }}
                    />
                )}
            />

        </FormControl>
    )
});


const PrimaryDatePicker = React.memo((props: Omit<DatePickerProps, 'renderInput'>) => {
    return (
        <KeyboardDatePicker
            {...props}
            renderInput={params => (
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="DD-MM-YYYY"
                    label={props.label}
                    {...params}
                />
            )}
        />
    )
})

export { CustomDatePicker, DatePicker, PrimaryDatePicker }