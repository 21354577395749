import { Chip } from "@mui/material";
import { avatarOptions, ChipColumnOptions, dateOptions, FlagColumnOptions, genderOptions, labelOption, priceOption, BooleanIconOption, dateTimeOptions } from ".";
import { candidateStatusOptions,accountStatusOptions, statusOptions } from "..";

export const masterColumns = (formatMessage: any) => [
  {
    name: "code",
    label: formatMessage({ id: "code" }),
  },
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "is_active",
    label: formatMessage({ id: "is-active" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const roleColumns = (formatMessage: any) => [
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  // {
  //   name: "guard_name",
  //   label: formatMessage({ id: "guard-name" }),
  // },
];

export const accountColumns = (formatMessage: any) => [
  {
    name: "logo_path",
    label: formatMessage({ id: "logo" }),
    ...avatarOptions,
    disableSort: true,
    disableFilter: true,
    hideGlobalSearchFilter: true,
  },
  {
    name: "is_verified",
    label: formatMessage({ id: "verified" }),
    ...BooleanIconOption,
    disableSort: true,
    disableFilter: true,
    hideGlobalSearchFilter: true,
  },
  {
    name: "name",
    label: formatMessage({ id: "name" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "login_email",
    label: formatMessage({ id: "login-email" }),
    disableSort: true,
    disableFilter: true,
    primaryColumnName:'email',
    secondaryColumnName:'email',
  },
  {
    name: "status",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(accountStatusOptions),
    disableSort: true,
    disableFilter: true,
    hideGlobalSearchFilter: true,
  },
  {
    name: "phone",
    label: formatMessage({ id: "phone" }),
    disableSort: true,
    disableFilter: true,
  },
];

export const companyColumns = (formatMessage: any) => [
  {
    name: "logo_path",
    label: formatMessage({ id: "logo" }),
    ...avatarOptions,
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "is_flagged",
    label: formatMessage({ id: "blacklist" }),
    ...FlagColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "name",
    label: formatMessage({ id: "name" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "email",
    label: formatMessage({ id: "email" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "country",
    label: formatMessage({ id: "country" }),
    primaryColumnName: 'address_line1',
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "is_active",
    label: formatMessage({ id: "status" }),
    ...BooleanIconOption
  },
];

export const candidateColumns = (formatMessage: any) => [
  {
    name: "photo_url",
    label: formatMessage({ id: "photo" }),
    ...avatarOptions,
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "passport_number",
    label: formatMessage({ id: "passport-number" }),
    ...labelOption(),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "status",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(candidateStatusOptions),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "full_name_search",
    label: formatMessage({ id: "name" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "dob_ad",
    label: formatMessage({ id: "dob" }),
    primaryColumnName: "dob_ad",
    ...dateOptions,
    disableSort: true,
    disableFilter: true,
  },
];

export const userColumns = (formatMessage: any) => [
  {
    name: "email",
    label: formatMessage({ id: "email" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "full_name",
    label: formatMessage({ id: "name" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "phone",
    label: formatMessage({ id: "phone" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "role",
    label: formatMessage({ id: "role" }),
    disableSort: true,
    disableFilter: true,
    options: {
      customBodyRender: (value: any) => {
        return value?.map((item: any, index: number) => (
          <Chip key={index} label={item} sx={{ marginLeft: 1 }} size="small" color="info" variant="outlined" />
        ));
      },
    },
  },
  {
    name: "is_active",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const candidateSearchColumns = (formatMessage: any) => [
  {
    name: "citizenship_number",
    label: formatMessage({ id: "citizenship-number" }),
    ...labelOption(),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "passport_number",
    label: formatMessage({ id: "passport-number" }),
    ...labelOption(),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "full_name_search",
    label: formatMessage({ id: "name" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "is_flagged",
    label: formatMessage({ id: "is-flagged" }),
    ...FlagColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const companySearchColumns = (formatMessage: any) => [
  {
    name: "name",
    label: formatMessage({ id: "name" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "is_flagged",
    label: formatMessage({ id: "is-flagged" }),
    ...FlagColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];


export const candidateStatusColumns = (formatMessage: any) => [
  {
    name: "created_at",
    label: formatMessage({ id: "date" }),
    ...dateTimeOptions,
  },
  {
    name: "status",
    label: formatMessage({ id: "status" }),
  },
  {
    name: "created_by",
    label: formatMessage({ id: "created-by" }),
    // options: {
    //   customBodyRender: (value: any) => `(by) ${value?.full_name}`,
    // },
  },
  {
    name: "remarks",
    label: formatMessage({ id: "remarks" }),
    innerWidth: 500
  },

];

export const expenseColumns = (formatMessage: any) => [

  {
    name: "expense_item_detail",
    label: formatMessage({ id: "items" }),
    options: {
      customBodyRender: (value: any) => {
        const items = value?.map(({ title }: any) => title);
        return items?.map((item: any, index: number) => (
          <Chip key={index} label={item} sx={{ marginLeft: 1 }} size="small" color="primary" />
        ));
      },
    },
  },
  {
    name: "total_amount",
    label: formatMessage({ id: "total-amount" }),
    ...priceOption
  },
  {
    label: formatMessage({ id: "paid-amount" }),
    name: "paid_amount",
    ...priceOption
  },
  {
    name: "created_at",
    label: formatMessage({ id: "date" }),
    primaryColumnName: "dob_ad",
    ...dateOptions,
  }
];

export const expenseDetailsColumns = (formatMessage: any) => [
  // {
  //   name: "code",
  //   label: formatMessage({ id: "code" }),
  // },
  {
    name: "title",
    label: formatMessage({ id: "title" }),
  },
  {
    name: "qty",
    label: formatMessage({ id: "qty" }),
  },
  {
    name: "rate",
    label: formatMessage({ id: "rate" }),
  },
  {
    label: formatMessage({ id: "total-amount" }),
    name: "total_amount",
    ...priceOption
  },
  {
    label: formatMessage({ id: "paid-amount" }),
    name: "paid_amount",
    ...priceOption
  },
  {
    label: formatMessage({ id: "due-amount" }),
    name: "due_amount",
    ...priceOption
  },
];

export const subscriptionColumns = (formatMessage: any) => [
  {
    name: "account",
    label: formatMessage({ id: "account" }),
  },
  {
    name: "subscription_plan",
    label: formatMessage({ id: "subscription-plan" }),
  },
  {
    name: "start_date",
    label: formatMessage({ id: "start-date" }),
    ...dateTimeOptions,
  },
  {
    name: "end_date",
    label: formatMessage({ id: "end-date" }),
    ...dateTimeOptions,
  },
  {
    name: "expires_in_days",
    label: formatMessage({ id: "expires_in" }),
    options: {
      customBodyRender: (value: any) => `${value || 0} days`,
    },
  },
];

export const visaColumns = (formatMessage: any) => [
  // {
  //   name: "visa_number",
  //   label: formatMessage({ id: "visa-number" }),
  // },
  {
    name: "passport_number",
    label: formatMessage({ id: "passport-number" }),
    ...labelOption(),
  },
  {
    name: "full_name",
    label: formatMessage({ id: "full-name" }),
  },
  {
    name: "company",
    label: formatMessage({ id: "company" }),
  },
  {
    name: "has_departure_confirm",
    label: formatMessage({ id: "departure" }),
    ...BooleanIconOption
  },
  {
    name: "has_labour_clearance",
    label: formatMessage({ id: "clearance" }),
    ...BooleanIconOption
  },
  {
    name: "has_ticket_confirm",
    label: formatMessage({ id: "ticket" }),
    ...BooleanIconOption
  },
  {
    name: "date_of_issue",
    label: formatMessage({ id: "visa-issue-date" }),
    ...dateOptions,
  },
  {
    name: "profession",
    label: formatMessage({ id: "profession" }),
  },
  {
    name: "is_verified",
    label: formatMessage({ id: "verified" }),
    ...BooleanIconOption
  },
];


export const subscriptionPlanColumns = (formatMessage: any) => [
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "plan_period_type",
    label: formatMessage({ id: "plan-period" }),
  },
  {
    name: "amount",
    label: formatMessage({ id: "amount" }),
  },
  {
    name: "saved_amount",
    label: formatMessage({ id: "saved-amount" }),
  },
  {
    name: "total_amount",
    label: formatMessage({ id: "total-amount" }),
  },
  {
    name: "status",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];

export const vacancyColumns = (formatMessage: any) => [
  {
    name: "company",
    label: formatMessage({ id: "company-name" }),
  },
  {
    name: "chalan_number",
    label: formatMessage({ id: "chalan-number" }),
  },
  {
    name: "publish_date",
    label: formatMessage({ id: "publish-date" }),
    ...dateOptions,
  },
  {
    name: "lot_number",
    label: formatMessage({ id: "lot-number" }),
  },
];


export const dashboardVacancySummary = (formatMessage: any) => [
  {
    name: "profession",
    label: formatMessage({ id: "profession" }),
  },
  {
    name: "count",
    label: formatMessage({ id: "total-candidate" }),
  },
  {
    name: "company",
    label: formatMessage({ id: "company" }),
  },
];

export const advanceSearchCandidateColumns = (formatMessage: any) => [
  {
    name: "full_name_search",
    label: formatMessage({ id: "name" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "passport_number",
    label: formatMessage({ id: "passport-number" }),
    ...labelOption(),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "citizenship_number",
    label: formatMessage({ id: "citizenship-number" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "dob_ad",
    label: formatMessage({ id: "dob" }),
    primaryColumnName: "dob_ad",
    ...dateOptions,
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "phone",
    label: formatMessage({ id: "phone" }),
    primaryColumnName: "phone",
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "gender",
    label: formatMessage({ id: "gender" }),
    primaryColumnName: "gender",
    disableSort: true,
    disableFilter: true,
  },
];

export const advanceSearchCandidateVisaColumns = (formatMessage: any) => [
  {
    name: "full_name",
    label: formatMessage({ id: "name" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "passport_number",
    label: formatMessage({ id: "passport-number" }),
    ...labelOption(),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "visa_number",
    label: formatMessage({ id: "visa-number" }),
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "dob_ad",
    label: formatMessage({ id: "dob" }),
    ...dateOptions,
    disableSort: true,
    disableFilter: true,
  },
  {
    name: "application_number",
    label: formatMessage({ id: "application-number" }),
    disableSort: true,
    disableFilter: true,
  },
];

export const subscriptionFeatureColumns = (formatMessage: any) => [
  {
    name: "code",
    label: formatMessage({ id: "code" }),
  },
  {
    name: "name",
    label: formatMessage({ id: "name" }),
  },
  {
    name: "status",
    label: formatMessage({ id: "status" }),
    ...ChipColumnOptions(statusOptions),
    disableSort: true,
    disableFilter: true,
  },
];
