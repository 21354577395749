import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CustomDialog, CustomModal, SimpleTable } from "components";
import useResponsive from "hooks/useResponsive";
import { groupBy } from "lodash";
import { services } from "redux/services";
import { useToastMessage, useCheckRole, useCheckPermissions } from "hooks";
import {
  AppBar,
  Card,
  CardMedia,
  Chip,
  Drawer,
  Grid,
  IconButton,
  Popover,
  TextareaAutosize,
} from "@mui/material";
import {
  ArrowLeft,
  ArrowRight,
  Cancel,
  Check,
  Close,
  Save,
} from "@mui/icons-material";
import { useIntl } from "react-intl";
import { candidateStatusColumns } from "utils/constants/tableColumn/column";
import parser from "react-html-parser"
import { getCandiateStatus } from "utils/global";
import { candidateStatusEnum } from "utils/constants";

const steps = [
  "General",
  'Extra Information',
  "Document",
  // "Experience",
  // "Medical",
];

interface Props {
  open: boolean;
  onClose: () => void;
  uuid: string;
  refreshData: any;
}

const candidateData = [
  {
    label: "Passport Number",
    value: "passport_number",
  },
  {
    label: "Passport Issue Date",
    value: "passport_issue_date_ad",
  },
  {
    label: "Passport Expiry Date",
    value: "passport_expiry_date_ad",
  },
  {
    label: "Full Name",
    value: "full_name_search",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Phone",
    value: "phone",
  },
  {
    label: "DOB",
    value: "dob_ad",
  },
  {
    label: "Gender",
    value: "gender",
  },
  {
    label: "Citizenship Number",
    value: "citizenship_number",
  },
  {
    label: "Citizenship Issue Date",
    value: "citizenship_issue_date_ad",
  },
  {
    label: "Province",
    value: "citizenship_province",
  },
  {
    label: "District",
    value: "citizenship_district",
  },
  {
    label: "Municipality",
    value: "citizenship_municipality",
  },
  {
    label: "Tole",
    value: "Tole",
  },
  {
    label: "Ward",
    value: "ward",
  },
  {
    label: "Status",
    value: "status",
  },
  {
    label: "Company",
    value: "company",
  },
];

const permissionSet = {
  verifyDocumentReviewRequest: ['candidates.verifyDocumentReviewRequest'],
  verifyDocumentProcessRequest: ['candidates.verifyDocumentProcessRequest'],
}

export default function VerifyDocument({
  onClose,
  open,
  uuid,
  refreshData,
}: Props) {
  const { isReceptionist, isAdmin, isDocumentation } = useCheckRole();
  const checkPermissions = useCheckPermissions(permissionSet);

  const { formatMessage } = useIntl();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const [loading, setLoading] = React.useState(false);
  const { toastMessage } = useToastMessage();
  const [defaultValue, setDefaultValue] = React.useState<any>();
  const { isReviewing, isRequested } = getCandiateStatus(defaultValue?.status)
  const [fileArray, setFileArray] = React.useState<any>();
  const [confirmationModal, setConfirmationModal] =
    React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [remarks, setRemarks] = React.useState("");
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopOver = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    if (completedSteps() === totalSteps()) {
      setConfirmationModal(true);
      // handleStatus({ uuid: defaultValue.uuid, status: 'approved' });
    } else {
      handleNext();
    }
  };

  React.useEffect(() => {
    if (uuid) {
      setLoading(true);
      services
        .getCandidateByUuid({ uuid })
        .then((res: any) => {
          setLoading(false);
          const response = res.result;
          setDefaultValue(response);
          setFileArray(groupBy(response?.documents, "type"));
        })
        .catch((err: any) => {
          setLoading(false);
          toastMessage(
            err?.data?.message || "Something Wrong",
            "error"
          );
        });
    }
  }, [uuid]);

  const renderValue = (name: string, value: string) => {
    return (
      <span>
        <Typography variant="subtitle2" style={{ marginRight: 10 }}>
          {name}
        </Typography>
        <Typography variant="body2">{value}</Typography>
      </span>
    );
  };

  function handleStatus(params: any) {
    services
      .updateCandidateStatus(params)
      .then((res: any) => {
        refreshData();
        handleClose();
        onClose();
        toastMessage("Candidate status updated");
      })
      .catch((err: any) => {
        toastMessage(
          err?.data?.message || "Something Wrong",
          "error"
        );
      });
  }

  const renderDocument = (type: string) => {
    return (
      <Grid container spacing={2} width={"100%"} marginBottom={3}>
        <Grid item xs={12}>
          <Typography
            variant="h5" textTransform="capitalize" marginBottom={2}
          >
            {formatMessage({ id: type }) + " " + formatMessage({ id: 'document' })}
          </Typography>
        </Grid>
        {fileArray && Object.keys(fileArray)?.length > 0 && fileArray?.[type] ? (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {fileArray?.[type]?.map((item: any, index: number) => (
                <Grid item lg={4} md={6} sm={6} xs={6} key={index}>
                  <Card sx={{ maxWidth: 500 }}>
                    <CardMedia
                      sx={{
                        height: 300,
                        backgroundSize: "contain",
                        cursor: "pointer",
                      }}
                      onClick={() => window.open(item.file_url, "_blank")}
                      image={item.file_url}
                      title={type}
                    />
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} display="flex" justifyContent="center">
            <Typography variant="subtitle1" color="gray">
              {formatMessage({ id: "no-document-found" })}
            </Typography>
          </Grid>
        )}

      </Grid>
    );
  };

  const renderPassport = (type: string) => {
    return (
      <Grid container spacing={2} width={"100%"}>
        {fileArray &&
          Object.keys(fileArray)?.length > 0 &&
          Object.keys(fileArray)?.map(
            (ind: any) =>
              ind === type && (
                <Grid item xs={12} key={ind}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2"
                        sx={{ textTransform: "uppercase" }}
                      >
                        Passport Image
                      </Typography>
                    </Grid>
                    {fileArray?.[ind]?.map((item: any, index: number) => (
                      <Grid item lg={12} key={index}>
                        <Card sx={{ maxWidth: 500 }}>
                          <CardMedia
                            sx={{
                              height: 300,
                              backgroundSize: "contain",
                              cursor: "pointer",
                            }}
                            onClick={() => window.open(item.file_url, "_blank")}
                            image={item.file_url}
                            title={type}
                          />
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )
          )}
      </Grid>
    );
  };

  const renderDetails = (header: string, information: string) => {
    return <><Typography color="secondary" variant="h5" sx={{ textDecoration: 'underline', textUnderlineOffset: 9 }} textTransform="capitalize" marginBottom={1}> {header}</Typography>
      {information ? parser(information) : <Typography variant="body2" color="gray">
        {formatMessage({ id: "no-information" })}
      </Typography>}</>
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      transitionDuration={600}
      PaperProps={{ elevation: 9 }}
      SlideProps={{ unmountOnExit: true }}
      hideBackdrop={false}
      anchor="bottom"
    >
      <Box sx={{ width: "100%", minHeight: "calc(100vh - 0px)", padding: 1 }}>
        <AppBar position="sticky" color="default" sx={{ padding: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <Stepper nonLinear activeStep={activeStep} sx={{ padding: 1 }}>
                {steps.map((label, index) => (
                  <Step
                    key={label}
                    completed={completed[index]}
                  //  sx={{
                  //   '& .MuiStepLabel-root .Mui-completed': {
                  //     color: 'info.dark', // circle color (COMPLETED)
                  //   },
                  //   '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                  //   {
                  //     color: 'grey.500', // Just text label (COMPLETED)
                  //   },
                  //   '& .MuiStepLabel-root .Mui-active': {
                  //     color: 'info.light', // circle color (ACTIVE)
                  //   },
                  //   '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                  //   {
                  //     color: 'common.white', // Just text label (ACTIVE)
                  //   },
                  //   '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                  //     fill: 'black', // circle's number (ACTIVE)
                  //   },
                  // }}
                  >
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <div>
                  <Button
                    variant="contained"
                    // color="info"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    startIcon={<ArrowLeft />}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleNext}
                    sx={{ mr: 1 }}
                    // color="info"
                    variant="contained"
                    endIcon={<ArrowRight />}
                  >
                    Next
                  </Button>
                </div>
                <div>
                  {(checkPermissions.verifyDocumentReviewRequest || checkPermissions.verifyDocumentProcessRequest) &&
                    (isReviewing || isRequested) && (
                      <>
                        <Button
                          color="error"
                          sx={{ mr: 1 }}
                          variant="outlined"
                          startIcon={<Cancel />}
                          aria-describedby={id}
                          onClick={handleClick}
                        >
                          Reject
                        </Button>
                        <Button
                          color="secondary"
                          variant="contained"
                          startIcon={<Check />}
                          aria-describedby={id}
                          onClick={handleComplete}
                        >
                          {completedSteps() === totalSteps()
                            ? (isReviewing ? "Send for Process" : "Send for Approval")
                            : (isReviewing ? "Review" : "Check")}
                        </Button>
                        {confirmationModal && (
                          <CustomDialog
                            open={true}
                            onDisagree={() => setConfirmationModal(false)}
                            onAgree={() =>
                              handleStatus({
                                uuid: defaultValue.uuid,
                                status: isReviewing ? candidateStatusEnum.reviewed : candidateStatusEnum.approved,
                              })
                            }
                            title="Verify"
                            subTitle="The candidates has been verified. Send the candidate documents to process"
                          />
                        )}
                      </>
                    )}
                  <IconButton
                    // color="warning"
                    aria-label="Close"
                    sx={{ ml: 1, border: 1 }}
                    onClick={onClose}
                    size="small"
                  >
                    <Close />
                  </IconButton>
                </div>
              </Box>
            </Grid>
          </Grid>
        </AppBar>
        <Box sx={{ marginTop: 3, padding: 2 }}>
          {!allStepsCompleted() ? (
            <Box minHeight={300}>
              {activeStep === 0 && (
                <Grid container spacing={2}>
                  <Grid item lg={10} xs={12}>
                    <Grid container spacing={2}>
                      {candidateData?.map(
                        (c, i) =>
                          defaultValue?.[c.value] && (
                            <Grid item lg={4} md={4} sm={12} xs={12} key={i}>
                              {renderValue(c.label, defaultValue?.[c.value])}
                            </Grid>
                          )
                      )}
                      {defaultValue?.vacancy?.company_name &&
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                          {renderValue('Company', defaultValue?.vacancy?.company_name)}
                        </Grid>}
                      {defaultValue?.vacancy?.profession &&
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                          {renderValue('Applied For', defaultValue?.vacancy?.profession)}
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                  <Grid item lg={2} xs={12}>
                    <div>
                      <img
                        src={defaultValue?.photo_url}
                        alt="photo"
                        style={{ height: 180, width: 180 }}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    {renderPassport("passport")}
                  </Grid>
                  <Grid item lg={8} xs={12}>
                    {defaultValue?.status_record?.length > 0 && (
                      <Box>
                        <Typography sx={{ mb: 1 }} variant="h6">
                          Status History
                        </Typography>
                        <SimpleTable
                          columns={candidateStatusColumns(formatMessage)}
                          tableData={
                            defaultValue?.status_record?.length > 0
                              ? defaultValue?.status_record?.sort(
                                (a: { id: number }, b: { id: number }) =>
                                  b.id - a.id
                              )
                              : []
                          }
                          colSpans={[18, 12, 15, 45]}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              )}
              {activeStep === 1 && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {renderDetails(formatMessage({ id: 'professional-summary' }), defaultValue?.information?.profile_summary)}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="secondary" variant="h5" sx={{ textDecoration: 'underline', textUnderlineOffset: 9 }} textTransform="capitalize" marginBottom={2}>{formatMessage({ id: 'skills' }) + " | " + formatMessage({ id: 'professions' })}</Typography>
                    {defaultValue?.information?.professions?.length > 0 ?
                      defaultValue?.information?.professions?.map((p: any, x: any) => <Chip sx={{ marginRight: 1 }} key={x} variant="filled" label={p} />)
                      :
                      <Typography variant="body2" color="gray"> {formatMessage({ id: "no-information" })}</Typography>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    {renderDetails(formatMessage({ id: 'experience-history' }), defaultValue?.information?.experience_info)}
                  </Grid>
                  <Grid item xs={12}>
                    {renderDetails(formatMessage({ id: 'education-history' }), defaultValue?.information?.education_info)}
                  </Grid>
                  <Grid item xs={12}>
                    {renderDetails(formatMessage({ id: 'other-information' }), defaultValue?.information?.extra_info)}
                  </Grid>
                </Grid>
              )}
              {activeStep === 2 && <Box> {renderDocument("document")} {renderDocument("experience")} {renderDocument("medical")}</Box>}
              {/* {activeStep === 3 && renderDocument("experience")}
              {activeStep === 4 && renderDocument("medical")} */}
            </Box>
          ) : (
            <Typography sx={{ mb: 1 }} variant="h6" textAlign="center">
              {isReviewing ? "All informations have been checked. You can now request the document process." : "All informations have been checked. You can now send the documents for final verification."}

            </Typography>
          )}
        </Box>
        <Popover
          id={id}
          open={openPopOver}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 250, left: 700 }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          BackdropProps={{ invisible: false }}
        >
          <Grid container spacing={2} padding={2}>
            <Grid item xs={12}>
              <TextareaAutosize
                onChange={(e) => setRemarks(e.target.value)}
                aria-label="Remarks of cancelation"
                minRows={12}
                placeholder="Remarks"
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                color="error"
                sx={{ mr: 1 }}
                variant="contained"
                startIcon={<Cancel />}
                aria-describedby={id}
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                color="info"
                sx={{ mr: 1 }}
                variant="contained"
                startIcon={<Save />}
                aria-describedby={id}
                onClick={() =>
                  handleStatus({
                    uuid: uuid,
                    status: "cancelled",
                    remarks: remarks,
                  })
                }
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Popover>
      </Box>
    </Drawer>
  );
}
