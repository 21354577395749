import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { CustomCheckBox, CustomDatePicker, CustomFileUpload, CustomSelect, CustomTextArea, CustomTextBox } from 'components/forms';
import { CustomDrawer } from 'components';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';
import currencyList from 'utils/json/currencies.json'
import { Add, Delete } from '@mui/icons-material';
import { FileAcceptData, fileValidate } from 'utils/constants';
import { ProfessionForm } from 'pages';

const detailsInitialState = {
    'profession': '',
    'male': 0,
    'female': 0,
    'qualification': '',
    'currency': {
        "label": "NPR",
        "value": "NPRs",
        "name": "Nepalese Rupee"
    },
    'salary': 0,
}

const Form = ({ open, onClose, formType, defaultData, companyUuid, selectOptions, refreshData }: any) => {
    const { toastMessage } = useToastMessage();
    const { formatMessage } = useIntl();
    const [createProfessionModal, setCreateProfessionModal] = useState<boolean>(false);
    const [appededProfessionData, setAppededProfessionData] = useState<any>([]);

    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm<any>({
        mode: 'all',
        defaultValues: { is_active: 1, details: [detailsInitialState] }
    });
    const [loading, setLoading] = useState(false);
    const [fileImage, setFileImage] = useState({
        file: '',
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: "details",
    });


    function onSubmit(data: any) {
        setLoading(true);
        if(companyUuid){
            data.company_uuid = companyUuid;
        }else{
            data.company_uuid = data.company_id.uuid;
        }
        data.file = data?.file?.[0];
        data.has_lodging = data.has_lodging ? 1 : 0;
        data.has_fooding = data.has_fooding ? 1 : 0;
        data.details = data.details?.map((x: any) => ({ ...x, profession: x.profession.label, currency: x.currency.value }))
        if (formType === 'update') {
            data.id = defaultData?.id;
        }
        const apiFunc = formType === 'create' ? 'createVacancy' : 'updateVacancy';
        const message = formType === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                reset();
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'vacancy' }) }));
                onClose();
                refreshData();
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || 'Something Wrong', 'error');
            }).finally(() => setLoading(false))
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (defaultData) {
            setLoading(true);
            reset({
                title: defaultData.title,
                lot_number: defaultData.lot_number,
                publish_date: defaultData.publish_date,
                chalan_number: defaultData.chalan_number,
                over_time: defaultData.over_time,
                working_hours: defaultData.working_hours,
                working_days: defaultData.working_days,
                contract_period: defaultData.contract_period,
                holidays: defaultData.holidays,
                has_lodging: defaultData.has_lodging === 1 ? true : false,
                has_fooding: defaultData.has_fooding === 1 ? true : false,
                closing_date: defaultData.closing_date,
                description: defaultData.description,
                company_id: selectOptions?.company?.find((item: any) => defaultData.company_id == item.value),
                details: defaultData.details?.map((x: any) => ({
                    ...x,
                    profession: selectOptions?.profession?.find((item: any) => x.profession == item.label),
                    currency: currencyList?.find((item: any) => x.currency == item.value),
                })),
            })
            setFileImage({ file: defaultData?.file })
        }

    }, [formType === 'update', defaultData]);

    return (
        <CustomDrawer
            title="Vacancy Form"
            open={open}
            size={'xl'}
            anchor="bottom"
            onCancel={onClose}
            formMode={'create'}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Grid container spacing={2}>
                {!companyUuid &&
                    <Grid item lg={3} md={6} sm={12} xs={12}>
                        <CustomSelect
                            label={formatMessage({ id: "company" })}
                            options={selectOptions?.company}
                            name={`company_id`}
                            control={control}
                            error={errors?.company_id}
                            rules={{ required: true }}
                            disabled={formType === 'update'}
                        />
                    </Grid>
                }
                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "title" })}
                        name="title"
                        control={control}
                    />
                </Grid>
                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <CustomDatePicker
                        control={control}
                        label={formatMessage({ id: "publish-date" })}
                        name="publish_date"
                        error={errors.publish_date}
                        rules={{ required: true }}
                        maxDate={new Date()}
                    />
                </Grid>

                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "chalan-number" })}
                        name="chalan_number"
                        control={control}
                        error={errors.chalan_number}
                        rules={{ required: true }}
                    />
                </Grid>

                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "lot-number" })}
                        name="lot_number"
                        control={control}
                    />
                </Grid>

                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "over-time" })}
                        name="over_time"
                        control={control}
                        error={errors?.over_time}
                    />
                </Grid>
                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "working-hours" })}
                        name="working_hours"
                        control={control}
                    />
                </Grid>
                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "working-days" })}
                        name="working_days"
                        control={control}
                    />
                </Grid>
                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "contract-period" })}
                        name="contract_period"
                        control={control}
                    />
                </Grid>
                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "holidays" })}
                        name="holidays"
                        control={control}
                    />
                </Grid>
                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <CustomDatePicker
                        control={control}
                        label={formatMessage({ id: "closing-date" })}
                        name="closing_date"
                    />
                </Grid>
                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <CustomCheckBox
                        name="has_lodging"
                        label={formatMessage({ id: "has-lodging" })}
                        control={control}
                    />
                </Grid>
                <Grid item lg={3} md={6} sm={12} xs={12}>
                    <CustomCheckBox
                        name="has_fooding"
                        label={formatMessage({ id: "has-fooding" })}
                        control={control}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextArea
                        formLabel={formatMessage({ id: "detail" })}
                        name="description"
                        control={control}
                        minRows={6}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper} elevation={3}>
                        <Table size='small'>
                            <TableHead>
                                <TableRow sx={{ bgcolor: 'lightgray' }}>
                                    <TableCell align="center" colSpan={1} />
                                    <TableCell align="center" colSpan={2}>
                                        Number of position
                                    </TableCell>
                                    <TableCell align="center" colSpan={2}>
                                        Monthly Salary
                                    </TableCell>
                                    <TableCell align="center" colSpan={1} />
                                </TableRow>
                                <TableRow>
                                    <TableCell width="30%">
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
                                            <Typography fontWeight={'bold'}>Profession</Typography>
                                            <Button startIcon={<Add />} color='success' size='small' variant='contained' onClick={() => setCreateProfessionModal(true)}>Create </Button>
                                        </Box>
                                    </TableCell>
                                    <TableCell width="10%" align="justify">Male</TableCell>
                                    <TableCell width="10%" align="justify">Female</TableCell>
                                    <TableCell width="10%" align="justify">Currency</TableCell>
                                    <TableCell width="15%" align="justify">Salary</TableCell>
                                    <TableCell width="10%" align="justify">
                                        <Button
                                            startIcon={<Add />}
                                            variant='contained'
                                            size='small'
                                            fullWidth
                                            onClick={() => append(detailsInitialState)}
                                        >
                                            Add Vacany
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fields.map((field, index) => (
                                    <TableRow key={field.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>
                                            <CustomSelect
                                                options={[...appededProfessionData, ...(selectOptions?.profession?.length > 0 ? selectOptions?.profession : [])]}
                                                name={`details.${index}.profession`}
                                                control={control}
                                                error={errors?.details?.[index]?.profession}
                                                rules={{ required: true }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <CustomTextBox
                                                type='number'
                                                name={`details.${index}.male`}
                                                control={control}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <CustomTextBox
                                                type='number'
                                                name={`details.${index}.female`}
                                                control={control}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <CustomSelect
                                                options={currencyList?.map(x => ({ ...x, label: `${x.label} (${x.value})` }))}
                                                name={`details.${index}.currency`}
                                                control={control}
                                            />

                                        </TableCell>
                                        <TableCell>
                                            <CustomTextBox
                                                name={`details.${index}.salary`}
                                                control={control}
                                            />
                                        </TableCell>
                                        {fields?.length > 1 &&
                                            <TableCell>
                                                <IconButton onClick={() => remove(index)}>
                                                    <Delete color='error' />
                                                </IconButton>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomFileUpload
                        accept={FileAcceptData.IMAGES}
                        label={formatMessage({ id: "vacancy-image" })}
                        error={errors?.logo}
                        {...register('file', {
                            validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true,
                        })}
                        defaultFileUrl={fileImage.file}
                        onClear={() => {
                            setValue(`file`, null);
                            clearErrors(`file`);
                        }}
                        onChange={(file: any) => setValue('file', file)}
                    />
                </Grid>
                {createProfessionModal && <ProfessionForm
                    open={createProfessionModal}
                    closeModal={() => setCreateProfessionModal(false)}
                    refreshData={(data: { [index: string]: string; }) => data ? setAppededProfessionData([data, ...appededProfessionData]) : []}
                    formMode={'create'}
                    selectedData={null}
                    headerText={formatMessage({ id: "create-profession" })}
                />
                }
            </Grid>
        </CustomDrawer>
    )
}

export default Form;