import Grid from '@mui/material/Grid';
import { CustomTab } from 'components';
import Candidate from './candidate';
import Visa from './visa';
import { Container } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { reportTab } from 'utils/constants';

interface Props {
}
const AdvanceSearch = (props: Props) => {
    const { type } = useParams();
    const navigate = useNavigate();
    return (
        <Container maxWidth="xl" style={{ maxWidth: '100%' }}>
            {type &&
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <CustomTab
                            tabs={reportTab}
                            onChange={(tab) => {
                                navigate(`/admin/report/${tab}`);
                            }}
                            active={type}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {type === 'candidate' && <Candidate />}
                        {type === 'visa' && <Visa />}
                    </Grid>
                </Grid>
            }
        </Container>
    )
}

export default AdvanceSearch;