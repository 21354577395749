import {  useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import Image404 from 'assets/images/404.jpg';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

export default function AccessDenied() {
  const navigate = useNavigate();
  return (
    <Container>
      <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Typography variant="h3" paragraph>
          Access denied
        </Typography>

        <Typography sx={{ color: 'text.secondary' }}>
          Sorry, we couldn’t access this page.
        </Typography>

        <Box
          component="img"
          src={Image404}
          sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
        />

        <Button size="large" variant="contained" onClick={()=>navigate('/admin/dashboard')}>
          Go Back Home
        </Button>
      </ContentStyle>
    </Container>
  );
}
