import { useEffect, useRef, useState } from "react";
// @ts-ignore
import styled from "@emotion/styled";
import { FormErrorMessage } from "./FormErrorMessage";
import { useToastMessage } from "hooks";
import { Editor } from '@tinymce/tinymce-react';
import { Typography } from "@mui/material";

const EditorContainerStyle = styled("div")(({ theme }: any) => ({
    overflow: "hidden",
    borderBottom: `1px solid ${theme.palette.action.hover}`,
}));

interface Props {
    onChange: (value: string) => void;
    defaultValue?: string;
    error?: string;
    formLabel?: string;
    height?: number;
    name: string;
    placeholder?: string;
}
const CustomTextEditor = ({
    name,
    defaultValue,
    error,
    formLabel,
    onChange
}: Props) => {
    const reactQuillRef: any = useRef<HTMLInputElement>(null);
    const [state, setState] = useState("");
    const { toastMessage } = useToastMessage();

    useEffect(() => {
        if (defaultValue) setState(defaultValue);
    }, [defaultValue]);

    const handleEditorChange = (content: string) => {
        setState(content);
        onChange(content);
    };

    return (
        <EditorContainerStyle>
            {formLabel && (
                <Typography variant="body1" marginBottom={1}>{formLabel}</Typography>
            )}
            <Editor
                id={name}
                apiKey='f8vpa350cvow0nl69v14f90g5zcl69s8d3zuhoyso2ss5smw'
                onInit={(evt, editor) => reactQuillRef.current = editor}
                value={state ?? ""}
                init={{
                    height: 500,
                    menubar: false,
                    toolbar_mode: 'wrap',
                    plugins: 'anchor autolink charmap emoticons image link lists media searchreplace table visualblocks wordcount fullscreen',
                    toolbar: 'undo redo | fullscreen blocks fontfamily fontsize | bold italic underline strikethrough | align lineheight | numlist bullist indent outdent',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    // plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount fullscreen',
                    // toolbar: 'undo redo | fullscreen blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat codesample code',
                    // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    // codesample_languages: [
                    //     { text: 'HTML/XML', value: 'markup' },
                    //     { text: 'JavaScript', value: 'javascript' },
                    //     { text: 'CSS', value: 'css' },
                    //     { text: 'PHP', value: 'php' },
                    //     { text: 'Ruby', value: 'ruby' },
                    //     { text: 'Python', value: 'python' },
                    //     { text: 'Java', value: 'java' },
                    //     { text: 'C', value: 'c' },
                    //     { text: 'C#', value: 'csharp' },
                    //     { text: 'C++', value: 'cpp' }
                    // ],
                }}
                onEditorChange={handleEditorChange}
            />

            <FormErrorMessage error={error} />
        </EditorContainerStyle>
    );
};

CustomTextEditor.defaultProps = {
    defaultValue: "<p></br></p>",
};

export { CustomTextEditor };
