import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  CustomSelect,
  MaskTextBox,
  TextBox,
} from 'components/forms';
import { CustomModal } from 'components';
import { Add, Save } from '@mui/icons-material';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { services } from 'redux/services';
import { useToastMessage } from 'hooks';
import { useIntl } from 'react-intl';
import { MiscellaneousForm } from 'pages';

const item = {
  expense_item: null,
  qty: 1,
  rate: 0,
  total_amount: 0,
  discount_amount: 0,
  paid_amount: 0,
};

const defaultValues = {
  miscellaneousItem: [item],
};

const Form: React.FunctionComponent<any> = (props): JSX.Element => {
  const { onHide, show, category, miscellaneousList, refreshData, headerText } = props;
  const { formatMessage } = useIntl();
  const { toastMessage } = useToastMessage();
  const [viewModal, setViewModal] = useState(false);
  const [miscellaneousData, setMiscellaneousData] = useState<any>([]);

  const methods = useForm<any>({
    mode: 'onSubmit',
    defaultValues: defaultValues,
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    setError,
    reset,
    watch,
  } = methods;

  const miscellaneousItem: any = useFieldArray({
    control,
    name: 'miscellaneousItem',
  });

  useEffect(() => {
    if (miscellaneousList?.length > 0) {
      setMiscellaneousData(miscellaneousList)
    } else {
      setMiscellaneousData([])
    }
  }, [miscellaneousList]);

  const onSubmit = (data: any) => {
    data.id = null;
    data.remarks = '';
    data.type = category;
    data.miscellaneousItem = data?.miscellaneousItem?.map((item: any) => ({ ...item, id: item.pid })) ?? [];
    const apiCall = 'createExpense';
    services[apiCall](data)
      .then((res: any) => {
        toastMessage(formatMessage({ id: 'created-message' }, { title: "miscellaneous" }));
        refreshData();
        onHide();
      })
      .catch((err: any) => {
        toastMessage(err?.data?.message || "Something wrong !", 'error');
      })
  }

  const expenseOption = miscellaneousData?.map((item: any) => ({
    label: item.name,
    value: item.code,
    code: item.code,
    actual_price: item.actual_price,
  }));

  // useEffect(() => {
  //   if (miscellaneousItemData?.expense_item_detail) {
  //     reset({
  //       miscellaneousItem: miscellaneousItemData?.expense_item_detail.map((expense: any) => ({
  //         id: expense.id,
  //         pid: expense.id,
  //         code: expense.code,
  //         title: expense.title,
  //         paid_amount: expense.paid_amount,
  //         total_amount: expense.total_amount,
  //         discount_amount: expense.discount_amount,
  //         qty: expense.qty,
  //         rate: expense.rate,
  //         expense_item: expense.code
  //       }))
  //     });
  //   }
  // }, [miscellaneousItemData]);

  const handleSelect = (selected: any, index: number) => {
    const rate = selected?.actual_price ? selected?.actual_price : 0;
    setValue(`miscellaneousItem.${index}.expense_item`, selected?.value);
    setValue(`miscellaneousItem.${index}.qty`, 1);
    setValue(`miscellaneousItem.${index}.rate`, rate);
    setValue(`miscellaneousItem.${index}.code`, selected?.code);
    setValue(`miscellaneousItem.${index}.title`, selected?.label);
    let total_amount = +watch(`miscellaneousItem.${index}.qty`) * rate;
    setValue(`miscellaneousItem.${index}.total_amount`, total_amount);
    setValue(`miscellaneousItem.${index}.discount_amount`, 0);
    setValue(`miscellaneousItem.${index}.paid_amount`, total_amount);
  };

  return (
    <>
      <CustomModal
        open={show}
        onClose={onHide}
        size={'md'}
        aria-labelledby="contained-modal-title-vcenter"
        title={headerText}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="40%">
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
                    <Typography fontWeight={'bold'}>Miscellaneous Items</Typography>
                    <Button startIcon={<Add />} size='small' variant='contained' onClick={() => setViewModal(true)}>Add </Button>
                  </Box>
                </TableCell>
                <TableCell width="15%" align="justify">Rate</TableCell>
                <TableCell width="5%" align="justify">Qantity</TableCell>
                <TableCell width="20%" align="justify">Amount</TableCell>
                <TableCell width="25%" align="justify">
                  <Button variant='contained' startIcon={<Add />} onClick={() => miscellaneousItem.append(item)}>Add more</Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {expenseOption?.length > 0 ? miscellaneousItem.fields.map(
                (
                  {
                    id,
                    pid,
                    code,
                    title,
                    qty,
                    rate,
                    total_amount,
                    expense_item,
                    discount_amount,
                    paid_amount,
                  }: any,
                  index: number,
                ) => {
                  return (<>
                    <TableRow
                      id={id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell sx={{ display: 'none' }}>
                        <Box>
                          <TextBox
                            type="text"
                            defaultValue={pid}
                            {...register(`miscellaneousItem.${index}.pid`)}
                          />
                          <TextBox
                            type="text"
                            {...register(`miscellaneousItem.${index}..code`)}
                            defaultValue={code}
                          />
                          <TextBox
                            type="text"
                            {...register(`miscellaneousItem.${index}.title`)}
                            defaultValue={title}
                          />
                          <MaskTextBox
                            required
                            defaultValue={discount_amount}
                            name={`miscellaneousItem.${index}.discount_amount`}
                            control={control}
                            inputMode="numeric"
                            maskOptions={{
                              integerLimit: 4,
                              allowNegative: false,
                              allowDecimal: true,
                              decimalSymbol: '.',
                              includeThousandsSeparator: false,
                              allowLeadingZeroes: true,
                              decimalLimit: 2,
                            }}
                            rules={{
                              required: true,
                              validate: (value: number) => {
                                if (
                                  +value >
                                  +watch(`miscellaneousItem.${index}.total_amount`)
                                )
                                  return 'Discount cannot be greater than total amount';
                              },
                            }}
                            error={
                              errors?.miscellaneousItem?.[index]?.discount_amount
                            }
                            onValueChange={(e: any) => {
                              const value = e.target.value;
                              const total_amount =
                                +watch(`miscellaneousItem.${index}.total_amount`) -
                                +value;
                              setValue(
                                `miscellaneousItem.${index}.paid_amount`,
                                total_amount,
                              );
                            }}
                          />
                          <MaskTextBox
                            required
                            defaultValue={paid_amount}
                            name={`miscellaneousItem.${index}.paid_amount`}
                            control={control}
                            inputMode="numeric"
                            maskOptions={{
                              integerLimit: 5,
                              allowNegative: false,
                              allowDecimal: true,
                              decimalSymbol: '.',
                              includeThousandsSeparator: false,
                              allowLeadingZeroes: true,
                              decimalLimit: 2,
                            }}
                            rules={{
                              required: true,
                            }}
                            error={
                              errors?.['miscellaneousItem']?.[index]?.[
                              'paid_amount'
                              ]
                            }
                            disabled
                          />

                        </Box>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <CustomSelect
                          disableClearable
                          control={control}
                          options={expenseOption}
                          placeholder='Select Item...'
                          defaultValue={expenseOption?.find((x: any) => x.value == expense_item)}
                          name={`miscellaneousItem.${index}.expense_item`}
                          value={expenseOption?.find((x: any) => x.value == expense_item)}
                          onChangeValue={selected =>
                            handleSelect(selected, index)
                          }
                          rules={{
                            required: true,
                          }}
                          error={errors?.['miscellaneousItem']?.[index]?.['expense_item']}
                        />
                      </TableCell>
                      <TableCell>
                        <MaskTextBox
                          defaultValue={rate}
                          name={`miscellaneousItem.${index}.rate`}
                          control={control}
                          inputMode="numeric"
                          maskOptions={{
                            integerLimit: 3,
                            allowNegative: false,
                            allowDecimal: true,
                            decimalSymbol: '.',
                            includeThousandsSeparator: false,
                            allowLeadingZeroes: true,
                            decimalLimit: 2,
                          }}
                          rules={{
                            required: true,
                          }}
                          onValueChange={(e: any) => {
                            const value = e.target.value;
                            if (value > 0) {
                              const total_amount = Number(watch(`miscellaneousItem.${index}.qty`)) * Number(value);
                              setValue(`miscellaneousItem.${index}.total_amount`, total_amount);
                              setValue(`miscellaneousItem.${index}.paid_amount`, total_amount);
                            }
                            setValue(`miscellaneousItem.${index}.rate`, value);
                          }}
                          error={errors?.['miscellaneousItem']?.[index]?.['rate']}
                        />
                      </TableCell>
                      <TableCell>
                        <MaskTextBox
                          defaultValue={qty > 0 ? qty : 1}
                          name={`miscellaneousItem.${index}.qty`}
                          control={control}
                          inputMode="numeric"
                          maskOptions={{
                            integerLimit: 3,
                            allowNegative: false,
                            allowDecimal: false,
                            decimalSymbol: '.',
                            includeThousandsSeparator: false,
                            allowLeadingZeroes: true,
                          }}
                          rules={{
                            required: true,
                          }}
                          error={errors?.['miscellaneousItem']?.[index]?.['qty']}
                          onValueChange={(e: any) => {
                            const value = e.target.value;
                            if (value > 0) {
                              const total_amount = Number(watch(`miscellaneousItem.${index}.rate`)) * Number(value);
                              setValue(`miscellaneousItem.${index}.total_amount`, total_amount);
                              setValue(`miscellaneousItem.${index}.paid_amount`, total_amount);
                            }
                            setValue(`miscellaneousItem.${index}.qty`, value);
                          }}
                        />
                      </TableCell>

                      <TableCell>
                        <MaskTextBox
                          defaultValue={total_amount}
                          name={`miscellaneousItem.${index}.total_amount`}
                          control={control}
                          inputMode="numeric"
                          maskOptions={{
                            integerLimit: 5,
                            allowNegative: false,
                            allowDecimal: true,
                            decimalSymbol: '.',
                            includeThousandsSeparator: false,
                            allowLeadingZeroes: true,
                            decimalLimit: 2,
                          }}
                          rules={{
                            required: true,
                          }}
                          onValueChange={(e: any) => {
                            const value = e.target.value;
                            const rate =
                              +value / +watch(`miscellaneousItem.${index}.qty`);
                            setValue(`miscellaneousItem.${index}.rate`, rate);
                            setValue(
                              `miscellaneousItem.${index}.total_amount`,
                              value,
                            );
                            setValue(
                              `miscellaneousItem.${index}.paid_amount`,
                              value,
                            );
                          }}
                          error={
                            errors?.['miscellaneousItem']?.[index]?.[
                            'total_amount'
                            ]
                          }
                          disabled
                        />
                      </TableCell>

                      <TableCell>
                        {miscellaneousItem.fields?.length > 1 &&
                          <Button
                            variant="outlined"
                            color='error'
                            onClick={() => {
                              miscellaneousItem.remove(index);
                            }}
                          >
                            Remove
                          </Button>
                        }
                      </TableCell>
                    </TableRow>

                  </>
                  );
                },
              ) :
                <TableRow>
                  <TableCell align="center" colSpan={5}><Typography>No miscellaneous items found. <Typography component="span" onClick={() => setViewModal(true)} color="primary">Add</Typography> the new one.</Typography></TableCell>
                </TableRow>
              }
              <TableRow>
                {/* <TableCell rowSpan={3} /> */}
                <TableCell align="right" colSpan={3}><b>Total Amount </b></TableCell>
                <TableCell align="left" colSpan={1}>Rs {watch('miscellaneousItem')?.reduce((total: any, item: any) => total + Number(item.total_amount), 0) || 0}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {viewModal &&
            <MiscellaneousForm
              open={viewModal}
              closeModal={() => setViewModal(false)}
              formMode='create'
              selectedData={null}
              headerText={formatMessage({ id: "create-miscellaneous" })}
              refreshData={(data: { [index: string]: string }) => data ? setMiscellaneousData([data, ...miscellaneousData]) : null}
              style={{
                zIndex: 1400
              }}
            />
          }
        </TableContainer>
        <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2, paddingX: 2, margin: 2 }}>
          <Button variant='contained' color='info' startIcon={<Save />} onClick={handleSubmit(onSubmit)}>Submit</Button>
        </Box>
      </CustomModal >
    </>
  );
};

export default Form;
