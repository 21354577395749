import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid } from '@mui/material';

import { CustomCheckBox, CustomSelect, CustomTextBox } from 'components/forms';
import { CustomDrawer, HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';
import { planPeriodTypeOption } from 'utils/constants';

interface Props {
    open: boolean;
    closeModal: () => void;
    formMode: 'create' | 'update';
    selectedData: any;
    headerText: string;
    refreshData: () => void;
    subscriptionFeatureOption?: any;
}

const featuresInitialState = {
    detail: '',
}

const Form = (props: Props) => {
    const { closeModal, open, formMode, selectedData, headerText, refreshData, subscriptionFeatureOption } = props;
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, control, setValue, register, clearErrors, reset } = useForm<any>({
        mode: 'all',
        defaultValues: { status: 1, features: [featuresInitialState] }
    });
 
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);

    function onSubmit(data: any) {
        setLoading(true);
        data.feature_id = data?.feature_id?.value;
        data.period_type = data?.period_type?.value;
        data.status = data?.status ? 1 : 0;
        if (formMode === 'update') data.id = selectedData?.id;
        const apiFunc = formMode === 'create' ? 'createSubscriptionPlan' : 'updateSubscriptionPlan';
        const message = formMode === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: message }, { title: "subscription-plan" }));
                closeModal();
                refreshData();
            })
            .catch((err: any) => {
                setLoading(false);
                toastMessage(err.message, 'error');
            })
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (selectedData) {
            reset({
                name: selectedData.name,
                amount: selectedData.amount,
                total_amount: selectedData.total_amount,
                saved_amount: selectedData.saved_amount,
                plan_period: selectedData.plan_period,
                feature_id: subscriptionFeatureOption?.find((item: any) => item.value == selectedData.feature_id),
                period_type: planPeriodTypeOption?.find((item: any) => item.value == selectedData.period_type),
                status: selectedData.status,
            });
        }

    }, [formMode === 'update', subscriptionFeatureOption]);

    return (
        <CustomDrawer
            formMode={formMode}
            open={open}
            onCancel={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            title={headerText}
            size='sm'
        >
            <Grid container spacing={2}>
                {loading && <HoverLoader />}
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "name" })}
                        name="name"
                        control={control}
                        error={errors?.name}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomSelect
                        options={subscriptionFeatureOption}
                        label={formatMessage({ id: "feature" })}
                        name="feature_id"
                        control={control}
                        error={errors.feature_id}
                        rules={{ required: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomTextBox
                        label={formatMessage({ id: "plan-period" })}
                        name="plan_period"
                        control={control}
                        error={errors?.plan_period}
                        type='number'
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomSelect
                        options={planPeriodTypeOption}
                        label={formatMessage({ id: "period-type" })}
                        name="period_type"
                        control={control}
                        error={errors.period_type}
                        rules={{ required: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "amount" })}
                        name="amount"
                        type='number'
                        control={control}
                        error={errors?.amount}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "saved-amount" })}
                        name="saved_amount"
                        type='number'
                        control={control}
                        error={errors?.saved_amount}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "total-amount" })}
                        name="total_amount"
                        control={control}
                        error={errors?.total_amount}
                        type='number'
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>

                <Grid item xs={12}>
                    <CustomCheckBox
                        name="status"
                        label={formatMessage({ id: "is-active" })}
                        control={control}
                        isDefaultChecked
                    />
                </Grid>
            </Grid>
        </CustomDrawer>
    )
}

export default Form;