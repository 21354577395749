import { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useIntl } from "react-intl";

import CustomTable from "components/table";
import {
    ActionMenu,
    CustomButton,
    ConfirmationButton,
} from "components";
import { masterPaginationServices, tableInitialState, visaStatusOptions } from "utils/constants";
import { getTableParams } from "utils/global";
import { RootReducerState } from "utils/types";
import { getMasterPaginationData } from "redux/actions";
import PageLayout from "layouts/PageLayout";
import { visaColumns } from "utils/constants/tableColumn/column";
import { services } from "redux/services";
import { useCheckPermissions, useCheckRole, useToastMessage } from "hooks";
import { VisaForm } from "pages";
import VisaCheckForm from "./VisaCheckForm";
import { Delete, Edit, RemoveRedEye, Verified } from "@mui/icons-material";
import VisaView from "./View";
import { CustomTab } from "components";
import { useNavigate, useParams } from "react-router-dom";

const permissionSet = {
    createVisa: ['candidateVisas.createVisa'],
    updateVisa: ['candidateVisas.updateVisa'],
    deleteVisa: ['candidateVisas.deleteVisa'],
    visasExports: ['candidateVisas.visaListExport']
}

const Account: React.FC = () => {
    const { formatMessage } = useIntl();
    const { slug } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { toastMessage } = useToastMessage();
    const checkPermissions = useCheckPermissions(permissionSet);

    const [visaModal, setVisaModal] = useState(false);
    const [visaCheckModal, setVisaCheckModal] = useState(false);
    const [visaViewModal, setVisaViewModal] = useState(false);
    const { isAdmin, isDocumentation, isDeparture } = useCheckRole();
    const [selectedData, setSelectedData] = useState<any>();

    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const { userData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            userData:
                masterPaginationReducer[masterPaginationServices.candidateVisas].data,
            loading:
                masterPaginationReducer[masterPaginationServices.candidateVisas]
                    .loading,
        }),
        shallowEqual
    );

    const { rows, count } = userData;

    useEffect(() => {
        onApiCall();
    }, [tableState, slug]);

    function onApiCall(withState: boolean = true) {
        const params = getTableParams(withState ? tableState : {});
        if (slug === 'unverified') params.is_verified = 0;
        else if (slug === 'verified') params.is_verified = 1;
        else params.is_verified = 2;

        dispatch(
            getMasterPaginationData(masterPaginationServices.candidateVisas, params)
        );
    }

    let columnAction = {
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                const rowData = rows[tableMeta.rowIndex];
                return (
                    <ActionMenu>
                        {/* {(isDeparture && rowData?.is_verified == 0 || isAdmin) && (
                            <CustomButton
                                color="success"
                                size="small"
                                label="Verify"
                                variant="outlined"
                                onClick={() => handleVisaClick(rowData)}
                                startIcon={<Verified />}
                            />
                        )} */}

                        <CustomButton
                            color="warning"
                            size="small"
                            label="View"
                            variant="outlined"
                            onClick={() => handleViewClick(rowData)}
                            startIcon={<RemoveRedEye />}
                        />

                        {(checkPermissions.updateVisa && rowData?.is_verified == 0) && (
                            <CustomButton
                                color="secondary"
                                size="small"
                                label="Edit"
                                variant="outlined"
                                onClick={() => handleRowClick(rowData)}
                                startIcon={<Edit />}
                            />
                        )}

                        {(isDeparture && rowData?.is_verified == 0 || checkPermissions.deleteVisa) && (
                            <ConfirmationButton
                                color='error'
                                size='small'
                                label="Delete"
                                variant='outlined'
                                onConfirm={() => onDeleteData(rowData)}
                                title={formatMessage({ id: "delete-title" })}
                                subTitle={formatMessage({ id: "delete-confirmation" })}
                                startIcon={<Delete />}
                            />
                        )}
                    </ActionMenu>
                );
            },
        },
    };

    function handleViewClick(rowData: any) {
        setVisaViewModal(true);
        setSelectedData(rowData);
    }

    function handleVisaClick(rowData: any) {
        setVisaCheckModal(true);
        setSelectedData(rowData);
    }

    function handleRowClick(rowData: any) {
        setVisaModal(true);
        setSelectedData(rowData);
    }

    function onDeleteData(data: any) {
        setDeleteLoading(true);
        services
            .deleteCandidateVisa({ id: data.id })
            .then((res: any) => {
                onApiCall();
                toastMessage(
                    formatMessage(
                        { id: "cancelled-message" },
                        { title: formatMessage({ id: "visa" }) }
                    )
                );
            })
            .catch((err) => {
                toastMessage(err?.data?.message || formatMessage({ id: "opps-error-message" }), "error");
            })
            .finally(() => setDeleteLoading(false));
    }

    return (
        <PageLayout title={formatMessage({ id: "visa" })} hideToolbar>
            {slug &&
                <CustomTab
                    tabs={visaStatusOptions}
                    active={slug}
                    onChange={(tab: string) => {
                        navigate(`/admin/visas/${tab}`);
                        setTableState(tableInitialState);
                    }}
                />
            }
            <CustomTable
                columns={[...visaColumns(formatMessage), columnAction]}
                tableData={rows}
                tableState={tableState}
                rowsCount={count}
                setTableState={setTableState}
                loading={loading || deleteLoading}
                options={{
                    download:checkPermissions.visasExports,
                    print:checkPermissions.visasExports,
                }}
            />
            {visaModal && (
                <VisaForm
                    open={visaModal}
                    onClose={() => setVisaModal(false)}
                    defaultData={selectedData}
                    candidateData={{
                        uuid: selectedData?.candidate?.uuid,
                        passport_number: selectedData?.candidate?.passport_number,
                        dob_ad: selectedData?.candidate?.dob_ad,
                        full_name_search: selectedData?.candidate?.full_name_search,
                        passport_nationality: selectedData?.candidate?.passport_nationality,
                    }}
                    refreshData={onApiCall}
                    formType="update"
                />
            )}
            {visaCheckModal && (
                <VisaCheckForm
                    open={visaCheckModal}
                    onClose={() => setVisaCheckModal(false)}
                    defaultData={selectedData}
                    refreshData={onApiCall}
                />
            )}

            {visaViewModal && (
                <VisaView
                    open={visaViewModal}
                    onClose={() => setVisaViewModal(false)}
                    defaultData={[selectedData]}
                    refreshData={onApiCall}
                />
            )}

        </PageLayout>
    );
};

export default Account;
