const API_COMPLETION_URL = 'https://api.openai.com/v1/chat/completions'

export const API_MODEL = {
  GPT_35_TURBO: 'gpt-3.5-turbo-0613',
  GPT_35_TURBO_16K: 'gpt-3.5-turbo-16k-0613',
  GPT_35_TURBO_125: 'gpt-3.5-turbo-0125',
  GPT_4: 'gpt-4-0613',
} as const
export type API_MODEL = (typeof API_MODEL)[keyof typeof API_MODEL]

export interface StreamCompletionsRequestParams {
  messages: any[]
  temperature?: number
  model?: string
  abortController?: AbortController
}

const makeHeader = () => {
  const API_KEY = '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';
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${API_KEY}`,
  }
}

export const fetchStreamCompletionsApi = async (
  params: StreamCompletionsRequestParams,
  onReadText: (text: string) => void
) => {
  const response = await fetch(API_COMPLETION_URL, {
    method: 'POST',
    headers: makeHeader(),
    body: JSON.stringify({
      model: params.model != null ? params.model : API_MODEL.GPT_35_TURBO,
      temperature: params.temperature != null ? params.temperature : 0.7,
      stream: true,
      messages: params.messages,
    }),
  })
  const reader = response.body?.getReader()
  if (response.status !== 200 || !reader) {
    return 'error'
  }
  const decoder = new TextDecoder('utf-8')
  try {
    const read: any = async () => {
      const { done, value } = await reader.read()
      if (done) return reader.releaseLock()

      const chunk = decoder.decode(value, { stream: true })
      const jsons = chunk
        .split('data:')
        .map((data) => {
          const trimData = data.trim()
          if (trimData === '') return undefined
          if (trimData === '[DONE]') return undefined
          try {
            return JSON.parse(trimData)
          } catch (err) {
            console.error('Error parsing JSON:', err)
            console.log('Problematic JSON chunk:', trimData)
            return undefined
          }
        })
        .filter((data) => data);

      const translatedText = jsons.reduce((accumulatedText, currentJson) => {
        const content = currentJson?.choices[0]?.delta?.content || ''
        return accumulatedText + content
      }, '');

      onReadText(translatedText)
      return read()
    }
    await read()
  } catch (e) {
    console.error(e)
  }
  reader.releaseLock()
}

export const fetchCompletionsApi = async (
  params: StreamCompletionsRequestParams,
  onReadText: (text: string) => void
) => {
  const response = await fetch(API_COMPLETION_URL, {
    method: 'POST',
    headers: makeHeader(),
    body: JSON.stringify({
      model: params.model != null ? params.model : API_MODEL.GPT_35_TURBO_125,
      temperature: params.temperature != null ? params.temperature : 0.9,
      top_p: 0.9,
      messages: params.messages,
    }),
  })
  const reader = response.body?.getReader()
  if (response.status !== 200 || !reader) {
    return 'error'
  }
  const decoder = new TextDecoder('utf-8')
  try {
    const read: any = async () => {
      const { done, value } = await reader.read()
      if (done) return reader.releaseLock()

      const jsons = decoder.decode(value);
      const parsedJson = JSON.parse(jsons);

      const translatedText = parsedJson?.choices[0]?.message?.content;

      onReadText(translatedText)
      return read()
    }
    await read()
  } catch (e) {
    console.error(e)
  }
  reader.releaseLock()
}
