import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';
import { TreeViewBaseItem } from '@mui/x-tree-view/models';
import { CustomDrawer } from 'components';
import { services } from 'redux/services';
import { useToastMessage } from 'hooks';
import { useIntl } from 'react-intl';

interface InputItem {
  id: string;
  name: string;
  guard_name: string;
}

function camelCaseToTitleCase(camelCaseString:string) {
  return camelCaseString
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, function(str) { return str.toUpperCase(); })
    .trim();
}

function transformToTreeView(data: InputItem[]): TreeViewBaseItem[] {
  const tree: { [key: string]: TreeViewBaseItem } = {};

  data.forEach(item => {
    const [prefix, ...rest] = item.name.split('.');
    const label = rest.join('.');
    if (!tree[prefix]) {
      tree[prefix] = {
        id: prefix,
        label: camelCaseToTitleCase(prefix),
        children: []
      };
    }
    if (label)
      tree[prefix].children!.push({
        id: item.name,
        label:camelCaseToTitleCase(label),
      });
  });
  
  // Sort the children of each category alphabetically by label
  Object.values(tree).forEach(category => {
    category.children!.sort((a, b) => a.label.localeCompare(b.label));
  });

  // Convert tree object to array and sort it alphabetically by label
  return Object.values(tree).sort((a, b) => a.label.localeCompare(b.label));
}

function extractIds(nodes: TreeViewBaseItem[]): (string)[] {
  const ids: (string)[] = [];

  nodes.forEach(node => {
    ids.push(node.id);
    if (node.children) {
      ids.push(...extractIds(node.children));
    }
  });

  return ids;
}


function getItemDescendantsIds(item: TreeViewBaseItem) {
  const ids: string[] = [];
  item.children?.forEach((child) => {
    ids.push(child.id);
    ids.push(...getItemDescendantsIds(child));
  });

  return ids;
}

export default function RolePermission(props: any) {
  const { closeModal, open, permissionData, selectedData, refreshData } = props;
  const { formatMessage } = useIntl();
  // const [defaultExpand, setDefaultExpand] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const toggledItemRef = useRef<{ [itemId: string]: boolean }>({});
  const apiRef: any = useTreeViewApiRef();
  const { toastMessage } = useToastMessage();

  const handleItemSelectionToggle = (
    event: SyntheticEvent,
    itemId: string,
    isSelected: boolean,
  ) => {
    toggledItemRef.current[itemId] = isSelected;
  };

  const handleSelectedItemsChange = (
    event: SyntheticEvent,
    newSelectedItems: string[],
  ) => {
    setSelectedItems(newSelectedItems);
    const itemsToSelect: string[] = [];
    const itemsToUnSelect: { [itemId: string]: boolean } = {};
    Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
      const item = apiRef.current!.getItem(itemId);
      if (isSelected) {
        itemsToSelect.push(...getItemDescendantsIds(item));
      } else {
        getItemDescendantsIds(item).forEach((descendantId) => {
          itemsToUnSelect[descendantId] = true;
        });
      }
    });

    const newSelectedItemsWithChildren = Array.from(
      new Set(
        [...newSelectedItems, ...itemsToSelect].filter(
          (itemId) => !itemsToUnSelect[itemId],
        ),
      ),
    );

    setSelectedItems(newSelectedItemsWithChildren);

    toggledItemRef.current = {};
  };

  useEffect(() => {
    if (selectedData?.permissions?.length > 0) {
      const defaultTreeview = transformToTreeView(selectedData?.permissions);
      // setDefaultExpand(defaultTreeview?.map(t => t.id));
      setSelectedItems(extractIds(defaultTreeview));
    }
  }, [selectedData])

  function onSubmit() {
    services.assignPermission({
      role_id: selectedData.id,
      permissions: selectedItems.filter(value => permissionData?.map((x: any) => x.name)?.includes(value))
    }).then((res: any) => {
      refreshData();
      closeModal();
      toastMessage(formatMessage({ id: "update-message" }));
    })
      .catch((err) => {
        toastMessage(err?.data?.message || formatMessage({ id: "opps-error-message" }), 'error');
      })
  }

  return (
    <CustomDrawer
      formMode="view"
      open={open}
      onCancel={closeModal}
      title={"Update Permission"}
      onSubmit={onSubmit}
    >
      <RichTreeView
        sx={{ minWidth: 380 }}
        multiSelect
        checkboxSelection
        // defaultExpandedItems={defaultExpand}
        apiRef={apiRef}
        items={transformToTreeView(permissionData)}
        selectedItems={selectedItems}
        onSelectedItemsChange={handleSelectedItemsChange}
        onItemSelectionToggle={handleItemSelectionToggle}
      />
    </CustomDrawer>
  );
}
