
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from "react-intl";
import { RemoveRedEye, Edit, Delete } from '@mui/icons-material';

import CustomTable from 'components/table';
import { ActionMenu, ConfirmationButton, CustomButton } from 'components';
import { masterLookupServices, masterPaginationServices, tableInitialState, } from 'utils/constants';
import { getTableParams } from 'utils/global';
import { RootReducerState } from 'utils/types';
import { getMasterLookupData, getMasterPaginationData } from 'redux/actions';
import { vacancyColumns } from 'utils/constants/tableColumn/column';
import { services } from 'redux/services';
import { useCheckPermissions, useCheckRole, useCreateLookupOptions, useToastMessage } from 'hooks';
import View from './View';
import Form from './Form';
import PageLayout from 'layouts/PageLayout';

const permissionSet = {
    createCompanyVacancy: ['vacancies.createCompanyVacancy'],
    updateCompanyVacancy: ['vacancies.updateCompanyVacancy'],
    deleteCompanyVacancy: ['vacancies.deleteCompanyVacancy'],
}

const Vacancy: React.FC = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const { companyUuid } = useParams();
    const { toastMessage } = useToastMessage();
    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const checkPermissions = useCheckPermissions(permissionSet);

    const [viewModal, setViewModal] = useState(false);
    const [formModal, setFormModal] = useState(false);
    const [selectedData, setSelectedData] = useState<any>();

    const { userData, loading, candidateLookUp } = useSelector(
        ({ masterPaginationReducer, masterLookupReducer }: RootReducerState) => ({
            userData: masterPaginationReducer[masterPaginationServices.vacancy].data,
            loading: masterPaginationReducer[masterPaginationServices.vacancy].loading,
            candidateLookUp: masterLookupReducer[masterLookupServices.getCandidateLookup].data
        }),
        shallowEqual
    );

    const { rows, count } = userData;

    useEffect(() => {
        // if (companyUuid) {
        onApiCall();
        // }
    }, [tableState, companyUuid]);

    function onApiCall(withState: boolean = true) {
        let params = getTableParams(withState ? tableState : {});
        params.companyUuid = companyUuid;
        dispatch(getMasterPaginationData(masterPaginationServices.vacancy, params));
    }

    const selectOptions = useCreateLookupOptions(candidateLookUp);

    useEffect(() => {
        dispatch(getMasterLookupData(masterLookupServices.getCandidateLookup));
    }, []);

    let columnAction = {
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                const rowData = rows[tableMeta.rowIndex];
                return (
                    <ActionMenu>
                        <CustomButton
                            color="warning"
                            size="small"
                            label="View"
                            variant="outlined"
                            onClick={() => handleViewClick(rowData)}
                            startIcon={<RemoveRedEye />}
                        />

                        {checkPermissions.updateCompanyVacancy && (
                            <CustomButton
                                color="secondary"
                                size="small"
                                label="Edit"
                                variant="outlined"
                                onClick={() => handleRowClick(rowData)}
                                startIcon={<Edit />}
                            />
                        )}

                        {checkPermissions.deleteCompanyVacancy && (
                            <ConfirmationButton
                                color='error'
                                size='small'
                                label="Delete"
                                variant='outlined'
                                onConfirm={() => onDeleteData(rowData)}
                                title={formatMessage({ id: "delete-title" })}
                                subTitle={formatMessage({ id: "delete-confirmation" })}
                                startIcon={<Delete />}
                            />
                        )}
                    </ActionMenu>
                )
            }
        }
    }

    function handleViewClick(rowData: any) {
        setViewModal(true);
        setSelectedData(rowData);
    }

    function handleRowClick(rowData: any) {
        setFormModal(true);
        setSelectedData(rowData);
    }

    function onDeleteData(data: any) {
        setDeleteLoading(true);
        services.deleteVacancy({ id: data.id })
            .then((res: any) => {
                onApiCall();
                toastMessage(formatMessage({ id: "deleted-message" }, { title: formatMessage({ id: 'vacancy' }) }));
            })
            .catch((err) => {
                toastMessage(err?.data?.message || formatMessage({ id: "opps-error-message" }), 'error');
            })
            .finally(() => setDeleteLoading(false))
    }

    return (
        <PageLayout
            title={formatMessage({ id: "vacancy" })}
            onCreate={() => { setFormModal(true); setSelectedData(null) }}
            hideToolbar={!checkPermissions.createCompanyVacancy}
        >
            <CustomTable
                columns={[...vacancyColumns(formatMessage), columnAction]}
                tableData={rows}
                tableState={tableState}
                rowsCount={count}
                setTableState={setTableState}
                loading={loading || deleteLoading}
            />

            {formModal && (
                <Form
                    formType={selectedData ? 'update' : 'create'}
                    open={formModal}
                    onClose={() => setFormModal(false)}
                    defaultData={selectedData}
                    selectOptions={selectOptions}
                    refreshData={onApiCall}
                    companyUuid={companyUuid}
                />
            )}

            {viewModal && (
                <View
                    open={viewModal}
                    onClose={() => setViewModal(false)}
                    defaultData={selectedData}
                />
            )}
        </PageLayout>
    )
}

export default Vacancy;
