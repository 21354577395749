import { BASE_URI } from "utils/axios";

export const API_ENDPOINTS = {
	LOGIN: `${BASE_URI}/login`,
	CHANGE_PASSWORD: `${BASE_URI}/users/change-password`,
	GET_ACCOUNT: `${BASE_URI}/get-account`,
	SELECT_ACCOUNT: `${BASE_URI}/select-account`,
	LOGOUT: `${BASE_URI}/logout`,
	DASHBOARD: `${BASE_URI}/dashboard`,
	PROFILE: `${BASE_URI}/profile`,
	ASSIGN_PERMISSION: `${BASE_URI}/roles/assign-permissions`,

	LIST_ACCOUNT: `${BASE_URI}/accounts/list`,
	GET_ACCOUNT_BY_ID: `${BASE_URI}/accounts/get`,
	CREATE_ACCOUNT: `${BASE_URI}/accounts/save`,
	UPDATE_ACCOUNT: `${BASE_URI}/accounts/update`,
	DELETE_ACCOUNT: `${BASE_URI}/accounts/delete`,
	VERIFY_ACCOUNT: `${BASE_URI}/accounts/verify`,
	UPDATE_ACCOUNT_STATUS: `${BASE_URI}/accounts/update-status`,
	SAVE_ACCOUNT_FILE: `${BASE_URI}/accounts/documents/upload`,
	DELETE_ACCOUNT_FILE: `${BASE_URI}/accounts/documents/delete`,

	LIST_CANDIDATE: `${BASE_URI}/candidates/list`,
	GET_CANDIDATE_BY_UUID: `${BASE_URI}/candidates/get`,
	CREATE_CANDIDATE: `${BASE_URI}/candidates/save`,
	UPDATE_CANDIDATE: `${BASE_URI}/candidates/update`,
	UPDATE_CANDIDATE_FLAG: `${BASE_URI}/candidates/update-flag`,
	DELETE_CANDIDATE: `${BASE_URI}/candidates/delete`,
	CANDIDATE_LOOKUP: `${BASE_URI}/candidates/lookup`,
	CANDIDATE_CHECK_BLACKLIST: `${BASE_URI}/candidates/check-blacklist`,
	SAVE_CANDIDATE_FILE: `${BASE_URI}/candidates/documents/upload`,
	DELETE_CANDIDATE_FILE: `${BASE_URI}/candidates/documents/delete`,
	UPDATE_CANDIDATE_STATUS: `${BASE_URI}/candidates/update-status`,
	UPDATE_CANDIDATE_INFORMATION: `${BASE_URI}/candidates/update-information`,
	EXPORT_CANDIDATE_RESUME: `${BASE_URI}/candidates/export-resume`,
	UPDATE_CANDIDATE_PASSPORT_RECIEVED: `${BASE_URI}/candidates/update-passport-recieved`,
	ASSIGN_CANDIDATE_COMPANY: `${BASE_URI}/candidates/assign-company`,
	VALIDATE_CANDIDATE: `${BASE_URI}/candidates/validate`,
	CANDIDATE_ADVANCE_SEARCH: `${BASE_URI}/candidates/advance-search`,
	EXPORT_CANDIDATE_ADVANCE_SEARCH: `${BASE_URI}/candidates/export`,

	LIST_CANDIDATE_VISA: `${BASE_URI}/candidate-visas/list`,
	GET_CANDIDATE_VISA_BY_UUID: `${BASE_URI}/candidate-visas/get`,
	CREATE_CANDIDATE_VISA: `${BASE_URI}/candidate-visas/save`,
	UPDATE_CANDIDATE_VISA: `${BASE_URI}/candidate-visas/update`,
	DELETE_CANDIDATE_VISA: `${BASE_URI}/candidate-visas/delete`,
	VERIFY_CANDIDATE_VISA: `${BASE_URI}/candidate-visas/verify`,
	UPDATE_CANDIDATE_VISA_INFO: `${BASE_URI}/candidate-visas/update-info`,
	CANDIDATE_VISA_ADVANCE_SEARCH: `${BASE_URI}/candidate-visas/advance-search`,
	EXPORT_CANDIDATE_VISA_ADVANCE_SEARCH: `${BASE_URI}/candidate-visas/export`,

	LIST_COMPANY: `${BASE_URI}/companies/list`,
	GET_COMPANY_BY_UUID: `${BASE_URI}/companies/get`,
	CREATE_COMPANY: `${BASE_URI}/companies/save`,
	UPDATE_COMPANY: `${BASE_URI}/companies/update`,
	DELETE_COMPANY: `${BASE_URI}/companies/delete`,
	COMPANY_SEARCH: `${BASE_URI}/companies/advance-search`,
	GET_COMPANY_WITH_VACANCY: `${BASE_URI}/companies/vacancies`,

	LIST_VACANCY: `${BASE_URI}/vacancies/list`,
	GET_VACANCY_BY_UUID: `${BASE_URI}/vacancies/get`,
	CREATE_VACANCY: `${BASE_URI}/vacancies/save`,
	UPDATE_VACANCY: `${BASE_URI}/vacancies/update`,
	DELETE_VACANCY: `${BASE_URI}/vacancies/delete`,

	LIST_USER: `${BASE_URI}/account-users/list`,
	GET_USER_BY_UUID: `${BASE_URI}/account-users/get`,
	CREATE_USER: `${BASE_URI}/account-users/save`,
	UPDATE_USER: `${BASE_URI}/account-users/update`,
	UPDATE_USER_FLAG: `${BASE_URI}/account-users/update-flag`,
	DELETE_USER: `${BASE_URI}/account-users/delete`,
	USER_LOOKUP: `${BASE_URI}/account-users/lookup`,

	LIST_EXPENSE: `${BASE_URI}/expense-items/list`,
	CREATE_EXPENSE: `${BASE_URI}/expense-items/save`,
	DELETE_EXPENSE: `${BASE_URI}/expense-items/delete`,
	GENERATE_REPORT: `${BASE_URI}/expense-items/reports`,

	GET_SUBSCRIPTION: `${BASE_URI}/subscription/get`,
	LIST_SUBSCRIPTION: `${BASE_URI}/subscription/list`,
	CREATE_SUBSCRIPTION: `${BASE_URI}/subscription/choose-plan`,
	ENABLE_TRIAL_SUBSCRIPTION: `${BASE_URI}/subscription/enable-trial`,
	CANCEL_SUBSCRIPTION: `${BASE_URI}/subscription/cancel`,
	GET_SUBSCRIPTION_PLAN: `${BASE_URI}/subscription/all-plan`,
	GET_SUBSCRIPTION_PAYMENT_METHOD: `${BASE_URI}/subscription/all-payment-method`,
	
	GET_BILLING: `${BASE_URI}/subscription/billing`,
	SAVE_BILLING: `${BASE_URI}/subscription/billing-payment`,
	COMPLETE_PAYMENT: `${BASE_URI}/subscription/complete-payment`,

	ESEWA_SUCCESS: `${BASE_URI}/esewa/success`,
	KHALTI_INIT: `${BASE_URI}/khalti/init-transaction`,
	KHALTI_CONFIRM: `${BASE_URI}/khalti/confirm-transaction`,
	KHALTI_SUCCESS: `${BASE_URI}/khalti/success`,
};
