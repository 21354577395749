import { useIntl } from "react-intl";
import { Alert, Autocomplete, Box, Button, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Add, Refresh, Update } from "@mui/icons-material";

import { CustomTextEditor, Select as CustomSelect, TextArea } from "components/forms";
import CardLayout from "layouts/CardLayout";
import { services } from "redux/services";
import { useToastMessage } from "hooks";
import { useEffect, useState } from "react";
import { ProfessionForm } from "pages";
import QuickCompanyCreateForm from "pages/company/QuickCreateForm";

interface Props {
    uuid: string;
    companyApplied: {
        profession: any,
        company_id: any,
        vacancy_id: any,
    };
    setCompanyApplied: any;
    companyData: any;
    refreshCompanyApi: any;
    vacancyData: any;
}
const CompanyAppliedForm = ({ uuid, companyApplied, vacancyData, companyData, setCompanyApplied, refreshCompanyApi }: Props) => {
    const { formatMessage } = useIntl();
    const { toastMessage } = useToastMessage();

    const [vacanciesData, setVacanciesData] = useState([]);
    const [professionData, setProfessionData] = useState([]);

    function onSubmit() {
        services.updateCandidateVacancy({
            id: vacancyData?.id,
            uuid,
            profession: companyApplied.profession?.value,
            company_id: companyApplied.company_id?.value,
            vacancy_id: companyApplied?.vacancy_id?.value,
            profession_detail: companyApplied.profession,
        })
            .then((res: any) => {
                toastMessage(formatMessage({ id: 'updated-message' }, { title: formatMessage({ id: "vacancy" }) }));
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || "Something Wrong", "error");
            });
    }

    const handleChangeText = (name: string, value: any) => {
        if (name === 'company_id') {
            setVacanciesData(value?.vacancies || []);
            setCompanyApplied({ ...companyApplied, [name]: value, vacancy_id: null, profession: null });
        }
        else if (name === 'vacancy_id') {
            setProfessionData(value?.details || []);
            setCompanyApplied({ ...companyApplied, [name]: value, profession: null });
        } else {
            setCompanyApplied({ ...companyApplied, [name]: value });
        }
    }

    useEffect(() => {
        if (vacancyData) {
            const company = companyData.find((x: any) => x.value === vacancyData?.company_id);
            const vacancy = company?.vacancies?.find((x: any) => x.value === vacancyData?.vacancy_id) || null;
            const profession = vacancy?.details?.find((x: any) => x.value === vacancyData?.profession) || null;
            setVacanciesData(company?.vacancies || []);
            setProfessionData(vacancy?.details || []);
            setTimeout(() => {
                setCompanyApplied({
                    ...companyApplied,
                    company_id: company,
                    vacancy_id: vacancy,
                    profession: profession,
                })
            }, 500);
        }
    }, [companyData, vacancyData]);

    return (
        <CardLayout title={formatMessage({ id: 'company' }) + " " + formatMessage({ id: 'vacancy' })}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <CustomSelect
                                options={companyData}
                                label={formatMessage({ id: "company" })}
                                value={companyApplied.company_id}
                                onChange={(_, value: any) => handleChangeText('company_id', value)}
                            // disableClearable
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <Button
                                    fullWidth
                                    color="warning"
                                    variant='contained'
                                    startIcon={<Refresh />}
                                    onClick={refreshCompanyApi}
                                    sx={{ padding: 2, marginRight: 1 }}
                                >
                                    Refresh
                                </Button>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    startIcon={<Add />}
                                    onClick={() => window.open('/admin/companies/create')}
                                    sx={{ padding: 2 }}
                                >
                                    Add
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <CustomSelect
                        options={vacanciesData}
                        label={formatMessage({ id: "vacancy" }) + " " + formatMessage({ id: "chalan-number" })}
                        renderOption={(props, option: any) => (
                            <Box component="li" {...props}>
                                <Typography fontWeight="bold">{option.label}</Typography>&nbsp;&nbsp;<Typography variant="body2">(Publish Date:&nbsp;{option?.publish_date}&nbsp;|&nbsp;Lot Number:&nbsp;{option.lot_number}&nbsp;|&nbsp;Contract:&nbsp;{option.contract_period})</Typography>
                            </Box>
                        )}
                        value={companyApplied.vacancy_id}
                        onChange={(_, value: any) => handleChangeText('vacancy_id', value)}
                        disableClearable
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomSelect
                        options={professionData}
                        label={formatMessage({ id: "profession" })}
                        renderOption={(props, option: any) => (
                            <Box component="li" {...props}>
                                <Typography fontWeight="bold">{option.label}</Typography>&nbsp;&nbsp;<Typography variant="body2">(Male:&nbsp;{option?.male}&nbsp;|&nbsp;Female:&nbsp;{option.female}&nbsp;|&nbsp;Salary:&nbsp;{option.currency}&nbsp;{option.salary})</Typography>
                            </Box>
                        )}
                        value={companyApplied.profession}
                        onChange={(_, value: any) => handleChangeText('profession', value)}
                        disableClearable
                    />
                </Grid>
                <Grid item xs={12} textAlign="end">
                    <Button startIcon={<Update />} variant="contained" color="secondary" size="large" onClick={onSubmit}>
                        Update
                    </Button>
                </Grid>
            </Grid>
        </CardLayout>
    );
};

export default CompanyAppliedForm;
