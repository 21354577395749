import { useState } from 'react';
import { CustomModal, HoverLoader } from 'components';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Box, Button, Grid, IconButton, InputAdornment, Tooltip } from '@mui/material';
import { CustomTextBox } from 'components/forms';
import { services } from 'redux/services';
import { useToastMessage } from 'hooks';
import { validationRule } from 'utils/global';
import { Info, RemoveRedEye, RemoveRedEyeOutlined } from '@mui/icons-material';

interface Props {
    open: boolean;
    onClose: () => void;
}

const ChangePasswordForm = ({ onClose, open }: Props) => {
    const { handleSubmit, formState: { errors }, watch, control } = useForm({ mode: 'all' });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { toastMessage } = useToastMessage();

    function onSubmit(data: any) {
        setLoading(true);
        services.changePassword(data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: 'change-password' }, { title: "user" }));
                onClose();
            })
            .catch((err: any) => {
                setLoading(false);
                toastMessage(err?.data?.message || "Something wrong !", 'error');
            })
    }

    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={formatMessage({ id: "change-password" })}
        >
            <Box sx={{ padding: 1 }}>
                <Grid container spacing={2}>
                    {loading && <HoverLoader />}
                    <Grid item xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "current-password" })}
                            name="current_password"
                            control={control}
                            error={errors?.current_password}
                            type={showPassword ? "text" :"password"}
                            rules={validationRule.textbox({ required: true, type: 'passwordPolicy' })}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "password" })}
                            name="password"
                            control={control}
                            error={errors?.password}
                            type={showPassword ? "text" :"password"}
                            rules={{
                                ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                                validate: (value: any) => (watch('confirmation_password') ? (watch('confirmation_password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                            }}
                            autoComplete="off"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                                        <IconButton onClick={() => setShowPassword(prev => !prev)}>
                                            {showPassword ? <RemoveRedEye/> : <RemoveRedEyeOutlined/>}
                                        </IconButton>
                                        <Tooltip title={formatMessage({ id: "password-policy-message" })}>
                                            <Info color="primary" />
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "confirm-password" })}
                            name="confirmation_password"
                            control={control}
                            error={errors?.confirmation_password}
                            type={showPassword ? "text" :"password"}
                            rules={{
                                ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                                validate: (value: any) => (watch('password') ? (watch('password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                            }}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} onClick={handleSubmit(onSubmit)} justifyContent='flex-end'>
                        <Button variant='contained' fullWidth size='large'>
                            {formatMessage({ id: "change-password" })}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </CustomModal>
    );
}

export default ChangePasswordForm;