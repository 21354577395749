import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { makeStyles } from 'tss-react/mui';

import Box from "@mui/material/Box";
import Tabs, { TabsProps } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Button, Paper, Typography } from "@mui/material";
import { candidateStatusOptions } from "utils/constants";
import { useSelector } from "react-redux";
import { RootReducerState } from "utils/types";
import { findCommonItems } from "utils/global";

type TabContainerProps = {
    onChange?: (value: string) => void;
    status: string | undefined;
    totalItems: number;
} & Omit<TabsProps, "onChange">;

const useStyles = makeStyles()((theme) => ({
    customTabRoot: {
        color: theme.palette.primary.main,
        minHeight: "38px",
        height: "38px",
    },
    customTabIndicator: {
        height: "0px"
    },
    tabRoot: {
        textTransform: "uppercase",
        minHeight: "38px",
        height: "38px",
        transition: 'all 0.3s ease-in',
        color: theme.palette.text.secondary,
        borderRight: `1px solid ${theme.palette.divider}`,
        "&:hover": {
            backgroundColor: theme.palette.grey[400],
            // color: theme.palette.text.primary,
        }
    },
    activeTabRoot: {
        background: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.common.white} !important`,
        fontWeight: "bolder"
    }
})
);

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        className: 'text-13 font-regular'
    }
}

const CustomCandidateTab = ({ onChange, status, totalItems }: TabContainerProps) => {
    const user = useSelector(({ auth }: RootReducerState) => auth.data);
    const { formatMessage } = useIntl();
    const [statusOptions, setStatusOptions] = useState<any>([]);
    const { classes } = useStyles();
    const [activeTab, setActiveTab] = useState<number>();

    useEffect(() => {
        if (user) {
            // const options = candidateStatusOptions?.map(x => findCommonItems(x.roles, user?.role) && x);
            const options = candidateStatusOptions;
            setStatusOptions(options.filter(Boolean));
            setActiveTab(options.filter(Boolean).findIndex(x => x.value === status));
        }
    }, [user, status]);

    return (
        <Box marginBottom={1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', overflow: "hidden" }} component={Paper} elevation={2}>
            <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={activeTab}
                onChange={(_: any, value: number) => {
                    setActiveTab(value);
                    if (onChange) {
                        const active = statusOptions.find((_x: any, i: number) => i === value) as any;
                        onChange(active?.value);
                    }
                }}
                classes={{
                    root: classes.customTabRoot,
                    indicator: classes.customTabIndicator,
                }}
            >
                {statusOptions.map((tab: { value: string; label: string; icon: any }, index: number) => (
                    <Tab
                        icon={tab.icon}
                        iconPosition="start"
                        key={index}
                        label={formatMessage({ id: tab.label })}
                        {...a11yProps(index)}
                        classes={{ root: classes.tabRoot, selected: classes.activeTabRoot + " font-medium" }}
                    />
                ))}
            </Tabs>
            <Button size="small" color="info" endIcon={<Typography variant="subtitle2"
                sx={{ fontSize: '14px !important', marginRight: 2 }}>{totalItems}</Typography>}>
                TOTAL ROW :
            </Button>
        </Box >
    )
}

export default CustomCandidateTab;