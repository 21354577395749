import { Fragment, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import { CustomTab } from 'components';
import Candidate from './Candidate';
import Company from './Company';
import { Container, InputAdornment, OutlinedInput, styled } from '@mui/material';
import Search from '@mui/icons-material/Search';
import { useAsyncDebounce } from 'hooks';
import { CustomButton } from 'components';
import { advanceSearchTab } from 'utils/constants';
import SeoLayout from 'layouts/SeoLayout';

interface Props {

}

const SearchStyle = styled(OutlinedInput)(({ theme }: any) => ({
    width: '100%',
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': { width: '100%', boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));


const AdvanceSearch = (props: Props) => {
    const [activeTab, setActiveTab] = useState<string>(advanceSearchTab[0].value);
    const [searchText, setSearchText] = useState<string>('');
    const ref: any = useRef();
    const onChangeSearchQuery = useAsyncDebounce(() => {
        ref?.current?.onApiCall();
    }, 400);

    return (
        <Fragment>
            <SeoLayout title={'Advance Search'} />
            <Container maxWidth="xl" style={{ maxWidth: '100%' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomTab
                            tabs={advanceSearchTab}
                            active={activeTab}
                            onChange={(tab) => { setActiveTab(tab); setSearchText("") }}
                        />
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <SearchStyle
                            placeholder="Search..."
                            value={searchText}
                            size="small"
                            onChange={(e: any) => {
                                setSearchText(e.target.value);
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            }
                        />
                    </Grid>

                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <CustomButton
                            label='Search'
                            fullWidth
                            onClick={onChangeSearchQuery}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {activeTab === 'candidate' && <Candidate searchText={searchText} ref={ref} />}
                        {activeTab === 'company' && <Company searchText={searchText} ref={ref} />}
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    )
}

export default AdvanceSearch;