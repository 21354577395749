// @mui
import { Box, Button, Card, CardActions, CardContent, CardHeader, Container, Grid, Stack, Typography, alpha } from "@mui/material";
import { useCheckRole, useToastMessage } from "hooks";
import moment from "moment";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import PageLayout from "layouts/PageLayout";
import { useEffect, useState } from "react";
import { services } from "redux/services";

import WorkIcon from '@mui/icons-material/Work';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AttributionIcon from '@mui/icons-material/Attribution';
import AppWebsiteVisits from "sections/dashboard/AppWebsiteVisits";
import { ListAlt, Payment, Star, Upgrade } from "@mui/icons-material";

import { HoverLoader, SimpleTable } from "components";
import { candidateStatusOptions, visaStatusOptions } from "utils/constants";
import { AppCandidateSummary, AppCardSummary, AppWidgetSummary } from "sections/dashboard/AppWidgetSummary";
import PaymentModal from "pages/subscriptions/PaymentMethod";
import { dashboardVacancySummary } from "utils/constants/tableColumn/column";


export default function DashboardApp() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [dashboard, setDashboard] = useState<any>({
    candidate: 0,
    company: 0,
    agent: 0,
    account: 0,
    subscription: 0,
    visa: 0,
    candidateSummary: null,
    visaSummary: null,
    vacancySummary: []
  });
  const { formatMessage } = useIntl();
  const { toastMessage } = useToastMessage();
  const { isSuperAdmin, isAdmin, isAgent, isAccountant, isReceptionist, isDocumentation, isDeparture } = useCheckRole();
  const [remainingDays, setRemainingDay] = useState(0);
  const [paymentOpen, setPaymentOpen] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    services
      .dashboard()
      .then((res: any) => {
        const response = res?.result;
        setDashboard(response);
        const calcDays = moment(response?.subscription?.end_date).diff(moment(), 'days');
        setRemainingDay(calcDays);
      })
      .catch((err) => {
        console.log({ err})
        toastMessage(err?.data?.message ? err?.data?.message : formatMessage({ id: "opps-error-message" }), 'error');
      }).finally(() => setTimeout(() => {
        setLoading(false);
      }, 1000));
  }, []);

  return (
    <PageLayout title="Dashboard" isList={false} hideToolbar>
      {loading && <HoverLoader />}
      {isSuperAdmin ?
        <Grid container spacing={2} marginBottom={3}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <AppWidgetSummary
              title="Agencies"
              total={dashboard?.account}
              color="secondary"
              icon={<WorkIcon />}
              to={`/admin/agencies`}
            />
          </Grid>
        </Grid>
        :
        <Grid container spacing={2} marginBottom={3}>
          <Grid item xs={12}>
            <Typography variant="h5" color='gray'>
              Candidate Summary
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <AppCandidateSummary
              title="all"
              total={dashboard?.candidate}
              icon={<EngineeringIcon />}
            />
          </Grid>
          {dashboard?.candidateSummary && candidateStatusOptions?.filter(x => x.value != 'all')?.sort((a, b) => a.sort - b.sort)?.map((status: any, inds: number) =>
            <Grid item lg={2} md={4} sm={6} xs={12} key={inds}>
              <AppCandidateSummary
                title={status.value}
                total={dashboard?.candidateSummary?.[status.value] || 0}
                color={status.color}
                icon={status.icon}
              />
            </Grid>
          )}
        </Grid>
      }
      {(isDocumentation || isAccountant || isAdmin || isDeparture || isAgent || isReceptionist) &&
        <Grid container spacing={3}>
          {(isDocumentation || isAdmin || isDeparture) &&
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={3} marginBottom={3}>
                <Grid item xs={12}>
                  <Typography variant="h5" color='gray'>
                    Other Summary
                  </Typography>
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <AppCardSummary
                    title={"Total Visa"}
                    total={dashboard?.visa}
                    color='primary'
                    icon={<ListAlt />}
                    to={`/admin/visas/all`}
                  />
                </Grid>

                {visaStatusOptions?.filter(x => x.value != 'all')?.map((v: any, vid: number) =>
                  <Grid item lg={3} md={3} sm={6} xs={12} key={vid}>
                    <AppCardSummary
                      title={v.label + " Visa"}
                      total={dashboard?.visaSummary?.[v.id] || 0}
                      color={v.color}
                      icon={v.icon}
                      to={`/admin/visas/${v.value}`}
                    />
                  </Grid>
                )}


                {isAdmin &&
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <AppCardSummary
                      title="User"
                      total={dashboard?.user}
                      color="warning"
                      icon={<AttributionIcon />}
                      to={`/admin/users`}
                    />
                  </Grid>
                }
                {(isAdmin || isAgent || isReceptionist) &&
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <AppCardSummary
                      title="Company"
                      total={dashboard?.company}
                      color="info"
                      icon={<WorkIcon />}
                      to={`/admin/companies`}
                    />
                  </Grid>
                }
              </Grid>
            </Grid>
          }

          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}><Typography variant="h5" color='gray'>Vacancy Summary</Typography> </Grid>
              <Grid item xs={12}>
                <SimpleTable
                  columns={dashboardVacancySummary(formatMessage)}
                  tableData={dashboard?.vacancySummary || []}
                // tableProps={{ size: "medium" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {(isAdmin || isAccountant) && dashboard?.subscription &&
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}><Typography variant="h5" color='gray'>Subscription Plan</Typography> </Grid>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      bgcolor: alpha('#00c853', 0.1),
                      color: alpha('#00c853', 1),
                    }}
                  >
                    <CardHeader
                      title={dashboard?.subscription?.plan?.name}
                      subheader={dashboard?.subscription?.plan?.subheader}
                      titleTypographyProps={{ align: 'center' }}
                      subheaderTypographyProps={{ align: 'center' }}
                      action={dashboard?.subscription?.plan?.name === 'Pro' ? <Star /> : null}

                    />
                    <CardContent>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                      }}>
                        <Typography variant="h5" color='gray'>
                          Rs {dashboard?.subscription?.plan?.amount}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                          /mo
                        </Typography>
                      </Box>
                      <ul style={{
                        margin: 0,
                        padding: 8,
                        listStyle: 'none',
                      }}>
                        <Typography component="li" variant="subtitle2" align="center" color="textSecondary">
                          Start Date: {dashboard?.subscription?.start_date ? moment(dashboard?.subscription?.start_date).format('YYYY-MM-DD hh:mm A') : '-'}
                        </Typography>
                        <Typography component="li" variant="subtitle2" align="center" color="textSecondary">
                          End Date : {dashboard?.subscription?.end_date ? moment(dashboard?.subscription?.end_date).format('YYYY-MM-DD hh:mm A') : '-'}
                        </Typography>
                        <Typography component="li" color={remainingDays > 0 ? 'gray' : 'error'} variant="subtitle1" align="center">
                          {remainingDays > 0 ? `${remainingDays} Day Remaining` : `Expired`}
                        </Typography>

                      </ul>
                    </CardContent>
                    <CardActions>
                      {((!dashboard?.subscription?.billing || dashboard?.subscription?.billing?.status != 'success') && dashboard?.subscription?.is_trial === 0) ?
                        <Button
                          fullWidth
                          startIcon={<Payment />}
                          color='info'
                          variant={'contained'}
                          onClick={() => {
                            setPaymentOpen(true);
                          }}
                        >
                          Pay Now
                        </Button>
                        :
                        <Button
                          startIcon={<Upgrade />}
                          fullWidth
                          variant={'contained'}
                          color="success"
                          disabled={dashboard?.subscription?.is_trial === 1 ? false : remainingDays > 0 ? true : false}
                          onClick={() => {
                            navigate('/admin/subscription')
                          }}
                        >
                          Buy Now
                        </Button>
                      }
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          }
          {(isAdmin || isAccountant) &&
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <AppWebsiteVisits
                title="Account Summary"
                subheader="(+43%) than last year"
                chartLabels={[
                  '01/01/2003',
                  '02/01/2003',
                  '03/01/2003',
                  '04/01/2003',
                  '05/01/2003',
                  '06/01/2003',
                  '07/01/2003',
                  '08/01/2003',
                  '09/01/2003',
                  '10/01/2003',
                  '11/01/2003',
                ]}
                chartData={[
                  {
                    name: 'Fees',
                    type: 'column',
                    fill: 'solid',
                    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                  },
                  {
                    name: 'Income',
                    type: 'area',
                    fill: 'gradient',
                    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                  },
                  {
                    name: 'Expense',
                    type: 'line',
                    fill: 'solid',
                    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                  },
                ]}
              />
            </Grid>
          }

        </Grid>
      }


      {paymentOpen && <PaymentModal
        billingId={dashboard?.subscription?.id}
        totalAmount={dashboard?.subscription?.plan?.amount}
        open={paymentOpen}
        handleClose={() => { setPaymentOpen(false); }}
      />
      }
    </PageLayout>
  );
}
