import { useState } from 'react';

import Grid from '@mui/material/Grid';

import { CustomTab } from 'components';
import ExpenseItem from './expenseItem';
import CandidateFee from './candidateFee';
import Report from './report';
import { Container } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { accountingTab } from 'utils/constants';
import { useCheckPermissions } from 'hooks';

const permissionSet = {
    expenseItemsSave: ['expenseItems.createItem'],
    expenseItemsList: ['expenseItems.listItem'],
    expenseItemsDelete: ['expenseItems.deleteItem'],
    reportsGenerate: ['expenseItems.reports'],
}

const AdvanceSearch = () => {
    const { type } = useParams();
    const navigate = useNavigate();
    const checkPermissions = useCheckPermissions(permissionSet);

    return (
        <Container maxWidth="xl" style={{ maxWidth: '100%' }}>
            {type &&
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <CustomTab
                            tabs={accountingTab}
                            onChange={(tab) => {
                                navigate(`/admin/accounting/${tab}`);
                            }}
                            active={type}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {(type === 'candidate' && checkPermissions.expenseItemsList) && <CandidateFee />}
                        {(type === 'income' && checkPermissions.expenseItemsList) && <ExpenseItem category={'income'} />}
                        {(type === 'expense' && checkPermissions.expenseItemsList) && <ExpenseItem category={'expense'} />}
                        {(type === 'report' && checkPermissions.reportsGenerate) && <Report />}
                    </Grid>
                </Grid>
            }
        </Container>
    )
}

export default AdvanceSearch;