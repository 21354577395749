
import { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useIntl } from "react-intl";

import { ActionMenu, CustomButton } from 'components';
import { useToastMessage } from 'hooks';
import { candidateSearchColumns } from 'utils/constants/tableColumn/column';
import View from './View';
import { SimpleTable } from 'components';
import { services } from 'redux/services';
import { RemoveRedEye } from '@mui/icons-material';

const Candidate = forwardRef(({ searchText }: any, ref: any) => {
    const { formatMessage } = useIntl();
    const { toastMessage } = useToastMessage();
    const [viewModal, setViewModal] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [tableData, setTableData] = useState<any>([]);

    const columnAction = {
        label: formatMessage({ id: "view" }),
        name: "",
        options: {
            customBodyRender: (_: any, index: any) => {
                return (
                    <ActionMenu>
                        <CustomButton
                            color="warning"
                            size="small"
                            label="View"
                            variant="outlined"
                            onClick={() => handleViewOption(tableData[index])}
                            startIcon={<RemoveRedEye />}
                        />
                       <Fragment/>
                    </ActionMenu>
                )
            }
        }
    }

    useImperativeHandle(ref, () => ({ onApiCall }));

    useEffect(() => { onApiCall() }, []);

    function onApiCall() {
        setLoading(true)
        services.checkBlacklistCandidate({ searchText })
            .then((res: any) => {
                setTableData(res.result.rows)
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || "Something wrong !", 'error');
            }).finally(() =>
                setTimeout(() => {
                    setLoading(false)
                }, 1000)
            )
    }

    function handleViewOption(rowData: any) {
        setViewModal(true);
        setSelectedData(rowData)
    }

    return (
        <>
            <SimpleTable
                columns={[...candidateSearchColumns(formatMessage), columnAction]}
                tableData={tableData}
                loading={loading}
            />
            {viewModal &&
                <View
                    open={viewModal}
                    onClose={() => setViewModal(false)}
                    selectedData={selectedData}
                    headerText='Search Detail'
                />
            }
        </>
    )
})

export default Candidate;
