
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useIntl } from "react-intl";

import CustomTable from 'components/table';
import { ActionMenu, ConfirmationButton, CustomButton, TableButtonGroup } from 'components';
import { masterPaginationServices, tableInitialState, } from 'utils/constants';
import { getTableParams } from 'utils/global';
import { RootReducerState } from 'utils/types';
import { getMasterPaginationData } from 'redux/actions';
import PageLayout from 'layouts/PageLayout';
import Form from './Form';
import { services } from 'redux/services';
import { useCheckPermissions, useCheckRole, useToastMessage } from 'hooks';
import { roleColumns } from 'utils/constants/tableColumn/column';
import { Edit, Delete, PermDataSettingSharp } from '@mui/icons-material';
import RolePermission from './RolePermission';
import { roleData } from "utils/constants";

const permissionSet = {
    createRole: ['roles.createRole'],
    updateRole: ['roles.updateRole'],
    deleteRole: ['roles.deleteRole'],
    assignPermission: ['roles.assignPermission']
}

const Index: React.FC = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const { toastMessage } = useToastMessage();
    const checkPermissions = useCheckPermissions(permissionSet);

    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const [permissionModal, setPermissionModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const { isSuperAdmin, isAdmin } = useCheckRole();

    const { roleResponse, permissionData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            permissionData: masterPaginationReducer[masterPaginationServices.permission].data,
            roleResponse: masterPaginationReducer[masterPaginationServices.role].data,
            loading: masterPaginationReducer[masterPaginationServices.role].loading,
        }),
        shallowEqual
    );

    const { rows, count } = roleResponse;

    useEffect(() => {
        onApiCall();
    }, [tableState]);

    function onApiCall() {
        const params = getTableParams(tableState);
        dispatch(getMasterPaginationData(masterPaginationServices.role, params));
        dispatch(getMasterPaginationData(masterPaginationServices.permission, {}));
    }

    const columnAction = {
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                const rowData = rows[tableMeta.rowIndex];
                return (
                    <TableButtonGroup>
                        {checkPermissions.updateRole &&
                            <CustomButton
                                color='secondary'
                                size='small'
                                label="Edit"
                                variant='outlined'
                                onClick={() => handleRowClick(rowData)}
                                startIcon={<Edit />}
                            />
                        }
                        {checkPermissions.deleteRole &&
                            <ConfirmationButton
                                color='error'
                                size='small'
                                label="Delete"
                                variant='outlined'
                                onConfirm={() => onDeleteData(rowData)}
                                title={formatMessage({ id: "delete-title" })}
                                subTitle={formatMessage({ id: "delete-confirmation" })}
                                startIcon={<Delete />}
                            />
                        }
                        {checkPermissions.assignPermission && (([roleData.admin, roleData.superadmin].includes(rowData?.name) && isSuperAdmin) || isAdmin) &&
                            <CustomButton
                                color='warning'
                                size='small'
                                label="Permission"
                                variant='outlined'
                                onClick={() => handlePermission(rowData)}
                                startIcon={<PermDataSettingSharp />}
                            />
                        }
                    </TableButtonGroup>
                )
            }
        }
    }

    function handleRowClick(rowData: any) {
        setSelectedRow(rowData);
        setDrawer(true);
    }

    function handlePermission(rowData: any) {
        setSelectedRow(rowData);
        setPermissionModal(true);
    }

    function handleCreate() {
        setSelectedRow({});
        setDrawer(true);
    }

    function onDeleteData(data: any) {
        const params = { id: data.id };
        setDeleteLoading(true);
        services.deleteRole(params)
            .then((res: any) => {
                setDeleteLoading(false);
                onApiCall();
                toastMessage(formatMessage({ id: "delete-message" }));
            })
            .catch((err) => {
                setDeleteLoading(false);
                toastMessage(err?.data?.message || formatMessage({ id: "opps-error-message" }), 'error');
            })
    }

    return (
        <PageLayout title={formatMessage({ id: "role" })} onCreate={handleCreate} hideToolbar={!checkPermissions.createRole}>
            <CustomTable
                columns={[...roleColumns(formatMessage), columnAction]}
                tableData={rows}
                tableState={tableState}
                rowsCount={count}
                setTableState={setTableState}
                loading={loading || deleteLoading}
            />

            {drawer && (
                <Form
                    refreshData={onApiCall}
                    open={drawer}
                    closeModal={() => setDrawer(false)}
                    formMode={selectedRow ? 'create' : 'update'}
                    selectedData={selectedRow}
                    headerText={selectedRow ? formatMessage({ id: "create-role" }) : formatMessage({ id: "update-role" })}
                />
            )}

            {permissionModal && (
                <RolePermission
                    refreshData={onApiCall}
                    open={permissionModal}
                    closeModal={() => setPermissionModal(false)}
                    permissionData={permissionData?.rows || []}
                    selectedData={selectedRow}
                    headerText={selectedRow ? formatMessage({ id: "create-role" }) : formatMessage({ id: "update-role" })}
                />
            )}
        </PageLayout>
    )
}

export default Index;
