import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import { Link } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootReducerState } from 'utils/types';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function SubscriptionDenied() {
  const data = useSelector(({ auth }: RootReducerState) => auth?.data);
  const navigate = useNavigate();
  let title = 'Complete your subscription plan';
  let detail = 'You have not selected a subscription plan yet. Please choose one of our plans and subscribe to gain full access to our system.';
  if (data?.has_subscribed && data?.has_expired) {
    title = 'Renew your subscription plan';
    detail = 'Subscription has beed expired.Please renew to gain full access to our system.'
  }

  return (
    <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
      <Typography variant="h3" paragraph>
        {title}
      </Typography>

      <Typography sx={{ color: 'text.secondary' }}>
        {detail}
      </Typography>

      <Button startIcon={<Link />} sx={{ marginTop: 2 }} size="large" variant="contained" onClick={() => navigate('/admin/subscription')}>
        Go to subscription plan
      </Button>
    </ContentStyle>
  );
}
