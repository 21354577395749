import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { CustomModal } from './modal/CustomModal';
const steps = [
    {
        label: 'Uploading the file',
        description: 'Please wait while we upload your file.',
    },
    {
        label: 'Reading the file using AI',
        description: 'Analyzing the contents of the file with our AI engine.',
    },
    {
        label: 'Extracting information from the file',
        description: 'Extracting relevant information from the file.',
    },
    {
        label: 'Information fetched successfully',
        description: 'All information has been successfully fetched.',
    },
];

export default function OpenAIStepper({ activeStep, handleClose }: { activeStep: number, handleClose: any }) {
    return (
        <CustomModal open={true} onClose={null} size='xs'>
            <Box sx={{ paddingX: 2, paddingY:3 }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step
                            key={index}
                            // sx={{
                            //     '& .MuiStepLabel-root .Mui-completed': {
                            //         color: 'info.light', // circle color (COMPLETED)
                            //     },
                            //     '& .MuiStepLabel-root .Mui-active': {
                            //         color: 'info.main', // circle color (ACTIVE)
                            //     },
                            //     '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                            //     {
                            //         color: 'common.white', // Just text label (ACTIVE)
                            //     },
                            //     '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                            //         fill: 'common.white', // circle's number (ACTIVE)
                            //     },
                            // }}
                        >
                            <StepLabel
                                optional={<Typography variant="caption">{step.description}</Typography>}
                            >
                                {step.label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length && (
                    <Paper square elevation={0} sx={{ marginTop: 2 }}>
                        <Typography fontSize='bold' variant='subtitle1' color='green'>All the proccess is completed - Information fetched successfully.</Typography>
                        <Button fullWidth color="error" variant='outlined' onClick={handleClose} sx={{ mt: 2 }}>
                            Close
                        </Button>
                    </Paper>
                )}
            </Box>
        </CustomModal>
    );
}
