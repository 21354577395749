import { Fragment, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { CustomTab, HoverLoader } from 'components';
import { Container } from '@mui/material';
import { masterLookupServices, profileTab } from 'utils/constants';
import SeoLayout from 'layouts/SeoLayout';
import ProfileForm from './Profile';
import DocumentForm from './DocumentForm';
import { useToastMessage } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { services } from 'redux/services';
import { RootReducerState } from 'utils/types';
import { getMasterLookupData } from 'redux/actions';

const AdvanceSearch = () => {
    const { toastMessage } = useToastMessage();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState<boolean>(false);
    const [defaultData, setDefaultData] = useState<any>(null);
    const [activeTab, setActiveTab] = useState<string>(profileTab[0].value);
    const authData = useSelector(({ auth }: RootReducerState) => auth.data);

    useEffect(() => {
        dispatch(getMasterLookupData(masterLookupServices.getCandidateLookup));
    }, [])

    useEffect(() => {
        if (authData?.account_uuid) {
            setLoading(true);
            services.getAccountByUuid({ uuid: authData.account_uuid })
                .then((res: any) => {
                    setDefaultData(res.result);
                })
                .catch((err: any) => {
                    toastMessage(err?.data?.message || "Something wrong !", 'error');
                })
                .finally(() => setLoading(false))
        }

    }, [authData?.account_uuid]);

    return (
        <Fragment>
            <SeoLayout title={'Profile'} />
            {loading && <HoverLoader />}
            <Container maxWidth="xl" style={{ maxWidth: '100%' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomTab
                            tabs={profileTab}
                            active={activeTab}
                            onChange={(tab) => { setActiveTab(tab) }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {activeTab === 'general' && <ProfileForm defaultData={defaultData} />}
                        {activeTab === 'documents' && <DocumentForm defaultData={defaultData} />}
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    )
}

export default AdvanceSearch;