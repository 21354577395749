import { SubscriptionDenied } from "pages";
import AccessDenied from "pages/AccessDenied";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { roleData } from "utils/constants";
import { findCommonItems } from "utils/global";
import { RootReducerState } from "utils/types";

const PrivateRoute = ({
  children,
  permissions,
  hasSubscribed,
}: {
  children: JSX.Element;
  permissions: Array<string>;
  hasSubscribed: boolean;
}) => {
  const location = useLocation();

  const { data, token } = useSelector(({ auth }: RootReducerState) => ({
    data: auth.data,
    token: auth.token,
  }));

  const userHasRequiredPermission = permissions?.length > 0 ? findCommonItems(
    permissions,
    data.permissions
  ) : true;

  if (!token) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  if (
    !data?.role?.includes(roleData.superadmin) &&
    hasSubscribed &&
    (!data?.has_subscribed || data?.has_expired)
  ) {
    return <SubscriptionDenied />; // build your won access denied page (sth like 404)
  }

  if (token && !userHasRequiredPermission) {
    return <AccessDenied />; // build your won access denied page (sth like 404)
  }

  return children;
};

PrivateRoute.defaultProps = {
  hasSubscribed: true,
};

export default PrivateRoute;
