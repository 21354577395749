import { useIntl } from "react-intl";
import { Alert, Button, Grid } from "@mui/material";
import { Add, Update } from "@mui/icons-material";

import { CustomTextEditor, Select, TextArea } from "components/forms";
import CardLayout from "layouts/CardLayout";
import { services } from "redux/services";
import { useToastMessage } from "hooks";
import { useState } from "react";
import { ProfessionForm, SpokenLanguageForm } from "pages";

interface Props {
    uuid: string;
    description: {
        languages: Array<any>
        professions: Array<any>,
        experience_info: string,
        education_info: string,
        extra_info: string,
        profile_summary: string
    };
    setDescription: any;
    selectOptions: any;
    informationData: any;
}
const ExtraInformationForm = ({ uuid, description, selectOptions, setDescription, informationData }: Props) => {
    const { formatMessage } = useIntl();
    const [createProfessionModal, setCreateProfessionModal] = useState<boolean>(false);
    const [appededProfessionData, setAppededProfessionData] = useState<any>([]);
    const [createLanguageModal, setCreateLanguageModal] = useState<boolean>(false);
    const [appededLanguageData, setAppededLanguageData] = useState<any>([]);
    const { toastMessage } = useToastMessage();

    function onSubmit() {
        services.updateCandidateInformation({
            id: informationData?.id,
            uuid,
            ...description,
            professions: description?.professions?.map(x => x.label),
            languages: description?.languages?.map(x => x.label),
        })
            .then((res: any) => {
                toastMessage(formatMessage({ id: 'updated-message' }, { title: formatMessage({ id: "information" }) }));
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || "Something Wrong", "error");
            });
    }

    const handleChangeText = (name: string, value: string) => {
        setDescription({ ...description, [name]: value })
    }
    return (
        <CardLayout title={formatMessage({ id: 'resume' }) + " | " + formatMessage({ id: 'extra-information' })}>
            <Grid container spacing={2}>
                <Grid item xs={12} paddingTop='0 !important'>
                    <Alert severity="info">{formatMessage({ id: 'extra-information-alert-message' })}</Alert>
                </Grid>
                <Grid item xs={12}>
                    <TextArea
                        formLabel={formatMessage({ id: "professional-summary" })}
                        name="profile_summary"
                        value={description.profile_summary}
                        minRows={6}
                        onChange={(e) => handleChangeText('profile_summary', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={11}>
                            <Select
                                options={[...appededProfessionData, ...(selectOptions?.profession?.length > 0 ? selectOptions?.profession : [])]}
                                label={formatMessage({ id: 'skills' }) + " | " + formatMessage({ id: 'professions' })}
                                size="medium"
                                multiple
                                value={description.professions}
                                onChange={(_, value: any) => handleChangeText('professions', value)}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                variant='contained'
                                startIcon={<Add />}
                                onClick={() => setCreateProfessionModal(true)}
                                sx={{ padding: 2 }}
                            >
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={11}>
                            <Select
                                options={[...appededLanguageData, ...(selectOptions?.language?.length > 0 ? selectOptions?.language : [])]}
                                label={formatMessage({ id: 'language' })}
                                size="medium"
                                multiple
                                value={description.languages}
                                onChange={(_, value: any) => handleChangeText('languages', value)}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                variant='contained'
                                startIcon={<Add />}
                                onClick={() => setCreateLanguageModal(true)}
                                sx={{ padding: 2 }}
                            >
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <CustomTextEditor
                        formLabel={formatMessage({ id: 'experience-history' })}
                        name="experience_info"
                        defaultValue={description.experience_info}
                        onChange={(value) => handleChangeText('experience_info', value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextEditor
                        formLabel={formatMessage({ id: 'education-history' })}
                        name="education_info"
                        defaultValue={description.education_info}
                        onChange={(value) => handleChangeText('education_info', value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <CustomTextEditor
                        formLabel={formatMessage({ id: 'other-information' })}
                        name="extra_info"
                        defaultValue={description.extra_info}
                        onChange={(value) => handleChangeText('extra_info', value)}
                    />
                </Grid>

                <Grid item xs={12} textAlign="end">
                    <Button startIcon={<Update />} variant="contained" color="secondary" size="large" onClick={onSubmit}>
                        Update Information
                    </Button>
                </Grid>
            </Grid>
            {createProfessionModal && <ProfessionForm
                open={createProfessionModal}
                closeModal={() => setCreateProfessionModal(false)}
                refreshData={(data: { [index: string]: string; }) => data ? setAppededProfessionData([data, ...appededProfessionData]) : []}
                formMode={'create'}
                selectedData={null}
                headerText={formatMessage({ id: "create-profession" })}
            />
            }

            {createLanguageModal && <SpokenLanguageForm
                open={createLanguageModal}
                closeModal={() => setCreateLanguageModal(false)}
                refreshData={(data: { [index: string]: string; }) => data ? setAppededLanguageData([data, ...appededLanguageData]) : []}
                formMode={'create'}
                selectedData={null}
                headerText={formatMessage({ id: "create-profession" })}
            />
            }


        </CardLayout>
    );
};

export default ExtraInformationForm;
