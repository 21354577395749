import Dashboard from '@mui/icons-material/Dashboard';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import GroupIcon from '@mui/icons-material/Group';
import EngineeringIcon from '@mui/icons-material/Engineering';
import WorkIcon from '@mui/icons-material/Work';
import { AccountBalance, Announcement, Book, BookOnline, BookOnlineOutlined, Cases, Info, MiscellaneousServices, Money, MoneyOff, Payment, PermIdentity, PersonAdd, Report, ReportOff, SensorOccupied, Settings, Subscriptions, SubscriptionsOutlined } from '@mui/icons-material';
import AttributionIcon from '@mui/icons-material/Attribution';

import { candidateStatusOptions, roleData, visaStatusOptions } from '../utils/constants/default';
import Search from '@mui/icons-material/Search';

const SidebarMenu = [
  {
    title: 'dashboard',
    path: '/admin/dashboard',
    icon: <Dashboard />,
    permissions: [],
  },
  {
    title: 'Blacklist Checker',
    path: '/admin/blacklist-checker',
    icon: <Search />,
    permissions: ["candidates.listCandidate", "candidates.createCandidate"],
  },
  {
    title: 'Candidate',
    path: '/admin/candidates/list',
    icon: <EngineeringIcon />,
    permissions: ["candidates.listCandidate"],
    children: candidateStatusOptions?.map(x => ({
      title: x.label,
      path: `/admin/candidates/list/${x.value}`,
      permissions: ["candidates.listCandidate"],
      icon: <EngineeringIcon />,
    }))
  },
  {
    title: 'Visas',
    path: '/admin/visas',
    icon: <Book />,
    permissions: ["candidateVisas.listVisa"],
    children: visaStatusOptions?.map(x => ({
      title: x.label,
      path: `/admin/visas/${x.value}`,
      permissions: ["candidateVisas.listVisa"],
      icon: <Book />,
    }))
  },
  {
    title: 'Agency',
    path: '/admin/agencies',
    icon: <MapsHomeWorkIcon />,
    permissions: ["accounts.listAgencyAccount"],
  },
  {
    title: 'Company',
    path: '/admin/companies',
    icon: <WorkIcon />,
    permissions: ["companies.listCompany"],
  },
  {
    title: 'Vacancies',
    path: '/admin/vacancies',
    icon: <Announcement />,
    permissions: ["vacancies.listCompanyVacancy"],
  },
  {
    title: 'User',
    path: '/admin/users',
    icon: <AttributionIcon />,
    permissions: ["accountUsers.listUser", "roles.listRole"],
  },
  {
    title: 'Profile',
    path: '/admin/profile',
    icon: <Info />,
    permissions: ['accountUsers.listUser'],
  },
  {
    title: 'Report',
    path: '/admin/report',
    icon: <BookOnline />,
    permissions: ["candidates.candidateListExport,candidateVisas.visaListExport"],
    children: [
      {
        title: 'Candidate',
        path: '/admin/report/candidate',
        icon: <PersonAdd />,
        permissions: ["candidates.candidateListExport"],
      },
      {
        title: 'Visa',
        path: '/admin/report/visa',
        icon: <BookOnlineOutlined />,
        permissions: ["candidateVisas.visaListExport"],
      },
    ]
  },
  {
    title: 'Accounting',
    path: '/admin/accounting',
    icon: <AccountBalance />,
    permissions: ["expenseItems.listItem"],
    children: [
      {
        title: 'Candidate Fees',
        path: '/admin/accounting/candidate',
        icon: <PersonAdd />,
        permissions: ["expenseItems.listItem"],
      },
      {
        title: 'Expense',
        path: '/admin/accounting/expense',
        icon: <Money />,
        permissions: ["expenseItems.listItem"],
      },
      {
        title: 'Report',
        path: '/admin/accounting/report',
        icon: <Report />,
        permissions: ["expenseItems.reports"],
      },
    ]
  },
  {
    title: 'Subscription',
    path: '/admin/subscriptions',
    icon: <Subscriptions />,
    permissions: ["subscription.listSubscription"],
  },
  {
    title: 'Subscription',
    path: '/admin/subscription',
    icon: <Subscriptions />,
    permissions: ["subscription.chooseSubscriptionPlan"],
  },
  {
    title: 'Setting',
    path: '/admin/settings',
    icon: <Settings />,
    permissions: ['subscriptionPlans.listPlan', 'spokenLanguage.listLanguage', 'professions.listProfession', 'permissions.listPermission', 'paymentMethods.listPaymentMethod', "subscriptionPlanFeatures.listFeature", "miscellaneous.listMiscellaneous"],
    children: [
      // {
      //   title: 'Role',
      //   path: '/admin/settings/roles',
      //   icon: <GroupIcon />,
      //   permissions: ['roles.list'],
      // },
      {
        title: 'Language',
        path: '/admin/settings/spoken-language',
        icon: <GroupIcon />,
        permissions: ['spokenLanguage.listLanguage'],
      },
      {
        title: 'Miscellaneous',
        path: '/admin/settings/miscellaneous',
        icon: <MiscellaneousServices />,
        permissions: ['miscellaneous.listMiscellaneous'],
      },

      {
        title: 'Profession',
        path: '/admin/settings/professions',
        icon: <SensorOccupied />,
        permissions: ['professions.listProfession'],
      },
      {
        title: 'Permission',
        path: '/admin/settings/permissions',
        icon: <PermIdentity />,
        permissions: ['permissions.assignPermission'],
      },
      {
        title: 'Payment Method',
        path: '/admin/settings/payment-methods',
        icon: <Payment />,
        permissions: ['paymentMethods.listPaymentMethod'],
      },
      {
        title: 'Subscription Features',
        path: '/admin/settings/subscription-features',
        icon: <SubscriptionsOutlined />,
        permissions: ["subscriptionPlanFeatures.listFeature"],
      },
      {
        title: 'Subscription Plan',
        path: '/admin/settings/subscription-plans',
        icon: <SubscriptionsOutlined />,
        permissions: ["subscriptionPlans.listPlan"],
      },
    ]
  },
];

export default SidebarMenu;
